// TasksData.ts
import {
  CompanyAddRelocationLinkDescription,
  CompanyUpgradeCandidatesDescription,
  CompanyEmbedRelocationLinkDescription,
} from "./Descriptions/CompanyTaskDescription";

import {
  ApartmentSearchDescription,
  CostOfLivingDescription,
  RecognitionProfesionalQualificationsDescription,
  ReviewInsuranceDescription,
  CancelContractsDescription,
  BankInvestmentDescription,
  RegisterHouseholdDescription,
  PetsWithYouDescription,
  ImportYourCarDescription,
  DeregistrationDescription,
  PostalMailDescription,
  FarewellDescription,
} from "./Descriptions/BeforeMovingTaskDescription";

import {
  ImportantDocumentsDescription,
  PurchaseVignetteDescription,
  CustomsOfficeDescription,
  DocumentsCarDescription,
} from "./Descriptions/MovingDayTaskDescription";

import {
  ChangeDriverLicenseDescription,
  ChildcareDescription,
  CurrencyExchangeDescription,
  EBankingTwintDescription,
  EducationSystemDescription,
  GetSwissInsuranceDescription,
  HealthInsuranceDescription,
  InformBanksOldCountryDescription,
  MobilityDescription,
  OnlineShoppingDescription,
  OpenBankAccountDescription,
  PhoneAndInternetDescription,
  RegisterCarDescription,
  RegisterPetsDescription,
  RegisterSwissDescription,
  ResidencePermitDescription,
  SerafeDescription,
  SupermarketsDescription,
  WasteDescription,
} from "./Descriptions/AfterTheMoveTaskDescription";

import {
  RetirementDescription,
  TaxDeclarationDescription,
} from "./Descriptions/AfterSettlingTaskDescription";

import {
  CertificateResidenceDescription,
  GeneralInformationDescription,
  HealthInsuranceCommuterDescription,
  InsurancesCommuterDescription,
  MobilePhoneCommuterDescription,
  OpenBankAccountCommuterDescription,
  RetirementCommuterDescription,
  WorkPermitDescription,
  TaxationCommuterDescription,
} from "./Descriptions/CommutingTaskDescription";

import {
  WeeklyApartmentSearchDescription,
  WeeklyCertificateResidenceDescription,
  WeeklyCurrencyExchangeDescription,
  WeeklyEBankingTwintDescription,
  WeeklyGeneralInformationDescription,
  WeeklyGetSwissInsuranceDescription,
  WeeklyHealthInsuranceCommuterDescription,
  WeeklyMobilityDescription,
  WeeklyOnlineShoppingDescription,
  WeeklyOpenBankDescription,
  WeeklyPhoneAndInternetDescription,
  WeeklyPickUpPermitDescription,
  WeeklyRecognitionProfesionalQualificationsDescription,
  WeeklyRegisterSwissDescription,
  WeeklyRetirementDescription,
  WeeklySerafeDescription,
  WeeklySupermarketsDescription,
  WeeklyTaxDeclarationDescription,
  WeeklyTaxationCommuterDescription,
  WeeklyWasteDescription,
} from "./Descriptions/WeeklyCommutingTasksDescription";

// Mapeo de componentes
export const componentMap: { [key: string]: React.FC } = {
  CompanyAddRelocationLinkDescription: CompanyAddRelocationLinkDescription,
  CompanyUpgradeCandidatesDescription: CompanyUpgradeCandidatesDescription,
  CompanyEmbedRelocationLinkDescription :CompanyEmbedRelocationLinkDescription,
  ApartmentSearchDescription: ApartmentSearchDescription,
  CostOfLivingDescription: CostOfLivingDescription,
  RecognitionProfesionalQualificationsDescription:
    RecognitionProfesionalQualificationsDescription,
  ReviewInsuranceDescription: ReviewInsuranceDescription,
  CancelContractsDescription: CancelContractsDescription,
  BankInvestmentDescription: BankInvestmentDescription,
  RegisterHouseholdDescription: RegisterHouseholdDescription,
  PetsWithYouDescription: PetsWithYouDescription,
  ImportYourCarDescription: ImportYourCarDescription,
  DeregistrationDescription: DeregistrationDescription,
  PostalMailDescription: PostalMailDescription,
  FarewellDescription: FarewellDescription,
  ImportantDocumentsDescription: ImportantDocumentsDescription,
  PurchaseVignetteDescription: PurchaseVignetteDescription,
  CustomsOfficeDescription: CustomsOfficeDescription,
  DocumentsCarDescription: DocumentsCarDescription,
  ChangeDriverLicenseDescription: ChangeDriverLicenseDescription,
  ChildcareDescription: ChildcareDescription,
  CurrencyExchangeDescription: CurrencyExchangeDescription,
  EBankingTwintDescription: EBankingTwintDescription,
  EducationSystemDescription: EducationSystemDescription,
  GetSwissInsuranceDescription: GetSwissInsuranceDescription,
  HealthInsuranceDescription: HealthInsuranceDescription,
  InformBanksOldCountryDescription: InformBanksOldCountryDescription,
  MobilityDescription: MobilityDescription,
  OnlineShoppingDescription: OnlineShoppingDescription,
  OpenBankAccountDescription: OpenBankAccountDescription,
  PhoneAndInternetDescription: PhoneAndInternetDescription,
  RegisterCarDescription: RegisterCarDescription,
  RegisterPetsDescription: RegisterPetsDescription,
  RegisterSwissDescription: RegisterSwissDescription,
  ResidencePermitDescription: ResidencePermitDescription,
  SerafeDescription: SerafeDescription,
  SupermarketsDescription: SupermarketsDescription,
  WasteDescription: WasteDescription,
  RetirementDescription: RetirementDescription,
  TaxDeclarationDescription: TaxDeclarationDescription,
  CertificateResidenceDescription: CertificateResidenceDescription,
  GeneralInformationDescription: GeneralInformationDescription,
  HealthInsuranceCommuterDescription: HealthInsuranceCommuterDescription,
  InsurancesCommuterDescription: InsurancesCommuterDescription,
  MobilePhoneCommuterDescription: MobilePhoneCommuterDescription,
  OpenBankAccountCommuterDescription: OpenBankAccountCommuterDescription,
  RetirementCommuterDescription: RetirementCommuterDescription,
  WorkPermitDescription: WorkPermitDescription,
  TaxationCommuterDescription: TaxationCommuterDescription,
  WeeklyApartmentSearchDescription: WeeklyApartmentSearchDescription,
  WeeklyCertificateResidenceDescription: WeeklyCertificateResidenceDescription,
  WeeklyCurrencyExchangeDescription: WeeklyCurrencyExchangeDescription,
  WeeklyEBankingTwintDescription: WeeklyEBankingTwintDescription,
  WeeklyGeneralInformationDescription: WeeklyGeneralInformationDescription,
  WeeklyGetSwissInsuranceDescription: WeeklyGetSwissInsuranceDescription,
  WeeklyHealthInsuranceCommuterDescription:
    WeeklyHealthInsuranceCommuterDescription,
  WeeklyMobilityDescription: WeeklyMobilityDescription,
  WeeklyOnlineShoppingDescription: WeeklyOnlineShoppingDescription,
  WeeklyOpenBankDescription: WeeklyOpenBankDescription,
  WeeklyPhoneAndInternetDescription: WeeklyPhoneAndInternetDescription,
  WeeklyPickUpPermitDescription: WeeklyPickUpPermitDescription,
  WeeklyRecognitionProfesionalQualificationsDescription:
    WeeklyRecognitionProfesionalQualificationsDescription,
  WeeklyRegisterSwissDescription: WeeklyRegisterSwissDescription,
  WeeklyRetirementDescription: WeeklyRetirementDescription,
  WeeklySerafeDescription: WeeklySerafeDescription,
  WeeklySupermarketsDescription: WeeklySupermarketsDescription,
  WeeklyTaxDeclarationDescription: WeeklyTaxDeclarationDescription,
  WeeklyTaxationCommuterDescription: WeeklyTaxationCommuterDescription,
  WeeklyWasteDescription: WeeklyWasteDescription,
};

export interface Task {
  id: string;
  title: string;
  component: string;
  description: JSX.Element;
  completed: boolean;
  group: number;
  category: string;
  type: string;
}

export const groupNames: string[] = [
  "moving-details.group1",
  "moving-details.group2",
  "moving-details.group3",
  "moving-details.group4",
  "moving-details.group5",
];
