// src/components/Settings/CompanySettings.tsx

import styles from "../../../styles/Company Dashboard styles/Companies Settings styles/Settings.module.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import LanguageSelector from "../../LanguageSelector"; // Import your custom LanguageSelector


const Settings: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const [theme, setTheme] = useState<string>("light");

  // Load saved theme from localStorage on component mount
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme") || "light";
    setTheme(savedTheme);
  }, []);

  // Apply the theme to the body and persist it
  useEffect(() => {
    document.body.classList.remove("theme-light", "theme-dark");
    document.body.classList.add(`theme-${theme}`);
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleSection = (section: string) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(e.target.checked ? "dark" : "light");
  };

  const handleGeneralSettingsChange = () => {
    // Handle general settings changes
    console.log("General settings updated");
  };

  const handleAccountSettingsChange = () => {
    // Handle account settings changes
    console.log("Account settings updated");
  };

  const handlePermissionsChange = () => {
    // Handle permissions and roles changes
    console.log("Permissions and roles updated");
  };

  const handleIntegrationsChange = () => {
    // Handle integrations changes
    console.log("Integrations updated");
  };

  return (
    <div className={styles.settingsContainer}>
      <h1 className={styles.settingsHeading}>Settings</h1>

      {/* General Settings */}
      <div className={styles.settingsAccordion}>
        <button
          className={styles.settingsAccordionButton}
          onClick={() => toggleSection("general")}
          aria-expanded={activeSection === "general"}
        >
          <span>General Settings</span>
          {activeSection === "general" ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {activeSection === "general" && (
          <div className={styles.settingsAccordionContent}>
            <form onChange={handleGeneralSettingsChange}>
              <div className={styles.settingsFormGroup}>
                <label htmlFor="language">Language</label>
                <LanguageSelector />
              </div>
              <div className={styles.settingsFormGroup}>
                <label htmlFor="region">Region</label>
                <select
                  id="region"
                  name="region"
                  className={styles.settingsSelect}
                  onChange={() => handleGeneralSettingsChange()}
                >
                  <option value="en_US">United States</option>
                  <option value="es_ES">Spain</option>
                  <option value="fr_FR">France</option>
                  {/* Add more regions as needed */}
                </select>
              </div>
              <div className={styles.settingsFormGroup}>
                <label htmlFor="theme">Theme</label>
                <div className={styles.settingsToggleContainer}>
                  <label className={styles.settingsSwitch}>
                    <input
                      type="checkbox"
                      id="theme"
                      name="theme"
                      checked={theme === "dark"}
                      onChange={handleThemeChange}
                    />
                    <span className={styles.settingsSlider}></span>
                  </label>
                  <span>{theme === "dark" ? "Dark Mode" : "Light Mode"}</span>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>

      {/* Account Settings */}
      <div className={styles.settingsAccordion}>
        <button
          className={styles.settingsAccordionButton}
          onClick={() => toggleSection("account")}
          aria-expanded={activeSection === "account"}
        >
          <span>Account Settings</span>
          {activeSection === "account" ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {activeSection === "account" && (
          <div className={styles.settingsAccordionContent}>
            <form onChange={handleAccountSettingsChange}>
              <div className={styles.settingsFormGroup}>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className={styles.settingsInput}
                  placeholder="Enter new password"
                />
              </div>
              <div className={styles.settingsFormGroup}>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  className={styles.settingsInput}
                  placeholder="Confirm new password"
                />
              </div>
              <div className={styles.settingsFormGroup}>
                <label htmlFor="2fa">Two-Factor Authentication (2FA)</label>
                <div className={styles.settingsToggleContainer}>
                  <label className={styles.settingsSwitch}>
                    <input
                      type="checkbox"
                      id="2fa"
                      name="2fa"
                      onChange={() => handleAccountSettingsChange()}
                    />
                    <span className={styles.settingsSlider}></span>
                  </label>
                  <span>Enable 2FA</span>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>

      {/* Permissions & Roles */}
      <div className={styles.settingsAccordion}>
        <button
          className={styles.settingsAccordionButton}
          onClick={() => toggleSection("permissions")}
          aria-expanded={activeSection === "permissions"}
        >
          <span>Permissions & Roles</span>
          {activeSection === "permissions" ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {activeSection === "permissions" && (
          <div className={styles.settingsAccordionContent}>
            <form onChange={handlePermissionsChange}>
              <div className={styles.settingsFormGroup}>
                <label htmlFor="role">User Role</label>
                <select
                  id="role"
                  name="role"
                  className={styles.settingsSelect}
                  onChange={() => handlePermissionsChange()}
                >
                  <option value="admin">Administrator</option>
                  <option value="manager">Manager</option>
                  <option value="employee">Employee</option>
                  {/* Add more roles as needed */}
                </select>
              </div>
              <div className={styles.settingsFormGroup}>
                <label>Specific Permissions</label>
                <div className={styles.settingsCheckboxGroup}>
                  <label>
                    <input type="checkbox" name="manageUsers" />
                    Manage Users
                  </label>
                  <label>
                    <input type="checkbox" name="viewReports" />
                    View Reports
                  </label>
                  <label>
                    <input type="checkbox" name="manageSettings" />
                    Manage Settings
                  </label>
                  {/* Add more permissions as needed */}
                </div>
              </div>
            </form>
          </div>
        )}
      </div>

      {/* Integrations */}
      <div className={styles.settingsAccordion}>
        <button
          className={styles.settingsAccordionButton}
          onClick={() => toggleSection("integrations")}
          aria-expanded={activeSection === "integrations"}
        >
          <span>Integrations</span>
          {activeSection === "integrations" ? <FaChevronUp /> : <FaChevronDown />}
        </button>
        {activeSection === "integrations" && (
          <div className={styles.settingsAccordionContent}>
            <form onChange={handleIntegrationsChange}>
              <div className={styles.settingsFormGroup}>
                <label htmlFor="googleWorkspace">Google Workspace</label>
                <div className={styles.settingsToggleContainer}>
                  <label className={styles.settingsSwitch}>
                    <input
                      type="checkbox"
                      id="googleWorkspace"
                      name="googleWorkspace"
                      onChange={() => handleIntegrationsChange()}
                    />
                    <span className={styles.settingsSlider}></span>
                  </label>
                  <span>Enable Google Workspace Integration</span>
                </div>
              </div>
              <div className={styles.settingsFormGroup}>
                <label htmlFor="slack">Slack</label>
                <div className={styles.settingsToggleContainer}>
                  <label className={styles.settingsSwitch}>
                    <input
                      type="checkbox"
                      id="slack"
                      name="slack"
                      onChange={() => handleIntegrationsChange()}
                    />
                    <span className={styles.settingsSlider}></span>
                  </label>
                  <span>Enable Slack Integration</span>
                </div>
              </div>
              {/* Add more integrations as needed */}
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default Settings;
