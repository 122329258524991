import React, { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logoName from "../assets/logoName.png";
import logo from "../assets/logo.png";
import LanguageSelector from "./LanguageSelector";
import styles from "../styles/NavBar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faLock } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../context/AuthContext";

const NavBar: React.FC = () => {
  const { t } = useTranslation("global");
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated, logout } = useContext(AuthContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null); // Changed from userName
  const [userMoveType, setUserMoveType] = useState<string | null>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const xIconRef = useRef<HTMLSpanElement>(null);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const isLandingPage = location.pathname === "/";

  const [userType, setUserType] = useState<"company" | "individual" | null>(
    null
  );

  const registrationPath = "/registration-organization";
  const organizationPaths = ["/company", "/companies", "/organization"];
  const loginPath = organizationPaths.some((path) =>
    location.pathname.includes(path)
  )
    ? "/login-organization"
    : "/login-individuals";

  const handleLogout = () => {
    logout();

    localStorage.removeItem("token");
    localStorage.removeItem("loggedAs");
    localStorage.removeItem("_grecaptcha");
    localStorage.removeItem("threadId");
    navigate(loginPath);
  };

  const isActive = (path: string) => location.pathname === path;

  const handleClickOutside = (event: MouseEvent) => {
    if (
      isMenuOpen &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      xIconRef.current &&
      !xIconRef.current.contains(event.target as Node)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    // Agregar el evento cuando el menú está abierto
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Limpiar el evento al desmontar el componente
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const fetchUserData = async (
      token: string,
      currentUserType: "company" | "individual"
    ) => {
      try {
        if (currentUserType === "company") {
          const response = await fetch("https://api.relotu.com/companies", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          });

          if (response.ok) {
            const data = await response.json();
            setAvatarUrl(data.avatar);
            setCompanyName(data.name);
          } else {
            console.error("Error fetching company data");
          }
        } else if (currentUserType === "individual") {
          const responseCompany = await fetch(
            "https://api.relotu.com/individuals/company",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "x-relotu-access": token,
              },
            }
          );

          if (responseCompany.ok) {
            const dataCompany = await responseCompany.json();
            setAvatarUrl(dataCompany.avatar);
            setCompanyName(dataCompany.name);
          } else {
            console.error("Error fetching company data for individual");
          }

          const responseIndividual = await fetch(
            "https://api.relotu.com/individuals",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "x-relotu-access": token,
              },
            }
          );

          if (responseIndividual.ok) {
            const dataIndividual = await responseIndividual.json();

            setUserMoveType(dataIndividual.moveInfo.moveType);
          } else {
            console.error("Error fetching individual data");
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (isAuthenticated) {
      const token = localStorage.getItem("token");
      const storedUserType = localStorage.getItem("loggedAs") as
        | "company"
        | "individual"
        | null;

      if (token && storedUserType) {
        setUserType(storedUserType);
        fetchUserData(token, storedUserType);
      } else {
        console.error("Token or userType not found in localStorage");
        setAvatarUrl(null);
        setCompanyName(null);
      }
    } else {
      setAvatarUrl(null);
      setCompanyName(null);
      setUserType(null);
    }
  }, [isAuthenticated]);

  return (
    <nav className={`${styles.nav} ${isLandingPage ? styles.landingNav : ""}`}>
      {isAuthenticated ? (
        <div className={styles.logoAvatarContainer}>
          <Link
            to={userType === "company" ? "/dashboard-companies" : "/my-journey"}
          >
            <img src={logo} alt="Logo" className={styles.logo} />
          </Link>

          <div className={styles.separator}></div>

          {avatarUrl && companyName && (
            <div className={styles.avatarContainer}>
              <div className={styles.avatarBox}>
                <img src={avatarUrl} alt="Avatar" className={styles.avatar} />
                <span className={styles.userName}>{companyName}</span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Link to="/">
          <img src={logoName} alt="Logo Name" className={styles.logoName} />
        </Link>
      )}
      <span ref={xIconRef}>
        <FontAwesomeIcon
          icon={isMenuOpen ? faTimes : faBars}
          className={styles.hamburger}
          onClick={toggleMenu}
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
        />
      </span>

      <div
        ref={menuRef}
        className={isMenuOpen ? styles.navMenuActive : styles.navMenu}
      >
        <ul className={styles.navList}>
          {!isLandingPage && (
            <>
              {isAuthenticated ? (
                userType === "company" ? (
                  <>
                    <li
                      className={
                        isActive("/dashboard-companies") ? styles.active : ""
                      }
                    >
                      <Link to="/dashboard-companies">
                        {t("navbar.dashboard")}
                      </Link>
                    </li>
                    {/*<li
                      className={
                        isActive("/what-is-included") ? styles.active : ""
                      }
                    >
                      <Link to="//what-is-included">
                      {t("navbar.what-is-included")}
                      </Link>
                    </li>*/}

                    <li
                      className={
                        isActive("/people-insights") ? styles.active : ""
                      }
                    >
                      <span className={styles.disabledLinkContainer}>
                        <span className={styles.disabledLink}>
                          {t("navbar.people-insights")}
                          <FontAwesomeIcon
                            icon={faLock}
                            className={styles.lockIcon}
                          />
                        </span>
                        <span className={styles.tooltipText}>
                          {t("navbar.tooltip-disabled-link-people-insights")}
                        </span>
                      </span>
                      {/*<Link to="/people-insights">{t("navbar.people-insights")}</Link>*/}
                    </li>
                    {/*<li
                      className={
                        isActive("/company-tasks") ? styles.active : ""
                      }
                    >
                      <Link to="/company-tasks">
                        {t("navbar.company-tasks")}
                      </Link>
                    </li>*/}
                    <li
                      className={isActive("/videolibrary") ? styles.active : ""}
                    >
                      <Link to="/videolibrary">{t("navbar.videolibrary")}</Link>
                    </li>
                  </>
                ) : userType === "individual" ? (
                  <>
                    <li
                      className={isActive("/my-journey") ? styles.active : ""}
                    >
                      <Link to="/my-journey">{t("navbar.my-journey")}</Link>
                    </li>
                    <li className={isActive("/chat") ? styles.active : ""}>
                      <Link to="/chat">{t("navbar.chat")}</Link>
                    </li>

                    <li
                      className={isActive("/videolibrary") ? styles.active : ""}
                    >
                      <Link to="/videolibrary">{t("navbar.videolibrary")}</Link>
                    </li>
                    <li
                      className={`${
                        isActive("/my-tasks") ? styles.active : ""
                      }`}
                    >
                      {userMoveType === "interested" ? (
                        <span className={styles.disabledLinkContainer}>
                          <span className={styles.disabledLink}>
                            {t("navbar.my-tasks")}
                            <FontAwesomeIcon
                              icon={faLock}
                              className={styles.lockIcon}
                            />
                          </span>
                          <span className={styles.tooltipText}>
                            {t("navbar.tooltip-disabled-link-interested")}
                          </span>
                        </span>
                      ) : (
                        <Link to="/my-tasks">{t("navbar.my-tasks")}</Link>
                      )}
                    </li>
                  </>
                ) : null
              ) : (
                <>
                  <li
                    className={
                      isActive("/individuals-landing-page") ? styles.active : ""
                    }
                  >
                    <Link to="/individuals-landing-page">
                      {t("navbar.for-individuals")}
                    </Link>
                  </li>
                  <li
                    className={
                      isActive("/companies-landing-page") ? styles.active : ""
                    }
                  >
                    <Link to="/companies-landing-page">
                      {t("navbar.for-companies")}
                    </Link>
                  </li>
                </>
              )}
            </>
          )}
          <li className={`${isActive("/contact-us") ? styles.active : ""}`}>
            {userMoveType === "interested" ? (
              <span className={styles.disabledLinkContainer}>
                <span className={styles.disabledLink}>
                  {t("navbar.contact-us")}
                  <FontAwesomeIcon icon={faLock} className={styles.lockIcon} />
                </span>
                <span className={styles.tooltipText}>
                  {t("navbar.tooltip-disabled-link-interested")}
                </span>
              </span>
            ) : (
              <Link to="/contact-us">{t("navbar.contact-us")}</Link>
            )}
          </li>

          {!isLandingPage && (
            <>
              {isAuthenticated ? (
                <li>
                  <button
                    type="button"
                    className={styles.registrationButton}
                    onClick={handleLogout}
                  >
                    {t("navbar.logout-button")}
                  </button>
                </li>
              ) : (
                <>
                  <li>
                    <Link to={registrationPath}>
                      <button className={styles.registrationButton}>
                        {t("navbar.register-button")}
                      </button>
                    </Link>
                  </li>
                  <li>
                    <Link to={loginPath}>
                      <button className={styles.navBarButton}>
                        {t("navbar.login-button")}
                      </button>
                    </Link>
                  </li>
                </>
              )}
            </>
          )}
          <LanguageSelector />
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
