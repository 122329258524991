// src/components/CompanyDashboard/CompanyDashboard.tsx

import React, { useState, useEffect } from "react";
import styles from "../../styles/Company Dashboard styles/CompanyDashboard.module.css";
import HomeCompanyDashboard from "./Home components/HomeCompanyDashboard";
import CompanyProfile from "./Profile components/CompanyProfile";
import {
  RiCollapseDiagonalFill,
  RiExpandDiagonal2Fill,
  RiExpandDiagonalFill,
} from "react-icons/ri";
import ContactPersonRelotu from "./ContactPersonRelotu components/ContactPersonRelotu";
import CompanyServicesDashboard from "./Companies Services components/CompanyServiceDashboard";
import ComapnySettings from "./Companies Settings components/CompanySettings";
import ViewIndividuals from "./Companies Individuals components/ViewIndividuals";
import UpgradeIndividuals from "./Companies Individuals components/UpgradeIndividuals";
import MyIndividualsDashboard from "./Companies Individuals components/MyIndividualsDashboard";
import NavItem from "./NavItem";
import {
  FaHome,
  FaTasks,
  FaChartBar,
  FaCog,
  FaUser,
  FaBell,
  FaAddressBook,
  FaHandshake,
  FaUsers,
  FaDatabase,
  FaServicestack,
  FaBars,
  FaTimes,
  FaChevronLeft,
  FaChevronRight,
  FaTruckMoving,
} from "react-icons/fa";
import CreateLinks from "../Dashboard components/CreateLinks";

interface NavItemData {
  name: string;
  icon?: React.ReactNode;
}

// Main Navigation Items
const mainNavItems: NavItemData[] = [
  { name: "companyDashboard.home", icon: <FaHome /> },
  //{ name: "companyDashboard.activities", icon: <FaTasks /> },
  { name: "companyDashboard.services", icon: <FaHandshake /> },
  { name: "companyDashboard.provideRelocation", icon: <FaTruckMoving /> },
  { name: "companyDashboard.manageUsers", icon: <FaUsers /> },
  // Add more main items here if needed
];

// Bottom Navigation Items
const bottomNavItems: NavItemData[] = [
  { name: "companyDashboard.settings", icon: <FaCog /> },
  { name: "companyDashboard.profile", icon: <FaUser /> },
];

const extraNavItems: NavItemData[] = [
  { name: "companyDashboard.contactPerson", icon: <FaAddressBook /> },
  { name: "companyDashboard.reports", icon: <FaChartBar /> },
  { name: "companyDashboard.viewData", icon: <FaDatabase /> },
];

const CompanyDashboard: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string>(mainNavItems[0].name);
  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleCollapse = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSidebarOpen]);

  const renderContent = () => {
    switch (activeItem) {
      case "companyDashboard.home":
        return <HomeCompanyDashboard setActiveItem={setActiveItem} />;
      case "companyDashboard.provideRelocation":
        return <CreateLinks />;
      case "companyDashboard.manageUsers":
        return <MyIndividualsDashboard />;
      case "companyDashboard.reports":
        return (
          <div className={styles.content}>
            Generate and review your reports.
          </div>
        );
      case "companyDashboard.settings":
        return <ComapnySettings />;
      case "companyDashboard.profile":
        return <CompanyProfile />;
      case "companyDashboard.contactPerson":
        return <ContactPersonRelotu />;
      case "companyDashboard.hireServices":
        return (
          <div className={styles.content}>Hire external services here.</div>
        );
      case "companyDashboard.viewData":
        return <div className={styles.content}>View your data.</div>;
      case "companyDashboard.services":
        return <CompanyServicesDashboard />;
      default:
        return <div className={styles.content}>Default Content</div>;
    }
  };

  return (
    <div className={styles.companyDashboard}>
      {/* Toggle Button for Small Screens */}
      <button
        className={`${styles.toggleButton} ${
          isSidebarOpen ? styles.toggleButtonOpen : ""
        }`}
        onClick={toggleSidebar}
        aria-label="Toggle Sidebar"
        aria-expanded={isSidebarOpen}
        aria-controls="sidebar"
      >
        <FaBars />
      </button>

      {/* Overlay for Small Screens */}
      {isSidebarOpen && (
        <div className={styles.overlay} onClick={closeSidebar}></div>
      )}

      {/* Sidebar */}
      <aside
        id="sidebar"
        className={`${styles.companyDashboardSidebar} ${
          isSidebarOpen ? styles.companyDashboardSidebarOpen : ""
        } ${isSidebarCollapsed ? styles.companyDashboardSidebarCollapsed : ""}`}
      >
        {/* Collapse/Expand Button */}
        <button
          className={styles.collapseButton}
          onClick={toggleCollapse}
          aria-label="Collapse Sidebar"
        >
          {isSidebarCollapsed ? (
            <RiExpandDiagonalFill />
          ) : (
            <RiCollapseDiagonalFill />
          )}
        </button>

        {/* Main Navigation */}
        <nav className={styles.sidebarNav}>
          <ul className={styles.companyDashboardNavList}>
            {mainNavItems.map((item) => (
              <NavItem
                key={item.name}
                name={item.name}
                icon={item.icon}
                isActive={activeItem === item.name}
                onClick={() => {
                  setActiveItem(item.name);
                  closeSidebar(); // Close sidebar after selection on small screens
                }}
                isCollapsed={isSidebarCollapsed}
              />
            ))}
          </ul>
        </nav>

        {/* Extra Navigation */}
        <nav className={styles.sidebarNav}>
          <ul className={styles.companyDashboardNavList}>
            {extraNavItems.map((item) => (
              <NavItem
                key={item.name}
                name={item.name}
                icon={item.icon}
                isActive={activeItem === item.name}
                onClick={() => {
                  setActiveItem(item.name);
                  closeSidebar(); // Close sidebar after selection on small screens
                }}
                isCollapsed={isSidebarCollapsed}
              />
            ))}
          </ul>
        </nav>

        {/* Bottom Navigation */}
        <nav className={styles.bottomNav}>
          <ul className={styles.companyDashboardNavList}>
            {bottomNavItems.map((item) => (
              <NavItem
                key={item.name}
                name={item.name}
                icon={item.icon}
                isActive={activeItem === item.name}
                onClick={() => {
                  setActiveItem(item.name);
                  closeSidebar(); // Close sidebar after selection on small screens
                }}
                isCollapsed={isSidebarCollapsed}
              />
            ))}
          </ul>
        </nav>
      </aside>

      {/* Main Content */}
      <main className={styles.companyDashboardMainContent}>
        {renderContent()}
      </main>
    </div>
  );
};

export default CompanyDashboard;
