import React, { useState } from "react";
import logo from "../../assets/logo.png";
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  addDays,
  addMonths,
  subMonths,
  format,
  isSameMonth,
  isSameDay,
  isBefore,
  startOfDay,
} from "date-fns";
import { Curiosity, curiosities } from "./CuriositiesData";
import styles from "../../styles/Curiosities Calendar styles/CuriositiesCalendar.module.css";
import { useTranslation } from "react-i18next";

const CuriositiesCalendar: React.FC = () => {
  const { t, i18n } = useTranslation("global");
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";

    return (
      <div className={styles.curiositiesCalendarHeader}>
        <button
          onClick={prevMonth}
          className={styles.curiositiesCalendarNavButton}
        >
          &lt;
        </button>
        <div className={styles.curiositiesCalendarCurrentMonth}>
          {format(currentMonth, dateFormat)}
        </div>
        <button
          onClick={nextMonth}
          className={styles.curiositiesCalendarNavButton}
        >
          &gt;
        </button>
      </div>
    );
  };

  const renderDays = () => {
    const days = [];
    const dateFormat = "EEE";
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={styles.curiositiesCalendarDay} key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className={styles.curiositiesCalendarDaysRow}>{days}</div>;
  };

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, { weekStartsOn: 1 });
    const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });

    const dateFormat = "d";
    const rows = [];

    let days: JSX.Element[] = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;

        const formattedFullDate = format(day, "yyyy-MM-dd");
        const curiosity: Curiosity | undefined = curiosities.find(
          (c) => c.date === formattedFullDate
        );

        const isPast = isBefore(day, startOfDay(new Date()));

        let logoClass = "";
        if (curiosity) {
          if (curiosity.type === "specific") {
            logoClass = isPast
              ? styles.curiositiesCalendarLogoGray
              : styles.curiositiesCalendarLogo;
          }
        }

        const showLogo = curiosity && curiosity.type === "specific";
        const showDot = curiosity && curiosity.type === "general";

        days.push(
          <div
            className={`${styles.curiositiesCalendarCell} ${
              !isSameMonth(day, monthStart)
                ? styles.curiositiesCalendarOutOfMonth
                : ""
            } ${
              isSameDay(day, selectedDate)
                ? styles.curiositiesCalendarSelected
                : ""
            }`}
            key={day.toString()}
            onClick={() => onDateClick(cloneDay)}
          >
            <span>{formattedDate}</span>
            {showLogo && (
              <img
                src={logo}
                alt={t("curiositiesCalendar.specificCuriosityLogo")}
                className={logoClass}
              />
            )}
            {showDot && <div className={styles.curiositiesCalendarDot}></div>}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className={styles.curiositiesCalendarRow} key={day.toString()}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className={styles.curiositiesCalendarBody}>{rows}</div>;
  };

  const onDateClick = (day: Date) => {
    setSelectedDate(day);
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const goToToday = () => {
    const today = new Date();
    setCurrentMonth(today);
    setSelectedDate(today);
  };

  const renderCuriosity = () => {
    const formattedFullDate = format(selectedDate, "yyyy-MM-dd");
    const curiosity: Curiosity | undefined = curiosities.find(
      (c) => c.date === formattedFullDate
    );

    const language = localStorage.getItem("language") || i18n.language; // Obtiene el idioma desde localStorage o usa el idioma por defecto

    return (
      <div className={styles.curiositiesCalendarCuriosityContainer}>
        <div className={styles.curiositiesCalendarInfoSection}>
          <div className={styles.curiositiesCalendarTitleDescription}>
            <h1 className={styles.curiositiesCalendarTitle}>
              {t("curiositiesCalendar.title")}
            </h1>
            <p className={styles.curiositiesCalendarDescription}>
              {t("curiositiesCalendar.description")}
            </p>
          </div>

          <div className={styles.curiositiesCalendarDailyCuriosity}>
            <button
              onClick={goToToday}
              className={styles.curiositiesCalendarTodayButton}
            >
              {t("curiositiesCalendar.todayButton")}
            </button>
            {curiosity ? (
              <div
                className={styles.curiositiesCalendarCuriosityTitleContainer}
              >
                <h3 className={styles.curiositiesCalendarCuriosityTitle}>
                  {curiosity.title[language as keyof Curiosity['title']]}
                </h3>
                <p className={styles.curiositiesCalendarDescription}>
                  {curiosity.description[language as keyof Curiosity['description']]}
                </p>
              </div>
            ) : (
              <div>
                <h3>{t("curiositiesCalendar.noCuriosityTitle")}</h3>
                <p>{t("curiositiesCalendar.noCuriosityDescription")}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderLegend = () => {
    return (
      <div className={styles.curiositiesCalendarLegend}>
        <div className={styles.curiositiesCalendarLegendItem}>
          <img src={logo} alt={t("curiositiesCalendar.specificCuriosityLogo")} />
          <span>{t("curiositiesCalendar.specificCuriosity")}</span>
        </div>
        <div className={styles.curiositiesCalendarLegendItem}>
          <div className={styles.curiositiesCalendarLegendDot}></div>
          <span>{t("curiositiesCalendar.generalCuriosity")}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.curiositiesCalendarContainer}>
      <div className={styles.curiositiesCalendarCalendar}>
        {renderHeader()}
        {renderDays()}
        {renderCells()}
        {renderLegend()}
      </div>
      <div className={styles.curiositiesCalendarCuriositySection}>
        {renderCuriosity()}
      </div>
    </div>
  );
};

export default CuriositiesCalendar;
