import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip"; // Usa Tooltip en lugar de ReactTooltip
import 'react-tooltip/dist/react-tooltip.css'; // Importa el CSS de react-tooltip
import styles from '../../styles/Authentication styles/PasswordValidation.module.css'; // Importamos el módulo de estilos

interface PasswordValidationProps {
  password: string;
}

const PasswordValidation: React.FC<PasswordValidationProps> = ({ password }) => {
  const [t] = useTranslation("global");

  const minLength = 8;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const isValidLength = password.length >= minLength;

  return (
    <div>
      {/* Tooltip trigger */}
      <span
        data-tooltip-id="passwordTooltip"
        className={styles.tooltipIcon} // Aplicamos el estilo del ícono
      >
        ℹ️ {t("authentication.password-tooltip-label")}
      </span>

      {/* Definimos el contenido del tooltip */}
      <Tooltip id="passwordTooltip" place="right">
        <p>{t("authentication.password-validation-title")}</p>
        <ul className={styles.validationList}>
          <li className={`${styles.validationItem} ${isValidLength ? styles.valid : styles.invalid}`}>
            {isValidLength
              ? t("authentication.password-valid-length")
              : t("authentication.password-invalid-length", { minLength })}
          </li>
          <li className={`${styles.validationItem} ${hasUpperCase ? styles.valid : styles.invalid}`}>
            {hasUpperCase
              ? t("authentication.password-has-uppercase")
              : t("authentication.password-needs-uppercase")}
          </li>
          <li className={`${styles.validationItem} ${hasLowerCase ? styles.valid : styles.invalid}`}>
            {hasLowerCase
              ? t("authentication.password-has-lowercase")
              : t("authentication.password-needs-lowercase")}
          </li>
          <li className={`${styles.validationItem} ${hasNumber ? styles.valid : styles.invalid}`}>
            {hasNumber
              ? t("authentication.password-has-number")
              : t("authentication.password-needs-number")}
          </li>
          <li className={`${styles.validationItem} ${hasSpecialChar ? styles.valid : styles.invalid}`}>
            {hasSpecialChar
              ? t("authentication.password-has-special-char")
              : t("authentication.password-needs-special-char")}
          </li>
        </ul>
      </Tooltip>
    </div>
  );
};

export default PasswordValidation;
