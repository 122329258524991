// src/components/CreateLinks.tsx

import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faUserGraduate,
  faBus,
  faWalking,
  faTruckMoving,
  faCopy,
  faVideo,
  faArrowDown,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/Dashboard styles/CreateLinks.module.css";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import logoName from "../../assets/logoName.png";

const CreateLinks: React.FC = () => {
  const { t } = useTranslation("global");
  const { token, isAuthenticated } = useContext(AuthContext);

  const [companyId, setCompanyId] = useState<string | null>(null);
  const [companyName, setCompanyName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const userTypes = [
    {
      name: t("servicesCompanies.provideRelocationService.interested"),
      icon: faUserPlus,
      moveType: "interested",
    },
    {
      name: t("servicesCompanies.provideRelocationService.candidate"),
      icon: faUserGraduate,
      moveType: "candidate",
    },
    {
      name: t("servicesCompanies.provideRelocationService.mover"),
      icon: faTruckMoving,
      moveType: "mover",
    },
    {
      name: t("servicesCompanies.provideRelocationService.dailyCommuter"),
      icon: faWalking,
      moveType: "daily-commuter",
    },
    {
      name: t("servicesCompanies.provideRelocationService.weeklyCommuter"),
      icon: faBus,
      moveType: "weekly-commuter",
    },
  ];

  const fetchCompanyInformation = async () => {
    if (!token) {
      setError(t("servicesCompanies.provideRelocationService.tokenNotFound"));
      setLoading(false);
      return;
    }

    const endpoint = "https://api.relotu.com/companies";

    try {
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-relotu-access": token,
        },
      });

      if (!response.ok) {
        throw new Error(t("servicesCompanies.provideRelocationService.fetchError", { status: response.status, statusText: response.statusText }));
      }

      const data = await response.json();
      setCompanyId(data._id);

      if (data.name) {
        setCompanyName(data.name);
      } else {
        throw new Error(t("servicesCompanies.provideRelocationService.companyNameNotFound"));
      }
    } catch (err: any) {
      setError(err.message || t("servicesCompanies.provideRelocationService.genericError"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchCompanyInformation();
    } else {
      setLoading(false);
      setError(t("servicesCompanies.provideRelocationService.userNotAuthenticated"));
    }
  }, [isAuthenticated]);

  const getBase64Image = (imgUrl: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.src = imgUrl;

      img.onload = () => {
        try {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          if (ctx) {
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
          } else {
            reject(t("servicesCompanies.provideRelocationService.canvasContextError"));
          }
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = (error) => {
        reject(t("servicesCompanies.provideRelocationService.imageLoadError"));
      };
    });
  };

  const handleDownloadPDF = async () => {
    if (!companyName) {
      alert(t("servicesCompanies.provideRelocationService.companyNameMissing"));
      return;
    }

    try {
      const downloadedLinksDoc = new jsPDF();

      const logoBase64 = await getBase64Image(logoName);

      if (logoBase64) {
        const imgWidth = 50;
        const imgHeight = 25;
        downloadedLinksDoc.addImage(logoBase64, "PNG", 10, 10, imgWidth, imgHeight);
      }

      downloadedLinksDoc.setFontSize(16);
      downloadedLinksDoc.setFont("helvetica", "bold");
      downloadedLinksDoc.text(
        t("servicesCompanies.provideRelocationService.thanks"),
        105,
        40,
        { align: "center" }
      );

      downloadedLinksDoc.setFontSize(12);
      downloadedLinksDoc.setFont("helvetica", "normal");
      downloadedLinksDoc.text(
        t("servicesCompanies.provideRelocationService.description"),
        105,
        50,
        { align: "center" }
      );

      let yPosition = 60;
      downloadedLinksDoc.setFontSize(10);

      for (const userType of userTypes) {
        const link = `http://relotu.com/registration-individuals?companyId=${companyId}&moveType=${userType.moveType}`;

        downloadedLinksDoc.setFont("helvetica", "bold");
        downloadedLinksDoc.text(`${userType.name}:`, 10, yPosition);
        yPosition += 7;

        const splitLink = downloadedLinksDoc.splitTextToSize(link, 190);
        downloadedLinksDoc.setFont("helvetica", "normal");
        downloadedLinksDoc.text(splitLink, 10, yPosition);
        yPosition += splitLink.length * 7;

        yPosition += 2;

        downloadedLinksDoc.setDrawColor(200);
        downloadedLinksDoc.line(10, yPosition, 200, yPosition);
        yPosition += 10;

        if (yPosition > 270) {
          downloadedLinksDoc.addPage();
          yPosition = 20;
        }
      }

      const sanitizeFileName = (name: string) => {
        return name.replace(/[^a-z0-9]/gi, "_").toLowerCase();
      };

      const fileName = sanitizeFileName(companyName) + "_registration_links.pdf";

      downloadedLinksDoc.save(fileName);
    } catch (error) {
      console.error(t("servicesCompanies.provideRelocationService.pdfGenerationError"), error);
      alert(t("servicesCompanies.provideRelocationService.pdfError"));
    }
  };

  const handleCopy = (link: string) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        alert(t("servicesCompanies.provideRelocationService.linkCopied"));
      })
      .catch(() => {
        alert(t("servicesCompanies.provideRelocationService.copyFailed"));
      });
  };

  if (loading) {
    return (
      <section className={styles.section}>
        <p className={styles.title}>
          {t("servicesCompanies.provideRelocationService.title")}
        </p>
        <p>{t("servicesCompanies.provideRelocationService.loading")}</p>
      </section>
    );
  }

  if (error) {
    return (
      <section className={styles.section}>
        <p className={styles.title}>
          {t("servicesCompanies.provideRelocationService.title")}
        </p>
        <p className={styles.error}>{error}</p>
      </section>
    );
  }

  if (!companyId) {
    return (
      <section className={styles.section}>
        <p className={styles.title}>
          {t("servicesCompanies.provideRelocationService.title")}
        </p>
        <p className={styles.error}>
          {t("servicesCompanies.provideRelocationService.noCompanyId")}
        </p>
      </section>
    );
  }

  return (
    <section className={styles.section}>
      <div className={styles.headerCreateLinksDiv}>
        <div className={styles.videoBanner}>
          <p className={styles.videoText}>
            <FontAwesomeIcon icon={faVideo} className={styles.videoIcon} />
            {t("servicesCompanies.provideRelocationService.watchVideo")}
            <FontAwesomeIcon icon={faArrowDown} className={styles.arrowDown} />
          </p>
          <p className={styles.title}>
            {t("servicesCompanies.provideRelocationService.title")}
          </p>
        </div>

        <div className={styles.downloadButtonDiv}>
          <button onClick={handleDownloadPDF} className={styles.downloadButton}>
            <FontAwesomeIcon icon={faFilePdf} className={styles.pdfIcon} />
            {t("servicesCompanies.provideRelocationService.downloadPDF")}
          </button>
        </div>
      </div>

      <ul className={styles.linkList}>
        {userTypes.map((userType) => {
          const link = `https://relotu.com/registration-individuals?companyId=${companyId}&moveType=${userType.moveType}`;
          return (
            <li key={userType.moveType} className={styles.linkItem}>
              <div className={styles.userTypeInfo}>
                <FontAwesomeIcon icon={userType.icon} className={styles.icon} />
                <span className={styles.userTypeName}>{userType.name}</span>
              </div>
              <div className={styles.linkContainer}>
                <input
                  type="text"
                  readOnly
                  value={link}
                  className={styles.linkInput}
                />
                <button
                  onClick={() => handleCopy(link)}
                  className={styles.copyButton}
                  aria-label={t("servicesCompanies.provideRelocationService.copyLink")}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default CreateLinks;
