import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import styles from "../../styles/Legal styles/CookieHandler.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCookieBite } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface CookieHandlerProps {
  privacyPolicyLink: string;
  cookieSettingsLink: string;
}

const CookieHandler: React.FC<CookieHandlerProps> = ({
  privacyPolicyLink,
  cookieSettingsLink,
}) => {
  const [t, i18n] = useTranslation("global");
  const [cookies, setCookie] = useCookies([
    "cookieAccepted",
    "necessaryCookiesAccepted"
  ]);
  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    // Mostrar el banner solo si no se ha guardado ninguna preferencia de cookies
    const hasPreference = cookies.cookieAccepted || cookies.necessaryCookiesAccepted;
    setShowBanner(!hasPreference);
  }, [cookies]);

  const acceptAllCookies = () => {
    setCookie("cookieAccepted", true, { path: "/" });
    setCookie("necessaryCookiesAccepted", true, { path: "/" });
    setShowBanner(false);
  };

  const acceptNecessaryCookies = () => {
    setCookie("necessaryCookiesAccepted", true, { path: "/" });
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className={styles.CookiesHandlerContainer}>
      <div className={styles.CookiesHandlerIconHeaderWrapper}>
        <FontAwesomeIcon icon={faCookieBite} className={styles.CookiesHandlerIcon} />
        <h1 className={styles.CookiesHandlerHeader}>{t("cookies.handler-title")}</h1>
      </div>
      <p>
        <span className={styles.CookiesHandlerText}>
          {t("cookies.handler-text")}{" "}
          <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
            {t("cookies.handler-privacy-policy")}{" "}
          </a>
        </span>
      </p>
      <div className={styles.CookiesHandlerButtonGroup}>
        <button className={styles.CookiesHandlerButton} onClick={acceptAllCookies}>
          {t("cookies.handler-accept-all-button")}
        </button>
        <button className={styles.CookiesHandlerButton} onClick={acceptNecessaryCookies}>
          {t("cookies.handler-accept-necessary-button")}
        </button>
      </div>
      <a
        href={cookieSettingsLink}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.CookiesHandlerLink}
      >
        {t("cookies.handler-cookie-settings")}
      </a>
    </div>
  );
};

export default CookieHandler;
