// src/components/WhatIsIncluded.tsx

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../styles/Company Dashboard styles/Companies Services styles/WhatIsIncluded.module.css"; 
import servicesImgDE from "../../../assets/WhatIsIncludedImgDE.png"
import servicesImgDE1 from "../../../assets/WhatIsIncludedImgDE1.png"
import servicesImgDE2 from "../../../assets/WhatIsIncludedImgDE2.png"
import servicesImgDE3 from "../../../assets/WhatIsIncludedImgDE3.png"
import servicesImgEN from "../../../assets/WhatIsIncludedImgEN.png"
import servicesImgEN1 from "../../../assets/WhatIsIncludedImgEN1.png"
import servicesImgEN2 from "../../../assets/WhatIsIncludedImgEN2.png"
import servicesImgEN3 from "../../../assets/WhatIsIncludedImgEN3.png"

const WhatIsIncluded: React.FC = () => {
  const [t, i18n] = useTranslation("global");
  
  // Estado para el nombre de la empresa
  const [companyName, setCompanyName] = useState<string | null>(null);
  
  // Estados para manejar carga y errores
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth <= 768
  );


  useEffect(() => {
    const fetchUserData = async (
      token: string,
      currentUserType: "company" | "individual"
    ) => {
      try {
        if (currentUserType === "company") {
          const response = await fetch("https://api.relotu.com/companies", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          });

          if (response.ok) {
            const data = await response.json();
            console.log("Fetched company data:", data);
            setCompanyName(data.name);
          } else {
            console.error("Error fetching company data");
            setError("Error al obtener los datos de la empresa.");
          }
        } else if (currentUserType === "individual") {
          const responseCompany = await fetch(
            "https://api.relotu.com/individuals/company",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                "x-relotu-access": token,
              },
            }
          );

          if (responseCompany.ok) {
            const dataCompany = await responseCompany.json();
            console.log("Fetched company data for individual:", dataCompany);
            setCompanyName(dataCompany.name);
          } else {
            console.error("Error fetching company data for individual");
            setError("Error al obtener los datos de la empresa para individuo.");
          }
        }
      } catch (err: any) {
        console.error(err);
        setError("Ocurrió un error inesperado.");
      } finally {
        setLoading(false);
      }
    };

    const token = localStorage.getItem("token");
    const currentUserType: "company" | "individual" = "company"; // Ajusta según tu lógica

    if (token) {
      fetchUserData(token, currentUserType);
    } else {
      setError("No se encontró el token de autenticación.");
      setLoading(false);
    }
  }, []);

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  // Renderizar estado de carga
  if (loading) {
    return <div className={styles.whatIsIncluded}>Loading</div>;
  }

  // Renderizar estado de error
  if (error) {
    return <div className={styles.whatIsIncluded}>Error fetching the data</div>;
  }

  // Renderizar contenido principal una vez obtenidos los datos
  return (
    <section className={styles.whatIsIncluded}>
      {!isSmallScreen && (
        <h2 className={styles.includedHeader}>🗂️ {t("servicesCompanies.whatIsIncluded.title")}</h2>
      )}
      <ul className={styles.includedList}>
        <li className={styles.includedListItem}>
          <strong>{t("servicesCompanies.whatIsIncluded.services.general.title")}:</strong> {t("servicesCompanies.whatIsIncluded.services.general.description")}
        </li>
        <li className={styles.includedListItem}>
          <strong>{t("servicesCompanies.whatIsIncluded.services.personalized.title", { companyName })}:</strong> {t("servicesCompanies.whatIsIncluded.services.personalized.description", { companyName })}<a href="mailto:info@relotu.com">info@relotu.com</a>
        </li>
      </ul>
      <div className={styles.includedImageContainer}>
          {isSmallScreen ? (
            <>
              <img
                src={
                  i18n.language === "de" ? servicesImgDE1 : servicesImgEN1
                }
                className={styles.includedImageSmall}
                alt="Instruction Image 2"
              />
              <img
                src={
                  i18n.language === "de" ? servicesImgDE2 : servicesImgEN2
                }
                className={styles.includedImageSmall}
                alt="Instruction Image 3"
              />
              <img
                src={
                  i18n.language === "de" ? servicesImgDE3 : servicesImgEN3
                }
                className={styles.includedImageSmall}
                alt="Instruction Image 3"
              />
            </>
          ) : (
            <img
              src={i18n.language === "de" ? servicesImgDE : servicesImgEN}
              alt="Instruction Image 1"
              className={styles.includedImage}
            />
          )}
        </div>
      
    </section>
  );
};

export default WhatIsIncluded;
