// src/components/CustomerJourney.tsx

import React, { useState, useEffect } from "react";
import styles from "../../styles/Customer Journey styles/CustomerJourney.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/logo.png"; // Asegúrate de que la ruta sea correcta
import {
  faPersonCircleQuestion,
  faUser,
  faPersonWalkingLuggage,
  faFlagCheckered,
  faLock,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import InterestedCustomer from "./InterestedCustomer";
import CandidateCustomer from "./CandidateCustomer";
import MoverCustomer from "./MoverCustomer";
import { useTranslation } from "react-i18next"; 

interface Phase {
  id: number;
  key: string; // Clave para identificar la fase
  name: string;
  description: string;
  icon: any;
}

type MoveType =
  | "interested"
  | "candidate"
  | "mover"
  | "weekly-commuter"
  | "daily-commuter";

const PhaseContent: React.FC<{ phase: Phase }> = ({ phase }) => {
  if (phase.key === "interested") {
    return <InterestedCustomer />;
  }
  if (phase.key === "candidate") {
    return <CandidateCustomer />;
  }
  if (phase.key === "mover") {
    return <MoverCustomer />;
  }

  return (
    <div className={styles.phaseContent}>
      <h2>{phase.name}</h2>
      <p>{phase.description}</p>
    </div>
  );
};

const CustomerJourney: React.FC = () => {
  const { t } = useTranslation("global"); 
  const [selectedPhase, setSelectedPhase] = useState<Phase | null>(null);
  const [moveType, setMoveType] = useState<MoveType | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const phases: Phase[] = [
    {
      id: 1,
      key: "interested",
      name: t("customerJourney.phases.interested.name"),
      description: t("customerJourney.phases.interested.description"),
      icon: faPersonCircleQuestion,
    },
    {
      id: 2,
      key: "candidate",
      name: t("customerJourney.phases.candidate.name"),
      description: t("customerJourney.phases.candidate.description"),
      icon: faUser,
    },
    {
      id: 3,
      key: "mover",
      name: t("customerJourney.phases.mover.name"),
      description: t("customerJourney.phases.mover.description"),
      icon: faPersonWalkingLuggage,
    },
    {
      id: 4,
      key: "lifeInSwitzerland",
      name: t("customerJourney.phases.lifeInSwitzerland.name"),
      description: t("customerJourney.phases.lifeInSwitzerland.description"),
      icon: faFlagCheckered,
    },
  ];

  useEffect(() => {
    const fetchMoveType = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No access token found.");
        }
        const responseIndividual = await fetch(
          "https://api.relotu.com/individuals",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          }
        );

        const contentType = responseIndividual.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          const text = await responseIndividual.text();
          console.error("Non-JSON response:", text);
          throw new Error("Received non-JSON response from the server.");
        }

        if (responseIndividual.ok) {
          const dataIndividual = await responseIndividual.json();
          setMoveType(dataIndividual.moveInfo.moveType);
        } else {
          const errorText = await responseIndividual.text();
          console.error("Error response:", errorText);
          throw new Error("Error fetching individual data.");
        }
      } catch (error: any) {
        console.error("Fetch error:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMoveType();
  }, []);

  const getPhaseStatus = (
    phaseId: number
  ): "completed" | "active" | "locked" | "open" => {
    if (!moveType) return "locked";

    switch (moveType) {
      case "interested":
        return phaseId === 1 ? "active" : "locked";
      case "candidate":
        if (phaseId === 1) return "completed";
        if (phaseId === 2) return "active";
        return "locked";
      case "mover":
      case "weekly-commuter":
      case "daily-commuter":
        if (phaseId < 3) return "completed";
        if (phaseId === 3) return "active";
        //if (phaseId === 4) return "open"; // Desbloquear fase 4 cuando está en fase 3
        return "locked";
      default:
        return "locked";
    }
  };

  const getLogoPhaseId = (moveType: MoveType): number => {
    switch (moveType) {
      case "interested":
        return 1; // Fase 1
      case "candidate":
        return 2; // Fase 2
      case "mover":
      case "weekly-commuter":
      case "daily-commuter":
        return 3; // Fase 3
      default:
        return 1; // Valor por defecto
    }
  };

  const logoPhaseId = moveType ? getLogoPhaseId(moveType) : null;

  useEffect(() => {
    if (moveType) {
      switch (moveType) {
        case "interested":
          setSelectedPhase(phases[0]);
          break;
        case "candidate":
          setSelectedPhase(phases[1]);
          break;
        case "mover":
        case "weekly-commuter":
        case "daily-commuter":
          setSelectedPhase(phases[2]);
          break;
        default:
          setSelectedPhase(phases[0]);
      }
    }
  }, [moveType]);

  if (loading) {
    return <div className={styles.loading}>{t("customerJourney.loading")}</div>;
  }

  if (error) {
    return (
      <div className={styles.error}>
        {t("customerJourney.error", { error })}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        {phases.map((phase) => {
          const status = getPhaseStatus(phase.id);
          const isActive = selectedPhase?.id === phase.id;

          return (
            <div
              key={phase.id}
              className={`${styles.phaseItem} ${
                isActive ? styles.active : ""
              } ${status === "completed" ? styles.completed : ""} ${
                status === "locked" ? styles.locked : ""
              }`}
              onClick={() => {
                if (status !== "locked") {
                  setSelectedPhase(phase);
                }
              }}
            >
              <div className={styles.iconWrapper}>
                {status === "completed" && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className={styles.completedTopRightIcon}
                  />
                )}

                <FontAwesomeIcon
                  icon={phase.icon}
                  className={styles.phaseIcon}
                />

                {/* Mostrar el ícono de bloqueo en la esquina superior derecha si está bloqueado */}
                {status === "locked" && (
                  <FontAwesomeIcon
                    icon={faLock}
                    className={styles.lockedTopRightIcon}
                  />
                )}

                {/* Mostrar el logo activo centrado dentro del círculo */}
                {logoPhaseId === phase.id && (
                  <img src={logo} alt="Logo" className={styles.logoActive} />
                )}
              </div>
              <div className={styles.phaseName}>{phase.name}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.content}>
        {selectedPhase && <PhaseContent phase={selectedPhase} />}
      </div>
    </div>
  );
};

export default CustomerJourney;
