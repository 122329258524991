// src/components/CallsTab/CallsTab.tsx

import React from "react";
import styles from "../../../styles/Company Dashboard styles/Companies Services styles/CallsServiceTab.module.css";
import { FaPhoneAlt, FaUserFriends, FaPhoneSquare, FaPlusCircle } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface CallsService {
  id: number;
  icon: JSX.Element;
  title: string;
  description: string;
}

const CallsTab: React.FC = () => {
  const { t } = useTranslation("global");

  const servicesData: CallsService[] = [
    /*{
      id: 1,
      icon: <FaPhoneAlt className={styles.callsServiceIcon} />,
      title: t("callsTab.services.availableCalls.title"),
      description: t("callsTab.services.availableCalls.description"),
    },*/
    {
      id: 1,
      icon: <FaUserFriends className={styles.callsServiceIcon} />,
      title: t("callsTab.services.candidateCall.title"),
      description: t("callsTab.services.candidateCall.description"),
    },
    {
      id: 2,
      icon: <FaPhoneSquare className={styles.callsServiceIcon} />,
      title: t("callsTab.services.additionalCalls.title"),
      description: t("callsTab.services.additionalCalls.description"),
    },
    {
      id: 3,
      icon: <FaPlusCircle className={styles.callsServiceIcon} />,
      title: t("callsTab.services.extraCallsOption.title"),
      description: t("callsTab.services.extraCallsOption.description"),
    },
  ];

  return (
    <div className={styles.callsServiceContainer}>
      <h2 className={styles.callsServiceHeading}>{t("callsTab.heading")}</h2>
      <p className={styles.callsServiceDescription}>{t("callsTab.description")}</p>
      <div className={styles.callsServiceGrid}>
        {servicesData.map((service) => (
          <div key={service.id} className={styles.callsServiceCard}>
            <div className={styles.callsServiceIconContainer}>
              {service.icon}
            </div>
            <h3 className={styles.callsServiceTitle}>{service.title}</h3>
            <p className={styles.callsServiceDescriptionText}>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CallsTab;
