// src/components/Dashboard components/AddRelocationLinkDescription.tsx

import React from "react";
import { useTranslation } from "react-i18next";

export const CompanyAddRelocationLinkDescription: React.FC = () => {
  const { t } = useTranslation("global");

  return (
    <div>
      <ul>
        <li>
        {t("tasks.group1.company.addRelocationLink-step1-part1")}{" "}
          <strong>{t("tasks.group1.company.addRelocationLink-step1-bold")}</strong>{" "}
          {t("tasks.group1.company.addRelocationLink-step1-part2")}
        </li>
        <li>
          {t("tasks.group1.company.addRelocationLink-step2-part1")}{" "}
          <strong>{t("tasks.group1.company.addRelocationLink-step2-bold")}</strong>{" "}
          {t("tasks.group1.company.addRelocationLink-step2-part2")}
        </li>
        <li>{t("tasks.group1.company.addRelocationLink-step3")}</li>
      </ul>
    </div>
  );
};


// src/components/Dashboard components/EmbedRelocationLinkDescription.tsx

export const CompanyEmbedRelocationLinkDescription: React.FC = () => {
  const { t } = useTranslation("global");

  return (
    <div>
      <ul>
        <li>
          {t("tasks.group1.company.embedRelocationLink-step1-part1")}{" "}
          <strong>{t("tasks.group1.company.embedRelocationLink-step1-bold")}</strong>{" "}
          {t("tasks.group1.company.embedRelocationLink-step1-part2")}
        </li>
        <li>
          {t("tasks.group1.company.embedRelocationLink-step2-part1")}{" "}
          <strong>{t("tasks.group1.company.embedRelocationLink-step2-bold")}</strong>{" "}
          {t("tasks.group1.company.embedRelocationLink-step2-part2")}
        </li>
        <li>{t("tasks.group1.company.embedRelocationLink-step3")}</li>
      </ul>
    </div>
  );
};


// src/components/Dashboard components/UpgradeCandidatesDescription.tsx

export const CompanyUpgradeCandidatesDescription: React.FC = () => {
  const { t } = useTranslation("global");

  return (
    <div>
      <ul>
        <li>
          {t("tasks.group1.company.upgradeCandidates-step1-part1")}{" "}
          <strong>{t("tasks.group1.company.upgradeCandidates-step1-bold")}</strong>{" "}
          {t("tasks.group1.company.upgradeCandidates-step1-part2")}
        </li>
        <li>
          {t("tasks.group1.company.upgradeCandidates-step2-part1")}{" "}
          <strong>{t("tasks.group1.company.upgradeCandidates-step2-bold")}</strong>{" "}
          {t("tasks.group1.company.upgradeCandidates-step2-part2")}
        </li>
        <li>{t("tasks.group1.company.upgradeCandidates-step3")}</li>
      </ul>
    </div>
  );
};

