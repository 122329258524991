import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/Authentication styles/EmailValidation.module.css";
import failValidationImg from "../../assets/emailFailValidation.png";
import { useTranslation } from "react-i18next";

export default function FailValidationEmail() {
  const [t, i18n] = useTranslation("global");
 
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img
          src={failValidationImg}
          alt="Email not validated"
          className={styles.validationImage}
        />
        <h2 className={styles.title}>{t("authentication.fail-validation-title")}</h2>
        <p className={styles.message}>
        {t("authentication.fail-validation-message")}
        </p>
      
      </div>
    </div>
  );
}
