// src/components/IntegrationTab/IntegrationTab.tsx

import React from "react";
import styles from "../../../styles/Company Dashboard styles/Companies Services styles/IntegrationServicesTab.module.css";
import { FaBusinessTime, FaLanguage, FaCalendarAlt, FaHandsHelping } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface IntegrationService {
  id: number;
  icon: JSX.Element;
  title: string;
  description: string;
}

const IntegrationTab: React.FC = () => {
  const { t } = useTranslation("global");

  const servicesData: IntegrationService[] = [
    /*{
      id: 1,
      icon: <FaBusinessTime className={styles.integrationServiceIcon} />,
      title: t("integrationTab.services.orientation.title"),
      description: t("integrationTab.services.orientation.description"),
    },
    {
      id: 2,
      icon: <FaLanguage className={styles.integrationServiceIcon} />,
      title: t("integrationTab.services.languageSupport.title"),
      description: t("integrationTab.services.languageSupport.description"),
    },
    {
      id: 3,
      icon: <FaCalendarAlt className={styles.integrationServiceIcon} />,
      title: t("integrationTab.services.socialEvents.title"),
      description: t("integrationTab.services.socialEvents.description"),
    },
    {
      id: 4,
      icon: <FaHandsHelping className={styles.integrationServiceIcon} />,
      title: t("integrationTab.services.personalSupport.title"),
      description: t("integrationTab.services.personalSupport.description"),
    },*/
  ];

  return (
    <div className={styles.integrationServiceContainer}>
      <h2 className={styles.integrationServiceHeading}>{t("integrationTab.heading")}</h2>
      <p className={styles.integrationServiceDescription}>{t("integrationTab.description")}</p>
      <div className={styles.integrationServiceGrid}>
        {servicesData.map((service) => (
          <div key={service.id} className={styles.integrationServiceCard}>
            <div className={styles.integrationServiceIconContainer}>
              {service.icon}
            </div>
            <h3 className={styles.integrationServiceTitle}>{service.title}</h3>
            <p className={styles.integrationServiceDescriptionText}>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default IntegrationTab;
