// src/components/RelocationTool/RelocationToolTab.tsx

import React, { useState } from "react";
import styles from "../../../styles/Company Dashboard styles/Companies Services styles/RelocationToolTab.module.css";
import {
  FcVideoFile,
  FcIdea,
  FcQuestions,
  FcVoicePresentation,
  FcAcceptDatabase,
  FcSurvey,
} from "react-icons/fc";
import { useTranslation } from "react-i18next";

interface CardData {
  id: number;
  icon: JSX.Element;
  title: string;
  description: string;
  videoId?: string;
}

const RelocationToolTab: React.FC = () => {
  const { t } = useTranslation("global");

  const cardsData: CardData[] = [
    {
      id: 0,
      icon: <FcSurvey className={styles.relocationServiceIcon} />,
      title: t("relocationToolTab.cards.individualizedJourney.title"),
      description: t("relocationToolTab.cards.individualizedJourney.description"),
      videoId: "YOUR_VIDEO_ID_4",
    },
    {
      id: 1,
      icon: <FcQuestions className={styles.relocationServiceIcon} />,
      title: t("relocationToolTab.cards.relocationChat.title"),
      description: t("relocationToolTab.cards.relocationChat.description"),
      videoId: "YOUR_VIDEO_ID_1",
    },
    {
      id: 2,
      icon: <FcVideoFile className={styles.relocationServiceIcon} />,
      title: t("relocationToolTab.cards.expertVideos.title"),
      description: t("relocationToolTab.cards.expertVideos.description"),
      videoId: "YOUR_VIDEO_ID_2",
    },
    {
      id: 4,
      icon: <FcIdea className={styles.relocationServiceIcon} />,
      title: t("relocationToolTab.cards.comingSoon.title"),
      description: t("relocationToolTab.cards.comingSoon.description"),
    },
  ];

  const [activeId, setActiveId] = useState<number | null>(null);

  const toggleAccordion = (id: number) => {
    setActiveId(activeId === id ? null : id);
  };

  return (
    <div className={styles.relocationServiceContainer}>
      <h2 className={styles.relocationServiceHeading}>{t("relocationToolTab.heading")}</h2>
      <p className={styles.relocationServiceDescription}>{t("relocationToolTab.description")}</p>
      <div className={styles.relocationServiceAccordion}>
        {cardsData.map((card) => (
          <div key={card.id} className={styles.relocationServiceAccordionItem}>
            <button
              className={styles.relocationServiceAccordionButton}
              onClick={() => toggleAccordion(card.id)}
              aria-expanded={activeId === card.id}
              aria-controls={`service-content-${card.id}`}
            >
              <div className={styles.relocationServiceAccordionHeader}>
                <div className={styles.relocationServiceIconContainer}>
                  {card.icon}
                </div>
                <span className={styles.relocationServiceAccordionTitle}>{card.title}</span>
              </div>
              <span
                className={`${styles.relocationServiceAccordionIcon} ${
                  activeId === card.id ? styles.active : ""
                }`}
              >
                {activeId === card.id ? "-" : "+"}
              </span>
            </button>
            <div
              id={`service-content-${card.id}`}
              className={`${styles.relocationServiceAccordionContent} ${
                activeId === card.id ? styles.show : ""
              }`}
            >
              <p>{card.description}</p>
              {card.videoId && (
                <iframe
                  className={styles.relocationServiceCardVideo}
                  src={`https://www.youtube.com/embed/${card.videoId}`}
                  title={`${card.title} Video`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelocationToolTab;
