// src/components/Chat.tsx

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  FormEvent,
} from "react";
import logo from "../../assets/logo.png";
import styles from "../../styles/Chat styles/chat.module.css";
import { AuthContext } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RequiredActionFunctionToolCall } from "openai/resources/beta/threads/runs/runs";
import { useTranslation } from "react-i18next";

type MessageProps = {
  role: "user" | "assistant";
  text: string;
  isLoading?: boolean;
};

const formatText = (text: string) => {
  return text.replace(/【.*?】/g, ""); 
};

const UserMessage: React.FC<{ text: string }> = ({ text }) => {
  return (
    <div className={`${styles.message} ${styles.userMessage}`}>{text}</div>
  );
};

const AssistantMessage: React.FC<{ text: string; isLoading?: boolean }> = ({
  text,
  isLoading,
}) => {
  const formattedText = formatText(text)
  return (
    <div
      className={`${styles.message} ${styles.assistantMessage} ${
        isLoading ? styles.assistantLoading : ""
      }`}
    >
      {isLoading ? (
        <span className={styles.assistantLoadingText}>
          Finding the best answer to assist you
        </span>
      ) : (
        <Markdown>{formattedText}</Markdown>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ role, text, isLoading }) => {
  return role === "user" ? (
    <UserMessage text={text} />
  ) : (
    <AssistantMessage text={text} isLoading={isLoading} />
  );
};

type ChatProps = {
  functionCallHandler?: (
    toolCall: RequiredActionFunctionToolCall
  ) => Promise<string>;
};

const predefinedQuestions: string[] = [
  "question1",
  "question2",
  "question3",
  "question4",
];

const Chat: React.FC<ChatProps> = ({
  functionCallHandler = () => Promise.resolve(""),
}) => {
  const { t, i18n } = useTranslation("global"); 
  const [userInput, setUserInput] = useState<string>("");
  const [messages, setMessages] = useState<MessageProps[]>([]);
  const [inputDisabled, setInputDisabled] = useState<boolean>(false);
  const [threadId, setThreadId] = useState<string | null>(null); 

  const [error, setError] = useState<string | null>(null);
  const [isCreatingThread, setIsCreatingThread] = useState<boolean>(false);

  const { isAuthenticated, userType, token } = useContext(AuthContext);

  const hasInitializedThread = useRef(false);

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const initializeThread = async () => {
      if (hasInitializedThread.current) return;
  
      try {
        setIsCreatingThread(true);
        const res = await fetch(`https://api.relotu.com/chatbot/threads`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token!,
          },
          body: JSON.stringify({}), 
        });
  
        if (!res.ok) {
          const errorText = await res.text();
          throw new Error(`Error creating thread: ${errorText}`);
        }
  
        const data = await res.json();
  
        if (data.id) {
          setThreadId(data.id);
          localStorage.setItem("threadId", data.id);
          hasInitializedThread.current = true;
        } else {
          throw new Error("Id not found.");
        }
      } catch (error: any) {
        setError("Thread not created");
      } finally {
        setIsCreatingThread(false);
      }
    };
  
    localStorage.removeItem("threadId");
  
    if (isAuthenticated) {
      initializeThread();
    }
  }, [isAuthenticated, token]);

  const sendMessage = async (text: string) => {
    if (!threadId) {
      setError("Thread not created");
      return;
    }

    try {
      const res = await fetch(
        `https://api.relotu.com/chatbot/threads/${threadId}/messages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token!,
          },
          body: JSON.stringify({
            message: text,
          }),
        }
      );

      if (!res.ok) {
        const errorText = await res.text();
        throw new Error(`Error sending the message: ${errorText}`);
      }

      const responseText = await res.text();

      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (
          lastMessage.role === "assistant" &&
          lastMessage.text === "Finding the best answer to assist you..."
        ) {
          updatedMessages[updatedMessages.length - 1] = {
            role: "assistant",
            text: responseText,
          };
        } else {
          updatedMessages.push({ role: "assistant", text: responseText });
        }
        return updatedMessages;
      });

      setInputDisabled(false);
    } catch (error: any) {
      setError(error.message || "Error sending the message.");
      setInputDisabled(false);
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", text: userInput },
      {
        role: "assistant",
        text: "Finding the best answer to assist you...",
        isLoading: true,
      },
    ]);

    setInputDisabled(true);
    sendMessage(userInput);
    setUserInput("");
    scrollToBottom();
  };

  const handlePredefinedQuestionClick = (questionKey: string) => {
    const translatedQuestion = t(`chatbot.predefinedQuestions.${questionKey}`);
  
    // Agregar el mensaje predefinido del usuario (ya traducido)
    setMessages((prevMessages) => [
      ...prevMessages,
      { role: "user", text: translatedQuestion },
      {
        role: "assistant",
        text: "Finding the best answer to assist you...",
        isLoading: true,
      },
    ]);
  
    setInputDisabled(true); // Deshabilitar input mientras se envía el mensaje
    sendMessage(translatedQuestion);
    scrollToBottom();
  };
  

  return (
    <div className={styles.chatContainer}>
      {/* Mensaje de bienvenida */}
      {messages.length === 0 && (
        <div className={styles.introContainer}>
          <h3 className={styles.introTitle}>{t("chatbot.welcomeTitle")}</h3>
          <p className={styles.introText}>{t("chatbot.welcomeText")}</p>
        </div>
      )}

      {/* Contenedor de mensajes */}
      <div className={styles.messagesContainer}>
        <div className={styles.messages}>
          {messages.map((msg, index) => (
            <Message
              key={index}
              role={msg.role}
              text={msg.text}
              isLoading={msg.isLoading}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </div>

      {/* Preguntas predefinidas */}
      {messages.length === 0 && (
        <div className={styles.predefinedQuestions}>
          {predefinedQuestions.map((questionKey, index) => (
            <div
              className={styles.questionBox}
              key={index}
              onClick={() => handlePredefinedQuestionClick(questionKey)}
            >
              {t(`chatbot.predefinedQuestions.${questionKey}`)}
            </div>
          ))}
        </div>
      )}

      {/* Formulario de envío de mensajes */}
      <form
        onSubmit={handleSubmit}
        className={`${styles.inputForm} ${styles.clearfix}`}
      >
        <input
          type="text"
          className={styles.input}
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder={t("chatbot.inputPlaceholder")}
          disabled={inputDisabled}
          required
        />
        <button
          type="submit"
          className={styles.button}
          disabled={inputDisabled}
        >
          <FontAwesomeIcon icon={faArrowUp} className={styles.buttonIcon} />
          {t("chatbot.sendButton")}
        </button>
      </form>

      {/* Mostrar error si existe */}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default Chat;
