// src/components/MyIndividualsDashboard/MyIndividualsDashboard.tsx

import React, { useState } from "react";
import styles from "../../../styles/Company Dashboard styles/Companies Individuals Styles/MyIndividualsDashboard.module.css";
import ViewIndividuals from "./ViewIndividuals";
import UpgradeIndividuals from "./UpgradeIndividuals";
import { useTranslation } from "react-i18next";

const MyIndividualsDashboard: React.FC = () => {
  const { t } = useTranslation("global"); // Usamos el namespace global
  const [activeTab, setActiveTab] = useState<string>("upgrade");

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.myIndividualsDashboardContainer}>
      <h1 className={styles.myIndividualsHeading}>{t("myIndividualsDashboard.heading")}</h1>

      {/* Tab Navigation */}
      <div className={styles.myIndividualsTabs}>
        <button
          className={`${styles.myIndividualsTabButton} ${activeTab === "upgrade" ? styles.myIndividualsActiveTab : ""}`}
          onClick={() => handleTabClick("upgrade")}
        >
          {t("myIndividualsDashboard.tabs.upgrade")}
        </button>
        <button
          className={`${styles.myIndividualsTabButton} ${activeTab === "view" ? styles.myIndividualsActiveTab : ""}`}
          onClick={() => handleTabClick("view")}
        >
          {t("myIndividualsDashboard.tabs.view")}
        </button>
      </div>

      {/* Tab Content */}
      <div className={styles.myIndividualsTabContent}>
        {activeTab === "upgrade" && <UpgradeIndividuals />}
        {activeTab === "view" && <ViewIndividuals />}
      </div>
    </div>
  );
};

export default MyIndividualsDashboard;
