// FAQ.tsx
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/Individuals styles/FAQ.module.css";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const [t, i18n] = useTranslation("global");

  const faqs = [
    {
      question: t("individuals.faq1-question"),
      answer: t("individuals.faq1-answer"),
    },
    {
      question: t("individuals.faq2-question"),
      answer: t("individuals.faq2-answer"),
    },
    {
      question: t("individuals.faq3-question"),
      answer: t("individuals.faq3-answer"),
    },
    {
      question: t("individuals.faq4-question"),
      answer: t("individuals.faq4-answer"),
    },
    {
      question: t("individuals.faq5-question"),
      answer: t("individuals.faq5-answer"),
    },
  ];

  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <>
      <h1>{t("individuals.faq-title")}</h1>
      <div className={styles.faqContainer}>
        {faqs.map((faq, index) => (
          <div key={index} className={styles.faqItem}>
            <button
              onClick={() => toggleFAQ(index)}
              className={styles.faqButton}
            >
              <FontAwesomeIcon
                className={styles.faIcon}
                icon={expandedIndex === index ? faMinus : faPlus}
              />
              <span>{faq.question}</span>
            </button>
            {expandedIndex === index && <p>{faq.answer}</p>}
          </div>
        ))}
      </div>
    </>
  );
};

export default FAQ;
