import React from "react";
import styles from "../../../styles/Company Dashboard styles/Companies Services styles/CompanyServicesOverviewTab.module.css";
import { FcCheckmark } from "react-icons/fc";
import { useTranslation } from "react-i18next";

const ServiceOverviewTab: React.FC = () => {
  const { t } = useTranslation("global");

  return (
    <div className={styles.companyServicesTableContainer}>
      <h2 className={styles.companyServicesOverviewHeading}>
        {t("serviceOverviewTab.heading")}
      </h2>
      <p className={styles.companyServicesOverviewDescription}>
        {t("serviceOverviewTab.description")}
      </p>
      <div className={styles.companyServicesTableContainer}>
        <table className={styles.companyServicesTable}>
          <thead>
            <tr>
              <th className={styles.companyServicesHeader}>{t("serviceOverviewTab.tableHeaders.services")}</th>
              <th className={styles.companyServicesUserTypeHeader}>{t("serviceOverviewTab.tableHeaders.interested")}</th>
              <th className={styles.companyServicesUserTypeHeader}>{t("serviceOverviewTab.tableHeaders.candidate")}</th>
              <th className={styles.companyServicesUserTypeHeader}>{t("serviceOverviewTab.tableHeaders.mover")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.companyServicesServiceName}>
                {t("serviceOverviewTab.tableRows.accessTool")}
              </td>
              <td className={styles.companyServicesCheckIcon}><FcCheckmark /></td>
              <td className={styles.companyServicesCheckIcon}><FcCheckmark /></td>
              <td className={styles.companyServicesCheckIcon}><FcCheckmark /></td>
            </tr>
            <tr>
              <td className={styles.companyServicesServiceName}>
                {t("serviceOverviewTab.tableRows.relocationSupport")}
              </td>
              <td className={styles.companyServicesEmptyCell}></td>
              <td className={styles.companyServicesCheckIcon}>
                {t("serviceOverviewTab.tableRows.relocationSupportLevels.highLevel")}
              </td>
              <td className={styles.companyServicesCheckIcon}>
                {t("serviceOverviewTab.tableRows.relocationSupportLevels.detailed")}
              </td>
            </tr>
            <tr>
              <td className={styles.companyServicesServiceName}>
                {t("serviceOverviewTab.tableRows.calls")}
              </td>
              <td className={styles.companyServicesEmptyCell}></td>
              <td className={styles.companyServicesCheckIcon}>
                {t("serviceOverviewTab.tableRows.callNumbers.oneCall")}
              </td>
              <td className={styles.companyServicesCheckIcon}>
                {t("serviceOverviewTab.tableRows.callNumbers.threeCalls")}
              </td>
            </tr>
            <tr>
              <td className={styles.companyServicesServiceName}>
                {t("serviceOverviewTab.tableRows.integration")}
              </td>
              <td className={styles.companyServicesEmptyCell}></td>
              <td className={styles.companyServicesEmptyCell}></td>
              <td className={styles.companyServicesCheckIcon}><FcCheckmark /></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ServiceOverviewTab;
