// Home.tsx
import React from "react";
import IndividualsTextImage from "./Individuals components/IndividualsTextImage";
import IndividualsVideoText from "./Individuals components/IndividualsVideoText";
import FAQ from "./Individuals components/FAQ";

const Individuals = () => {
  return (
    <div>
      <IndividualsTextImage />
      <IndividualsVideoText />
      <FAQ/>
    </div>
  );
};

export default Individuals;

