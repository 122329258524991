// OpeningCompanies.tsx
import React from "react";
import talentImg from "../../assets/target.svg";
import styles from "../../styles/Landing Page styles/OpeningCompanies.module.css"; // Asegúrate de tener tu archivo de estilos
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OpeningCompanies: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return <div className={styles.openingCompanies}></div>;
};

export default OpeningCompanies;
