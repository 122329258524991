// src/components/Legal components/TermsAndConditions.tsx

import React from "react";
import styles from "../../styles/Legal styles/TermsAndConditions.module.css";
import { useTranslation } from "react-i18next";

const TermsAndConditions: React.FC = () => {
  const { t } = useTranslation("global");

  return (
    <div className={styles.container}>
      <h1 className={styles.header}>
        {t("terms.title")} - {t("terms.lastUpdated")}
      </h1>

      {/* Section 1 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-1")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-1-paragraph1")}
        <br />
        <br />
        {t("terms.sections.section-1-paragraph2")}
        <br />
        <br />
        {t("terms.sections.section-1-paragraph3")}
        <br />
        <br />
        {t("terms.sections.section-1-paragraph4")}
      </p>

      {/* Section 2 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-2")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-2-paragraph1")}
        <br />
        <br />
        {t("terms.sections.section-2-paragraph2")}
        <br />
        <br />
        {t("terms.sections.section-2-paragraph3")}
        <br />
        <br />
        {t("terms.sections.section-2-paragraph4")}
      </p>

      {/* Section 3 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-3")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-3-paragraph1")}
        <br />
        <br />
        {t("terms.sections.section-3-paragraph2")}
        <br />
        <br />
        {t("terms.sections.section-3-paragraph3")}
        <br />
        <br />
        {t("terms.sections.section-3-paragraph4")}
      </p>

      {/* Section 4 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-4")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-4-paragraph1")}
        <br />
        <br />
        {t("terms.sections.section-4-paragraph2")}
        <br />
        <br />
        {t("terms.sections.section-4-paragraph3")}
      </p>

      {/* Section 5 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-5")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-5-paragraph1")}
        <br />
        <br />
        {t("terms.sections.section-5-paragraph2")}
        <br />
        <br />
        {t("terms.sections.section-5-paragraph3")}
        <br />
        <br />
        {t("terms.sections.section-5-paragraph4")}
        <br />
        <br />
        {t("terms.sections.section-5-paragraph5")}
        <br />
        <br />
        {t("terms.sections.section-5-paragraph6")}
      </p>

      {/* Section 6 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-6")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-6-paragraph1")}
        <br />
        <br />
        {t("terms.sections.section-6-paragraph2")}
      </p>

      {/* Section 7 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-7")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-7-paragraph1")}
      </p>
      <p className={styles.paragraph}>
        {t("terms.sections.section-7-paragraph2")}
      </p>
      <p className={styles.paragraph}>
        {t("terms.sections.section-7-paragraph3")}
      </p>
      <p className={styles.paragraph}>
        {t("terms.sections.section-7-paragraph4")}
      </p>
      <p className={styles.paragraph}>
        {t("terms.sections.section-7-paragraph5")}
      </p>

      {/* Section 8 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-8")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-8-paragraph1")}
      </p>
      <p className={styles.paragraph}>
        {t("terms.sections.section-8-paragraph2")}
      </p>

      {/* Section 9 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-9")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-9-paragraph1")}
      </p>
      <p className={styles.paragraph}>
        {t("terms.sections.section-9-paragraph2")}
      </p>

      {/* Section 10 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-10")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-10-paragraph1")}
      </p>

      {/* Section 11 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-11")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-11-paragraph1")}
      </p>

      {/* Section 12 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-12")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-12-paragraph1")}
      </p>
      <p className={styles.paragraph}>
        {t("terms.sections.section-12-paragraph2")}
      </p>

      {/* Section 13 */}
      <h2 className={styles.header}>{t("terms.sections.title-section-13")}</h2>
      <p className={styles.paragraph}>
        {t("terms.sections.section-13-paragraph1")}
      </p>
    </div>
  );
};

export default TermsAndConditions;
