import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaw,
  faBaby,
  faCar,
  faUser,
  faHeart,
} from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/Tasks styles/MovingSelectionForm.module.css";
import { useTranslation } from "react-i18next";

interface MoverInfo {
  moveWithPartner: boolean;
  moveWithPets: boolean;
  moveWithKids: boolean;
  moveWithCar: boolean;
  isEuCitizen: boolean;
}

interface MovingSelectionFormProps {
  onDetailChange: (detail: keyof MoverInfo, value: boolean) => void;
}

const MovingSelectionForm: React.FC<MovingSelectionFormProps> = ({
  onDetailChange,
}) => {
  const [t] = useTranslation("global");

  // Map the selections to match the `MoverInfo` keys
  const selections = [
    { id: "moveWithPartner" as keyof MoverInfo, label: t("moving-form.selection1"), icon: faHeart },
    { id: "moveWithPets" as keyof MoverInfo, label: t("moving-form.selection2"), icon: faPaw },
    { id: "moveWithKids" as keyof MoverInfo, label: t("moving-form.selection3"), icon: faBaby },
    { id: "isEuCitizen" as keyof MoverInfo, label: t("moving-form.selection4"), icon: faUser },
    { id: "moveWithCar" as keyof MoverInfo, label: t("moving-form.selection5"), icon: faCar },
  ];

  const handleChange = (id: keyof MoverInfo, checked: boolean) => {
    onDetailChange(id, checked);
  };

  return (
    <div className={styles.form}>
      <h2>{t("moving-form.title")}</h2>
      <div className={styles.optionsContainer}>
        {selections.map((selection) => (
          <div key={selection.id} className={styles.option}>
            <input
              type="checkbox"
              id={selection.id}
              onChange={(e) => handleChange(selection.id, e.target.checked)}
            />
            <label htmlFor={selection.id}>
              <FontAwesomeIcon icon={selection.icon} className={styles.icon} />
              {selection.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MovingSelectionForm;
