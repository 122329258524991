import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "./styles/styles.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { AuthProvider } from "./context/AuthContext";

import global_en from "../src/translations/en/global.json";
import global_de from "../src/translations/de/global.json";
import global_sp from "../src/translations/sp/global.json";
import global_it from "../src/translations/it/global.json";
import global_fr from "../src/translations/fr/global.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    de: {
      global: global_de,
    },
    sp: {
      global: global_sp,
    },
    it: {
      global: global_it,
    },
    fr: {
      global: global_fr,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <I18nextProvider i18n={i18next}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </I18nextProvider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
