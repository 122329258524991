// src/components/UserTypeSelection.tsx

import React from "react";
import styles from "../../styles/Authentication styles/UserTypeSelection.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import companyImage from "../../assets/OrganizationsImg.png"; 
import individualImage from "../../assets/IndividualsImg.png"; // Update the path to your image

const UserTypeSelection: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  return (
    <div className={styles.userTypeSelection}>
      <div
        className={styles.card}
        onClick={() => navigate("/registration-organization")}
      >
        <img src={companyImage} alt="Company" className={styles.image} />
        <h2>{t("authentication.user-selection-type-company-title")}</h2>
        <p>{t("authentication.user-selection-type-company-message")}</p>
      </div>

      <div
        className={styles.card}
        onClick={() => navigate("/registration-individuals")}
      >
        <img src={individualImage} alt="Individual" className={styles.image} />
        <h2>{t("authentication.user-selection-type-individual-title")}</h2>
        <p>{t("authentication.user-selection-type-individual-message")}</p>
      </div>
    </div>
  );
};

export default UserTypeSelection;
