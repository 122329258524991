// ProtectedPage.tsx
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import styles from "../../styles/Authentication styles/EmailConfirmationSent.module.css";
import { useTranslation } from "react-i18next";
import emailConfirmationSentImg from "../../assets/emailSuccessfulValidation.png";

export const EmailSentConfirmation: React.FC<{
  onProtectedPage: () => void;
  notOnProtectedPage: () => void;
}> = ({ onProtectedPage, notOnProtectedPage }) => {
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  useEffect(() => {
    onProtectedPage();
    return () => {
      notOnProtectedPage();
    };
  }, [onProtectedPage, notOnProtectedPage]);

  return (
    <div className={styles.emailConfirmationSentContainer}>
      <img src={logo} alt="Logo" className={styles.logoEmailConfirmationSent} />
      <div className={styles.emailConfirmationSentImagContainer}>
        <img
          src={emailConfirmationSentImg}
          alt="Confirmation Illustration"
          className={styles.emailConfirmationSentImag}
        />
      </div>
      {/* Text box container */}
      <div className={styles.textBoxContainer}>
        <p>{t("authentication.email-sent-confirmation")}</p>
      </div>
    </div>
  );
};
