// src/components/Footer.tsx

import React, { useContext } from "react";
import styles from "../styles/Footer.module.css";
import logo from "../assets/logoName.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Import AuthContext
import { AuthContext } from "../context/AuthContext";

const Footer: React.FC = () => {
  const { t } = useTranslation("global");
  const { isAuthenticated } = useContext(AuthContext);

  // Do not render footer if user is authenticated
  if (isAuthenticated) {
    return null;
  }

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerLogoSection}>
        <img src={logo} alt="Logo" className={styles.footerLogo} />
      </div>

      <div className={styles.footerSectionList}>
        <div className={styles.footerListContainer}>
          <ul className={styles.footerList}>
            <h3>{t("footer.menu-title")}</h3>
            <li>
              <Link to="/">{t("footer.menu-home")}</Link>
            </li>
            <li>
              <Link to="/contact-us">{t("footer.menu-contact")}</Link>
            </li>
            <li>
              <Link to="/impressum">{t("footer.menu-impressum")}</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footerListContainer}>
          <ul className={styles.footerList}>
            <h3>{t("footer.services-title")}</h3>
            <li>
              <Link to="/companies-landing-page">
                {t("footer.services-company")}
              </Link>
            </li>
            <li>
              <Link to="/individuals-landing-page">{t("footer.services-mover")}</Link>
            </li>
          </ul>
        </div>
        <div className={styles.footerListContainer}>
          <ul className={styles.footerList}>
            <h3>{t("footer.resources-title")}</h3>
            <li>
              <Link to="/privacy-policy">
                {t("footer.resources-privacy-policy")}
              </Link>
            </li>
            <li>
              <Link to="/cookies-settings">{t("footer.resources-cookies")}</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">{t("footer.terms-and-conditions")}</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className={styles.footerSection}>
        <p>
          &copy; {new Date().getFullYear()} {t("footer.rights-reserved")}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
