import React from "react";
import styles from "../styles/Calendly.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

interface CalendlyComponentProps {
  title: string;
  boldText: string;  // Bold text for the span
  normalText: string;  // Normal text after the span
  whatsappText?: string;
  whatsappLink?: string;
  calendlyLink: string;
}

const CalendlyComponent: React.FC<CalendlyComponentProps> = ({
  title,
  boldText,
  normalText,
  whatsappText,
  whatsappLink,
  calendlyLink,
}) => {
  return (
    <div className={styles.contactUsLayout}>
      <div className={styles.calendlyContainer}>
        <h2>{title}</h2>
        <p>
          <span>{boldText}</span>
          {normalText}
        </p>

        {whatsappText && whatsappLink && (
          <p>
            <a
              href={whatsappLink}
              className={styles.whatsappLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                className={styles.whatsappIcon}
              />{" "}
              {whatsappText}
            </a>
          </p>
        )}

        <iframe src={calendlyLink} frameBorder="0" allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default CalendlyComponent;
