// src/components/Company Dashboard components/Profile components/CompanyNotifications.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../styles/Company Dashboard styles/Profile styles/CompanyProfile.module.css";

const CompanyNotifications: React.FC<{
  notifications: {
    messages: boolean;
    activityUpdates: boolean;
    importantAlerts: boolean;
    frequency: string;
    email: boolean;
    inPlatform: boolean;
  };
  handleNotificationChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}> = ({ notifications, handleNotificationChange }) => {
  const { t } = useTranslation("global");

  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const startEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    // Aquí puedes resetear las notificaciones si es necesario
    setIsEditing(false);
  };

  const saveEditing = () => {
    // Aquí puedes manejar el guardado de las notificaciones
    setIsEditing(false);
  };

  return (
    <div className={styles.companyProfileNotifications}>
      <h2 className={styles.companyProfileSectionTitle}>
        {t("companyProfile.notifications.title")}
      </h2>
      <div className={styles.notificationSettings}>
        {/* Tipos de Notificaciones */}
        <label className={styles.notificationItem} data-tooltip={t("companyProfile.notifications.messagesTooltip")}>
          <input
            type="checkbox"
            name="messages"
            checked={notifications.messages}
            onChange={handleNotificationChange}
            disabled={!isEditing}
          />
          {t("companyProfile.notifications.messages")}
        </label>

        <label className={styles.notificationItem} data-tooltip={t("companyProfile.notifications.activityUpdatesTooltip")}>
          <input
            type="checkbox"
            name="activityUpdates"
            checked={notifications.activityUpdates}
            onChange={handleNotificationChange}
            disabled={!isEditing}
          />
          {t("companyProfile.notifications.activityUpdates")}
        </label>

        <label className={styles.notificationItem} data-tooltip={t("companyProfile.notifications.importantAlertsTooltip")}>
          <input
            type="checkbox"
            name="importantAlerts"
            checked={notifications.importantAlerts}
            onChange={handleNotificationChange}
            disabled={!isEditing}
          />
          {t("companyProfile.notifications.importantAlerts")}
        </label>

        {/* Frecuencia de Notificaciones */}
        <div className={styles.notificationFrequency}>
          <label data-tooltip={t("companyProfile.notifications.frequencyTooltip")}>
            {t("companyProfile.notifications.frequency")}:
          </label>
          <select
            name="frequency"
            value={notifications.frequency}
            onChange={handleNotificationChange}
            disabled={!isEditing}
          >
            <option value="immediate">{t("companyProfile.notifications.frequencyImmediate")}</option>
            <option value="daily">{t("companyProfile.notifications.frequencyDaily")}</option>
            <option value="weekly">{t("companyProfile.notifications.frequencyWeekly")}</option>
          </select>
        </div>

        {/* Canales de Notificación */}
        <label className={styles.notificationItem} data-tooltip={t("companyProfile.notifications.emailTooltip")}>
          <input
            type="checkbox"
            name="email"
            checked={notifications.email}
            onChange={handleNotificationChange}
            disabled={!isEditing}
          />
          {t("companyProfile.notifications.email")}
        </label>

        <label className={styles.notificationItem} data-tooltip={t("companyProfile.notifications.inPlatformTooltip")}>
          <input
            type="checkbox"
            name="inPlatform"
            checked={notifications.inPlatform}
            onChange={handleNotificationChange}
            disabled={!isEditing}
          />
          {t("companyProfile.notifications.inPlatform")}
        </label>
      </div>

      {/* Botones de Editar / Guardar / Cancelar */}
      <div className={styles.editButtonsContainer}>
        {isEditing ? (
          <>
            <button
              className={styles.companyProfileSaveButton}
              onClick={saveEditing}
              type="button"
            >
              {t("companyProfile.notifications.save")}
            </button>
            <button
              className={styles.companyProfileCancelButton}
              onClick={cancelEditing}
              type="button"
            >
              {t("companyProfile.notifications.cancel")}
            </button>
          </>
        ) : (
          <button
            className={styles.companyProfileEditButton}
            onClick={startEditing}
            type="button"
          >
            {t("companyProfile.notifications.edit")}
          </button>
        )}
      </div>
    </div>
  );
};

export default CompanyNotifications;
