// src/components/Authentication components/LoginIndividuals.tsx
import React, { useState, ChangeEvent, FormEvent, useContext } from "react";
import styles from "../../styles/Authentication styles/LogIn.module.css";
import { Link, useNavigate } from "react-router-dom";
import loginImage from "../../assets/loginIndividuals.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

// Import FontAwesomeIcon and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../context/AuthContext";

// Import AuthContext

const LoginIndividuals: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false); // State to show/hide password
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation("global");
  const navigate = useNavigate();

  // Access login function from AuthContext
  const { login } = useContext(AuthContext);

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  // Function to generate the loginHash using SHA-256
  async function generateLoginHash(email: string, password: string): Promise<string> {
    const data = email + password;
    const encoder = new TextEncoder();
    const dataBuffer = encoder.encode(data);
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", dataBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join('');
    return hashHex;
  }

  // Function to validate email format
  function validateEmail(email: string): boolean {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  // Handle form submission
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrorMessage(null);
    setLoading(true);

    // Verify CAPTCHA
    /*
    if (!captchaToken) {
      setErrorMessage(t("authentication.login-individuals-captcha-required"));
      setLoading(false);
      return;
    }
    */

    // Validate email and password
    if (!username || !password) {
      setErrorMessage(t("authentication.login-individuals-enter-email-password"));
      setLoading(false);
      return;
    }

    if (!validateEmail(username)) {
      setErrorMessage(t("authentication.login-individuals-valid-email"));
      setLoading(false);
      return;
    }

    try {
      // Generate the loginHash
      const loginHash = await generateLoginHash(username, password);

      // Send the request to the backend for authentication
      const response = await fetch("https://api.relotu.com/auth/individuals", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          loginHash: loginHash,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        const { token } = data;

        // Store the token securely
        localStorage.setItem("token", token);
        localStorage.setItem("loggedAs", "individual");
        localStorage.removeItem("threadId");



        // Update authentication state
        login(token);

        // Redirect to the dashboard or another secure page
        navigate("/my-journey");
      } else {
        const errorData = await response.json();
        setErrorMessage(t("authentication.login-individuals-login-failed") + ": " + errorData.message);
      }
    } catch (error) {
      console.error("Error during the login request:", error);
      setErrorMessage(t("authentication.login-individuals-login-error"));
    } finally {
      setLoading(false);
    }
  };

  // Handle CAPTCHA change
  const onCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  // Function to toggle password visibility
  const toggleShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div>
      <h2 className={styles.title}>
        {t("authentication.login-individuals-title")}{" "}
        <span role="img" aria-label="sparkles" className={styles.icon}>
          ✨
        </span>
      </h2>
      <div className={styles.loginPageContainer}>
        <img src={loginImage} alt="Login" className={styles.loginImage} />
        <div className={styles.loginContainer}>
          <h4 className={styles.subtitle}>
            {t("authentication.login-individuals-subtitle")}{" "}
            <Link to="/login-organization" className={styles.link}>
              {t("authentication.login-not-individuals-link")}
            </Link>
          </h4>
          <form onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="email">
                {t("authentication.login-individuals-email")}
              </label>
              <input
                placeholder="e.g. name@xyz.com"
                type="text"
                id="email"
                value={username}
                onChange={handleUsernameChange}
                required
                disabled={loading}
                className={styles.input}
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="password">
                {t("authentication.login-individuals-password")}
              </label>
              <div className={styles.inputWrapper}>
                <input
                  placeholder="**********"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  disabled={loading}
                  className={styles.input}
                />
                <button
                  type="button"
                  className={styles.eyeButton}
                  onClick={toggleShowPassword}
                  aria-label={
                    showPassword
                      ? t("authentication.hide-password")
                      : t("authentication.show-password")
                  }
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button>
              </div>
            </div>

            {/* reCAPTCHA */}
            <div className={styles.captchaContainer}>
              <ReCAPTCHA
                sitekey="6LemUDkqAAAAAOJ4x2URJ9cCgIQHOFbQlXzWD5Ru"
                onChange={onCaptchaChange}
              />
            </div>

            {/* Show error message if login fails */}
            {errorMessage && (
              <p className={styles.error}>{errorMessage}</p>
            )}

            <button
              className={styles.loginButton}
              type="submit"
              disabled={loading}
            >
              {loading ? t("authentication.login-individuals-logging-in") : t("authentication.login-individuals-login-button")}
            </button>
            {/*<h4 className={styles.subtitle}>
              {t("authentication.login-individuals-not-registered")}{" "}
              <Link to="/registration-individuals" className={styles.link}>
                {t("authentication.login-individuals-not-registered-link")}
              </Link>
            </h4>*/}

            <div className={styles.forgotPassword}>
            <Link to="/recover-password">
                {t("authentication.login-individuals-forgot-password")}
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginIndividuals;
