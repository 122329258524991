// src/components/Company Dashboard components/Profile components/CompanyProfile.tsx
import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../context/AuthContext";
import styles from "../../../styles/Company Dashboard styles/Profile styles/CompanyProfile.module.css";
import CompanyProfileTabs from "./CompanyProfileTabs";
import CompanyDetails from "./CompanyDetails";
import CompanyNotifications from "./CompanyNotifications";
import CompanyChangePassword from "./CompanyChangePassword"; // Asegúrate de que el nombre del componente sea consistente

const CompanyProfile: React.FC = () => {
  const { t } = useTranslation("global");
  const { token } = useContext(AuthContext);

  const [company, setCompany] = useState<any>(null);
  const [editedCompany, setEditedCompany] = useState<any>(null);
  const [activeTab, setActiveTab] = useState<string>("details");

  const [notifications, setNotifications] = useState({
    messages: true,
    activityUpdates: false,
    importantAlerts: true,
    frequency: "daily",
    email: true,
    inPlatform: true,
  });

  // Manejar cambios en las notificaciones
  const handleNotificationChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    let checked = false;

    if (type === "checkbox") {
      checked = (e.target as HTMLInputElement).checked;
    }

    setNotifications({
      ...notifications,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Manejar cambios en los campos editados de detalles
  const handleDetailsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (editedCompany) {
      const { name, value } = e.target;
      setEditedCompany({ ...editedCompany, [name]: value });
    }
  };

  // Guardar cambios de detalles
  const handleDetailsSave = async () => {
    if (!editedCompany) return;

    try {
      const response = await fetch(
        `https://api.relotu.com/companies/${editedCompany.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token!,
          },
          body: JSON.stringify(editedCompany),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Error actualizando los datos de la empresa");
      }

      const updatedData = await response.json();
      setCompany(updatedData);
      setEditedCompany(updatedData);
    } catch (error: any) {
      alert(error.message || "Error al actualizar los datos de la empresa.");
    }
  };

  // Cancelar cambios de detalles
  const handleDetailsCancel = () => {
    if (company) {
      setEditedCompany(company);
    }
  };

  // Fetch Company Data
  useEffect(() => {
    const fetchCompany = async () => {
      try {
        const response = await fetch("https://api.relotu.com/companies", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token!,
          },
        });

        const contentType = response.headers.get("content-type");

        if (!response.ok) {
          if (contentType && contentType.includes("application/json")) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Error obteniendo los datos de la empresa");
          } else {
            const errorText = await response.text();
            throw new Error(errorText || "Error obteniendo los datos de la empresa");
          }
        }

        const data =
          contentType && contentType.includes("application/json")
            ? await response.json()
            : null;

        setCompany(data);
        setEditedCompany(data);
      } catch (error: any) {
        setCompany(null);
        alert(error.message || "Error inesperado");
      }
    };

    if (token) {
      fetchCompany();
    } else {
      alert(t("companyProfile.error", { message: t("companyProfile.noData") }));
    }
  }, [token, t]);

  if (!company) {
    return <div className={styles.companyProfileNoData}>{t("companyProfile.noData")}</div>;
  }

  // Determine Assistant Display Value
  const assistantDisplay =
    company.assistantId === "asst_z6qW5kVgJeepe2lBwQHVLYxB"
      ? t("common.generalKnowledge")
      : company.assistantId || "N/A";

  return (
    <div className={styles.companyProfileContainer}>
      {/* Basic Profile */}
      <section className={styles.companyProfileBasicProfile}>
        <div className={styles.companyProfileLogoPlaceholder}>
          {company.avatar ? (
            <img
              src={
                company.avatar.startsWith("http")
                  ? company.avatar
                  : `https://api.relotu.com${company.avatar}`
              }
              alt={`${company.name} Avatar`}
              className={styles.companyProfileLogoImage}
              onError={(e: any) => {
                e.currentTarget.src = "/default-avatar.png";
              }}
            />
          ) : (
            "Logo"
          )}
        </div>
        <h1 className={styles.companyProfileCompanyName}>
          {company.name || t("companyProfile.basicProfile.companyName")}
        </h1>
        <p className={styles.companyProfileSlogan}>
          {company.slogan || t("companyProfile.basicProfile.slogan")}
        </p>
        <p className={styles.companyProfileLocation}>
          {company.location
            ? t("companyProfile.basicProfile.location", {
                location: company.location,
              })
            : t("companyProfile.basicProfile.location", { location: "N/A" })}
        </p>
      </section>

      {/* Tabs */}
      <CompanyProfileTabs activeTab={activeTab} setActiveTab={setActiveTab} />

      {/* Tab Content */}
      <section className={styles.companyProfileTabContent}>
        {activeTab === "details" && (
          <CompanyDetails
            company={company}
            isEditing={!!editedCompany}
            handleChange={handleDetailsChange}
            handleSave={handleDetailsSave}
            handleCancel={handleDetailsCancel}
          />
        )}

        {activeTab === "changePassword" && <CompanyChangePassword />}

        {activeTab === "notifications" && (
          <CompanyNotifications
            notifications={notifications}
            handleNotificationChange={handleNotificationChange}
          />
        )}
      </section>
    </div>
  );
};

export default CompanyProfile;
