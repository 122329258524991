// src/components/Company Dashboard components/Profile components/CompanyChangePassword.tsx
import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../styles/Company Dashboard styles/Profile styles/CompanyProfile.module.css";
import { AuthContext } from "../../../context/AuthContext";
import PasswordValidation from "../../Authentication components/ValidationInputs"; // Asegúrate de tener este componente
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const CompanyChangePassword: React.FC = () => {
  const { t } = useTranslation("global");
  const { token } = useContext(AuthContext); // Obtener el token desde el contexto

  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");

  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (password !== confirmPassword) {
      setErrorMessage(t("companyProfile.changePassword.passwordMismatch"));
      return;
    }

    setLoading(true);

    try {
      const response = await fetch("https://api.relotu.com/companies/change-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-relotu-access": token!,
        },
        body: JSON.stringify({
          currentPassword, // Enviar la contraseña actual
          newPassword: password,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || t("companyProfile.changePassword.error"));
      }

      setSuccessMessage(t("companyProfile.changePassword.success"));
      setCurrentPassword("");
      setPassword("");
      setConfirmPassword("");
    } catch (error: any) {
      setErrorMessage(error.message || t("companyProfile.changePassword.error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.companyProfileChangePassword}>
      <h2 className={styles.companyProfileSectionTitle}>
        {t("companyProfile.changePassword.title")}
      </h2>
      <form onSubmit={handleSubmit} className={styles.changePasswordForm}>
        {/* Contraseña Actual */}
        <div className={styles.formGroup}>
          <label
            htmlFor="currentPassword"
            className={styles.label}
            data-tooltip={t("companyProfile.changePassword.currentPasswordTooltip")}
          >
            {t("companyProfile.changePassword.currentPassword")}
          </label>
          <div className={styles.inputWrapper}>
            <input
              type={showCurrentPassword ? "text" : "password"}
              id="currentPassword"
              value={currentPassword}
              onChange={handleCurrentPasswordChange}
              required
              disabled={loading}
              className={styles.input}
              placeholder={t("companyProfile.changePassword.currentPasswordPlaceholder")}
            />
            <button
              type="button"
              className={styles.eyeButton}
              onClick={toggleShowCurrentPassword}
              aria-label={
                showCurrentPassword
                  ? t("authentication.hidePassword")
                  : t("authentication.showPassword")
              }
            >
              <FontAwesomeIcon icon={showCurrentPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        </div>

        {/* Nueva Contraseña */}
        <div className={styles.formGroup}>
          <label
            htmlFor="password"
            className={styles.label}
            data-tooltip={t("companyProfile.changePassword.newPasswordTooltip")}
          >
            {t("companyProfile.changePassword.newPassword")}
          </label>
          <div className={styles.inputWrapper}>
            <input
              type={showNewPassword ? "text" : "password"}
              id="password"
              value={password}
              onChange={handlePasswordChange}
              required
              disabled={loading}
              className={styles.input}
              placeholder={t("companyProfile.changePassword.newPasswordPlaceholder")}
            />
            <button
              type="button"
              className={styles.eyeButton}
              onClick={toggleShowNewPassword}
              aria-label={
                showNewPassword
                  ? t("authentication.hidePassword")
                  : t("authentication.showPassword")
              }
            >
              <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          {/* Password Validation Component */}
          <PasswordValidation password={password} />
        </div>

        {/* Confirmar Nueva Contraseña */}
        <div className={styles.formGroup}>
          <label
            htmlFor="confirmPassword"
            className={styles.label}
            data-tooltip={t("companyProfile.changePassword.confirmPasswordTooltip")}
          >
            {t("companyProfile.changePassword.confirmPassword")}
          </label>
          <div className={styles.inputWrapper}>
            <input
              type={showNewPassword ? "text" : "password"}
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
              disabled={loading}
              className={styles.input}
              placeholder={t("companyProfile.changePassword.confirmPasswordPlaceholder")}
            />
            <button
              type="button"
              className={styles.eyeButton}
              onClick={toggleShowNewPassword}
              aria-label={
                showNewPassword
                  ? t("authentication.hidePassword")
                  : t("authentication.showPassword")
              }
            >
              <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        </div>

        {/* Mensajes de Error y Éxito */}
        {errorMessage && <p className={styles.error}>{errorMessage}</p>}
        {successMessage && <p className={styles.success}>{successMessage}</p>}

        {/* Botón de Submit */}
        <button
          className={styles.changePasswordButton}
          type="submit"
          disabled={loading}
        >
          {loading
            ? t("companyProfile.changePassword.resetting")
            : t("companyProfile.changePassword.submit")}
        </button>
      </form>
    </div>
  );
};

export default CompanyChangePassword;
