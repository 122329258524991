// src/components/ProfileComponents/CompanyProfileTabs.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../styles/Company Dashboard styles/Profile styles/CompanyProfile.module.css";

const CompanyProfileTabs = ({ activeTab, setActiveTab }: any) => {
  const { t } = useTranslation("global"); // Importa el hook de traducción

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <div className={styles.tabsContainer}>
      <button
        className={`${styles.tabButton} ${activeTab === "details" ? styles.activeTab : ""}`}
        onClick={() => handleTabClick("details")}
      >
        {t("companyProfile.tabs.details")}
      </button>
      <button
        className={`${styles.tabButton} ${activeTab === "changePassword" ? styles.activeTab : ""}`}
        onClick={() => handleTabClick("changePassword")}
      >
        {t("companyProfile.tabs.changePassword")}
      </button>
      <button
        className={`${styles.tabButton} ${activeTab === "notifications" ? styles.activeTab : ""}`}
        onClick={() => handleTabClick("notifications")}
      >
        {t("companyProfile.tabs.notifications")}
      </button>
      {/* Si decides agregar más pestañas en el futuro, puedes hacerlo aquí */}
    </div>
  );
};

export default CompanyProfileTabs;
