// src/components/curiositiesData.ts

export interface Curiosity {
    date: string; // Format 'yyyy-MM-dd'
    type: 'general' | 'specific';
    title: {
      en: string;
      de: string;
    };
    description: {
      en: string;
      de: string;
    };
  }
  
  export const curiosities: Curiosity[] = [
    {
      date: "2024-10-01",
      type: "general",
      title: {
        en: "🏔️ Number of Cantons",
        de: "🏔️ Anzahl der Kantone",
      },
      description: {
        en: "Switzerland is divided into 26 cantons, each with its own constitution.",
        de: "Die Schweiz ist in 26 Kantone unterteilt, jeder mit seiner eigenen Verfassung.",
      },
    },
    {
      date: "2024-10-02",
      type: "general",
      title: {
        en: "🗣️ Official Languages",
        de: "🗣️ Amtssprachen",
      },
      description: {
        en: "Switzerland has four official languages: German, French, Italian, and Romansh.",
        de: "Die Schweiz hat vier Amtssprachen: Deutsch, Französisch, Italienisch und Rätoromanisch.",
      },
    },
    {
      date: "2024-10-03",
      type: "general",
      title: {
        en: "👥 Population",
        de: "👥 Bevölkerung",
      },
      description: {
        en: "As of 2024, Switzerland has a population of approximately 8.7 million people.",
        de: "Im Jahr 2024 hat die Schweiz eine Bevölkerung von etwa 8,7 Millionen Menschen.",
      },
    },
    {
      date: "2024-10-04",
      type: "general",
      title: {
        en: "🌍 Number of Bordering Countries",
        de: "🌍 Anzahl der Nachbarländer",
      },
      description: {
        en: "Switzerland shares its borders with 5 countries: Germany, France, Italy, Austria, and Liechtenstein.",
        de: "Die Schweiz grenzt an 5 Länder: Deutschland, Frankreich, Italien, Österreich und Liechtenstein.",
      },
    },
    {
      date: "2024-10-05",
      type: "general",
      title: {
        en: "🏛️ Capital City",
        de: "🏛️ Hauptstadt",
      },
      description: {
        en: "The capital city of Switzerland is Bern, known for its well-preserved medieval city center.",
        de: "Die Hauptstadt der Schweiz ist Bern, bekannt für ihre gut erhaltene mittelalterliche Altstadt.",
      },
    },
    {
      date: "2024-10-06",
      type: "general",
      title: {
        en: "⌚ Famous Watches",
        de: "⌚ Berühmte Uhren",
      },
      description: {
        en: "Switzerland is renowned for its high-quality watchmaking industry, with brands like Rolex and Omega.",
        de: "Die Schweiz ist bekannt für ihre hochwertige Uhrenindustrie, mit Marken wie Rolex und Omega.",
      },
    },
    {
      date: "2024-10-07",
      type: "general",
      title: {
        en: "🏔️ Alpine Mountains",
        de: "🏔️ Alpen",
      },
      description: {
        en: "The Swiss Alps cover about 60% of Switzerland's total area.",
        de: "Die Schweizer Alpen nehmen etwa 60 % der Gesamtfläche der Schweiz ein.",
      },
    },
    {
      date: "2024-10-08",
      type: "general",
      title: {
        en: "🇨🇭 Swiss Neutrality",
        de: "🇨🇭 Schweizer Neutralität",
      },
      description: {
        en: "Switzerland is famous for its policy of neutrality and has not participated in a foreign war since 1815.",
        de: "Die Schweiz ist bekannt für ihre Neutralitätspolitik und hat seit 1815 an keinem Auslandskrieg teilgenommen.",
      },
    },
    {
      date: "2024-10-09",
      type: "general",
      title: {
        en: "🏦 Banking Secrecy",
        de: "🏦 Bankgeheimnis",
      },
      description: {
        en: "Switzerland is known for its banking secrecy laws, attracting clients worldwide.",
        de: "Die Schweiz ist bekannt für ihre Gesetze zum Bankgeheimnis, die Kunden aus aller Welt anziehen.",
      },
    },
    {
      date: "2024-10-10",
      type: "general",
      title: {
        en: "🍫 Swiss Chocolate",
        de: "🍫 Schweizer Schokolade",
      },
      description: {
        en: "Switzerland is one of the largest producers of chocolate in the world, known for its high quality.",
        de: "Die Schweiz ist einer der größten Schokoladenproduzenten der Welt, bekannt für ihre hohe Qualität.",
      },
    },
    {
      date: "2024-10-11",
      type: "general",
      title: {
        en: "🚆 Public Transport",
        de: "🚆 Öffentlicher Verkehr",
      },
      description: {
        en: "Switzerland has an efficient and punctual public transport system, including trains, buses, and trams.",
        de: "Die Schweiz verfügt über ein effizientes und pünktliches öffentliches Verkehrssystem, einschließlich Zügen, Bussen und Straßenbahnen.",
      },
    },
    {
      date: "2024-10-12",
      type: "general",
      title: {
        en: "🔪 Swiss Army Knife",
        de: "🔪 Schweizer Taschenmesser",
      },
      description: {
        en: "The Swiss Army Knife is a popular multi-tool originating from Switzerland, known for its versatility.",
        de: "Das Schweizer Taschenmesser ist ein beliebtes Multifunktionswerkzeug aus der Schweiz, bekannt für seine Vielseitigkeit.",
      },
    },
    {
      date: "2024-10-13",
      type: "general",
      title: {
        en: "♻️ Recycling",
        de: "♻️ Recycling",
      },
      description: {
        en: "Switzerland is a global leader in recycling, with high rates of waste separation and recycling.",
        de: "Die Schweiz ist ein weltweit führendes Land im Recycling, mit hohen Raten bei der Abfalltrennung und dem Recycling.",
      },
    },
    {
      date: "2024-10-14",
      type: "general",
      title: {
        en: "🎓 Education System",
        de: "🎓 Bildungssystem",
      },
      description: {
        en: "Switzerland has a highly regarded education system, with strong vocational training programs.",
        de: "Die Schweiz hat ein hoch angesehenes Bildungssystem mit starken Berufsausbildungsprogrammen.",
      },
    },
    {
      date: "2024-10-15",
      type: "general",
      title: {
        en: "🗳️ Direct Democracy",
        de: "🗳️ Direkte Demokratie",
      },
      description: {
        en: "Switzerland practices direct democracy, allowing citizens to vote on various issues through referendums.",
        de: "Die Schweiz praktiziert direkte Demokratie, die es den Bürgern ermöglicht, über verschiedene Themen in Referenden abzustimmen.",
      },
    },
    {
      date: "2024-10-16",
      type: "general",
      title: {
        en: "🍽️ Swiss Cuisine",
        de: "🍽️ Schweizer Küche",
      },
      description: {
        en: "Swiss cuisine includes dishes like fondue, raclette, and rösti, reflecting its diverse cultural influences.",
        de: "Die Schweizer Küche umfasst Gerichte wie Fondue, Raclette und Rösti, die ihre vielfältigen kulturellen Einflüsse widerspiegeln.",
      },
    },
    {
      date: "2024-10-17",
      type: "general",
      title: {
        en: "🏥 Healthcare System",
        de: "🏥 Gesundheitssystem",
      },
      description: {
        en: "Switzerland has a high-quality healthcare system, ranked among the best in the world.",
        de: "Die Schweiz hat ein hochwertiges Gesundheitssystem, das zu den besten der Welt gehört.",
      },
    },
    {
      date: "2024-10-18",
      type: "general",
      title: {
        en: "💡 Swiss Innovation",
        de: "💡 Schweizer Innovation",
      },
      description: {
        en: "Switzerland is a hub for innovation and research, with numerous patents and leading research institutions.",
        de: "Die Schweiz ist ein Zentrum für Innovation und Forschung, mit zahlreichen Patenten und führenden Forschungseinrichtungen.",
      },
    },
    {
      date: "2024-10-19",
      type: "general",
      title: {
        en: "🏢 Global Organizations",
        de: "🏢 Globale Organisationen",
      },
      description: {
        en: "Switzerland hosts many international organizations, including the Red Cross and various United Nations agencies.",
        de: "Die Schweiz beherbergt viele internationale Organisationen, darunter das Rote Kreuz und verschiedene Agenturen der Vereinten Nationen.",
      },
    },
    {
      date: "2024-10-20",
      type: "general",
      title: {
        en: "💰 Swiss Banks",
        de: "💰 Schweizer Banken",
      },
      description: {
        en: "Switzerland's banking sector is known for stability and expertise in wealth management.",
        de: "Der Bankensektor der Schweiz ist bekannt für Stabilität und Fachkenntnisse im Wealth Management.",
      },
    },
    {
      date: "2024-10-21",
      type: "general",
      title: {
        en: "🔋 Renewable Energy",
        de: "🔋 Erneuerbare Energien",
      },
      description: {
        en: "Switzerland invests heavily in renewable energy sources, particularly hydroelectric power.",
        de: "Die Schweiz investiert stark in erneuerbare Energiequellen, insbesondere in Wasserkraft.",
      },
    },
    {
      date: "2024-10-22",
      type: "general",
      title: {
        en: "🚄 Swiss Railways",
        de: "🚄 Schweizer Eisenbahnen",
      },
      description: {
        en: "The Swiss Federal Railways (SBB) is renowned for its punctuality and efficiency.",
        de: "Die Schweizerische Bundesbahnen (SBB) sind bekannt für ihre Pünktlichkeit und Effizienz.",
      },
    },
    {
      date: "2024-10-23",
      type: "general",
      title: {
        en: "🏆 UNESCO World Heritage Sites",
        de: "🏆 UNESCO-Weltkulturerbestätten",
      },
      description: {
        en: "Switzerland is home to 12 UNESCO World Heritage Sites, including the Old City of Bern and the Jungfrau-Aletsch area.",
        de: "Die Schweiz beherbergt 12 UNESCO-Weltkulturerbestätten, darunter die Altstadt von Bern und das Jungfrau-Aletsch-Gebiet.",
      },
    },
    {
      date: "2024-10-24",
      type: "general",
      title: {
        en: "⌚ Swiss Watches",
        de: "⌚ Schweizer Uhren",
      },
      description: {
        en: "Switzerland produces over 50% of the world's high-end watches.",
        de: "Die Schweiz produziert über 50 % der hochwertigen Uhren der Welt.",
      },
    },
    {
      date: "2024-10-25",
      type: "general",
      title: {
        en: "🍲 Swiss Cuisine",
        de: "🍲 Schweizer Küche",
      },
      description: {
        en: "Switzerland's diverse regions contribute to a rich variety of traditional dishes.",
        de: "Die unterschiedlichen Regionen der Schweiz tragen zu einer reichen Vielfalt traditioneller Gerichte bei.",
      },
    },
    {
      date: "2024-10-26",
      type: "general",
      title: {
        en: "🍫 Chocolate Consumption",
        de: "🍫 Schokoladenkonsum",
      },
      description: {
        en: "Switzerland has one of the highest per capita chocolate consumption rates in the world.",
        de: "Die Schweiz hat eine der höchsten Schokoladenverbrauchsraten pro Kopf der Welt.",
      },
    },
    {
      date: "2024-10-27",
      type: "general",
      title: {
        en: "🏛️ Swiss Federal Council",
        de: "🏛️ Schweizer Bundesrat",
      },
      description: {
        en: "Switzerland is governed by a Federal Council, a seven-member executive body elected by the parliament.",
        de: "Die Schweiz wird von einem Bundesrat regiert, einem sieben Mitglieder zählenden Exekutivorgan, das vom Parlament gewählt wird.",
      },
    },
    {
      date: "2024-10-28",
      type: "general",
      title: {
        en: "🎉 Swiss Festivals",
        de: "🎉 Schweizer Feste",
      },
      description: {
        en: "Switzerland hosts numerous festivals, including the Geneva International Motor Show and Locarno Film Festival.",
        de: "Die Schweiz veranstaltet zahlreiche Feste, darunter die Genfer Automobilmesse und das Locarno Filmfestival.",
      },
    },
    {
      date: "2024-10-29",
      type: "general",
      title: {
        en: "🏞️ Swiss National Park",
        de: "🏞️ Nationalpark Schweiz",
      },
      description: {
        en: "Switzerland's only national park, established in 1914, is located in the Engadine Valley.",
        de: "Der einzige Nationalpark der Schweiz, 1914 gegründet, befindet sich im Engadin.",
      },
    },
    {
      date: "2024-10-30",
      type: "general",
      title: {
        en: "🎓 Swiss Universities",
        de: "🎓 Schweizer Universitäten",
      },
      description: {
        en: "Switzerland boasts top-ranked universities like ETH Zurich and EPFL Lausanne.",
        de: "Die Schweiz hat hochrangige Universitäten wie die ETH Zürich und die EPFL Lausanne.",
      },
    },
    {
      date: "2024-10-31",
      type: "specific",
      title: {
        en: "🎃 Halloween",
        de: "🎃 Halloween",
      },
      description: {
        en: "Halloween is celebrated in Switzerland, though it's not as widespread as in some other countries.",
        de: "Halloween wird in der Schweiz gefeiert, obwohl es nicht so verbreitet ist wie in einigen anderen Ländern.",
      },
    },
  ];
  