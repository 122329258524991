// src/components/Company Dashboard components/Profile components/CompanyDetails.tsx
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../styles/Company Dashboard styles/Profile styles/CompanyProfile.module.css";

const CompanyDetails: React.FC<any> = ({
  company,
  handleChange,
  handleSave,
  handleCancel,
}) => {
  const { t } = useTranslation("global");
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const startEditing = () => {
    setIsEditing(true);
  };

  const cancelEditing = () => {
    // Aquí puedes resetear las notificaciones si es necesario
    setIsEditing(false);
  };

  const saveEditing = () => {
    // Aquí puedes manejar el guardado de las notificaciones
    setIsEditing(false);
  };
  return (
    <div className={styles.companyProfileDetails}>
      <h2 className={styles.companyProfileSectionTitle}>
        {t("companyProfile.details.title")}
      </h2>
      <div className={styles.detailsGrid}>
        {/* Nombre de la empresa */}
        <div className={styles.detailItem}>
          <span
            className={styles.detailLabel}
            data-tooltip={t("companyProfile.details.nameTooltip")}
          >
            {t("companyProfile.details.name")}:
          </span>
          {isEditing ? (
            <input
              type="text"
              name="name"
              value={company.name || ""}
              onChange={handleChange}
              className={styles.detailInput}
              placeholder={t("companyProfile.details.namePlaceholder")}
            />
          ) : (
            <span className={styles.detailValue}>{company.name || "N/A"}</span>
          )}
        </div>

        {/* Email */}
        <div className={styles.detailItem}>
          <span
            className={styles.detailLabel}
            data-tooltip={t("companyProfile.details.emailTooltip")}
          >
            {t("companyProfile.details.email")}:
          </span>
          <a href={`mailto:${company.email}`} className={styles.companyProfileLink}>
            {company.email || "N/A"}
          </a>
        </div>

        {/* Logo */}
        <div className={styles.detailItem}>
          <span
            className={styles.detailLabel}
            data-tooltip={t("companyProfile.details.logoTooltip")}
          >
            {t("companyProfile.details.logo")}:
          </span>
          <div
            className={
              isEditing
                ? styles.companyProfileLogoUpdate
                : styles.companyProfileLogoPlaceholder
            }
          >
            {isEditing ? (
              <input
                type="file"
                name="avatar"
                accept="image/*"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files && e.target.files[0]) {
                    const file = e.target.files[0];
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      handleChange(e as any);
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                className={styles.detailInput}
              />
            ) : company.avatar ? (
              <img
                src={
                  company.avatar.startsWith("http")
                    ? company.avatar
                    : `https://api.relotu.com${company.avatar}`
                }
                alt={`${company.name} Logo`}
                className={styles.companyProfileLogoImage}
              />
            ) : (
              "No Logo"
            )}
          </div>
        </div>

        {/* Fecha de creación */}
        <div className={styles.detailItem}>
          <span
            className={styles.detailLabel}
            data-tooltip={t("companyProfile.details.createdAtTooltip")}
          >
            {t("companyProfile.details.createdAt")}:
          </span>
          <span className={styles.detailValue}>
            {new Date(company.createdAt).toLocaleDateString()}
          </span>
        </div>

        {/* Última actualización */}
        <div className={styles.detailItem}>
          <span
            className={styles.detailLabel}
            data-tooltip={t("companyProfile.details.updatedAtTooltip")}
          >
            {t("companyProfile.details.updatedAt")}:
          </span>
          <span className={styles.detailValue}>
            {new Date(company.updatedAt).toLocaleDateString()}
          </span>
        </div>
      </div>

      {/* Botones de Editar / Guardar / Cancelar */}
      <div className={styles.editButtonsContainer}>
        {isEditing ? (
          <>
            <button
              className={styles.companyProfileSaveButton}
              onClick={saveEditing}
              type="button"
            >
              {t("companyProfile.details.save")}
            </button>
            <button
              className={styles.companyProfileCancelButton}
              onClick={cancelEditing}
              type="button"
            >
              {t("companyProfile.details.cancel")}
            </button>
          </>
        ) : (
          <button
            className={styles.companyProfileEditButton}
            onClick={startEditing}
            type="button"
          >
            {t("companyProfile.details.edit")}
          </button>
        )}
      </div>
    </div>
  );
};

export default CompanyDetails;
