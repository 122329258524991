// src/components/RelocationSupport/RelocationSupport.tsx

import React from "react";
import styles from "../../../styles/Company Dashboard styles/Companies Services styles/RelocationSupportTab.module.css";
import { FaTasks, FaInfoCircle, FaHandsHelping, FaChartLine, FaCogs, FaUserShield } from "react-icons/fa";
import { useTranslation } from "react-i18next";

interface RelocationToolServices {
  id: number;
  icon: JSX.Element;
  title: string;
  description: string;
}

const RelocationSupport: React.FC = () => {
  const { t } = useTranslation("global");

  const servicesData: RelocationToolServices[] = [
    {
      id: 1,
      icon: <FaTasks className={styles.relocationServiceIcon} />,
      title: t("relocationSupport.services.taskCoverage.title"),
      description: t("relocationSupport.services.taskCoverage.description"),
    },
    {
      id: 2,
      icon: <FaInfoCircle className={styles.relocationServiceIcon} />,
      title: t("relocationSupport.services.information.title"),
      description: t("relocationSupport.services.information.description"),
    },
    /*{
      id: 3,
      icon: <FaHandsHelping className={styles.relocationServiceIcon} />,
      title: t("relocationSupport.services.expertiseSupport.title"),
      description: t("relocationSupport.services.expertiseSupport.description"),
    },*/
    {
      id: 4,
      icon: <FaChartLine className={styles.relocationServiceIcon} />,
      title: t("relocationSupport.services.insights.title"),
      description: t("relocationSupport.services.insights.description"),
    },
    {
      id: 5,
      icon: <FaUserShield className={styles.relocationServiceIcon} />,
      title: t("relocationSupport.services.personalizedServices.title"),
      description: t("relocationSupport.services.personalizedServices.description"),
    },
    /*{
      id: 6,
      icon: <FaCogs className={styles.relocationServiceIcon} />,
      title: t("relocationSupport.services.configurations.title"),
      description: t("relocationSupport.services.configurations.description"),
    },*/
  ];

  return (
    <div className={styles.relocationServiceContainer}>
      <h2 className={styles.relocationServiceHeading}>{t("relocationSupport.heading")}</h2>
      <p className={styles.relocationServiceDescription}>{t("relocationSupport.description")}</p>
      <div className={styles.relocationServiceServicesGrid}>
        {servicesData.map((service) => (
          <div key={service.id} className={styles.relocationServiceServiceCard}>
            <div className={styles.relocationServiceIconContainer}>
              {service.icon}
            </div>
            <h3 className={styles.relocationServiceTitle}>{service.title}</h3>
            <p className={styles.relocationServiceCardDescription}>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RelocationSupport;
