// src/contexts/AuthContext.tsx

import React, { createContext, useState, useEffect, ReactNode } from 'react';

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | null;
  userType: 'company' | 'individual' | null;
  loading: boolean;
  login: (token: string) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  token: null,
  userType: null,
  loading: true,
  login: () => {},
  logout: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [userType, setUserType] = useState<'company' | 'individual' | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedUserType = localStorage.getItem('loggedAs') as 'company' | 'individual' | null;

    if (storedToken && storedUserType) {
      setToken(storedToken);
      setUserType(storedUserType);
      setIsAuthenticated(true);
    } else {
      // Si no hay token o userType, asegúrate de que el estado refleja que no está autenticado
      setIsAuthenticated(false);
      setUserType(null);
    }
    setLoading(false);
  }, []);

  const login = (newToken: string) => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
    setIsAuthenticated(true);
    // No modificamos el almacenamiento de 'loggedAs' aquí, ya que prefieres mantenerlo como está
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('loggedAs');
    setToken(null);
    setUserType(null);
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, userType, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
