// src/components/Authentication components/PublicRoute.tsx
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

interface PublicRouteProps {
  children: JSX.Element;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { isAuthenticated, loading, userType } = useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (isAuthenticated) {
    // Determinar la ruta del dashboard según el userType
    let dashboardPath = "/";
    if (userType === "company") {
      dashboardPath = "/dashboard-companies";
    } else if (userType === "individual") {
      dashboardPath = "/dashboard-individuals";
    }
    return <Navigate to={dashboardPath} replace />;
  }

  return children;
};

export default PublicRoute;
