// src/components/VideoLibraryIndividuals.tsx

import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { videos, Video } from "./videosData";
import styles from "../../styles/VideoLibrary styles/VideoLibrary.module.css";

const LeftArrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M15 18L9 12L15 6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const RightArrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M9 18L15 12L9 6"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const VideoLibraryIndividuals: React.FC = () => {
  const { t, i18n } = useTranslation("global");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [videosPerView, setVideosPerView] = useState(3);
  const [selectedTopic, setSelectedTopic] = useState<string>("All");
  const carouselRef = useRef<HTMLDivElement>(null);
  const isTransitioning = useRef(false);
  const language = localStorage.getItem("language") || i18n.language;

  const filteredVideos =
    selectedTopic === "All"
      ? videos
      : videos.filter((video) => video.topic === selectedTopic);

  const actualVideosPerView = Math.min(videosPerView, filteredVideos.length);

  const extendedVideos =
    filteredVideos.length > actualVideosPerView
      ? [
          ...filteredVideos.slice(-actualVideosPerView),
          ...filteredVideos,
          ...filteredVideos.slice(0, actualVideosPerView),
        ]
      : [...filteredVideos];

  const slideWidthPercentage = 100 / extendedVideos.length;

  useEffect(() => {
    if (filteredVideos.length > actualVideosPerView) {
      const initialIndex = actualVideosPerView;
      setCurrentIndex(initialIndex);
      if (carouselRef.current) {
        carouselRef.current.style.transition = "none";
        carouselRef.current.style.transform = `translateX(-${
          slideWidthPercentage * initialIndex
        }%)`;
      }
    } else {
      setCurrentIndex(0);
      if (carouselRef.current) {
        carouselRef.current.style.transition = "none";
        carouselRef.current.style.transform = `translateX(0%)`;
      }
    }
  }, [selectedTopic, actualVideosPerView]);

  useEffect(() => {
    const updateVideosPerView = () => {
      const width = window.innerWidth;
      if (width < 600) {
        setVideosPerView(1);
      } else if (width >= 600 && width < 900) {
        setVideosPerView(2);
      } else {
        setVideosPerView(3);
      }
    };

    updateVideosPerView();
    window.addEventListener("resize", updateVideosPerView);
    return () => window.removeEventListener("resize", updateVideosPerView);
  }, []);

  const handleTransitionEnd = () => {
    if (!isTransitioning.current) return;

    if (filteredVideos.length > actualVideosPerView) {
      if (currentIndex >= filteredVideos.length + actualVideosPerView) {
        const resetIndex = actualVideosPerView;
        setCurrentIndex(resetIndex);
        if (carouselRef.current) {
          carouselRef.current.style.transition = "none";
          carouselRef.current.style.transform = `translateX(-${
            slideWidthPercentage * resetIndex
          }%)`;
        }
      } else if (currentIndex < actualVideosPerView) {
        const resetIndex = filteredVideos.length + actualVideosPerView - 1;
        setCurrentIndex(resetIndex);
        if (carouselRef.current) {
          carouselRef.current.style.transition = "none";
          carouselRef.current.style.transform = `translateX(-${
            slideWidthPercentage * resetIndex
          }%)`;
        }
      }
    }
    isTransitioning.current = false;
  };

  const handlePrev = () => {
    if (isTransitioning.current || filteredVideos.length <= 1) return;
    isTransitioning.current = true;
    const newIndex = currentIndex - 1;
    setCurrentIndex(newIndex);
    if (carouselRef.current) {
      carouselRef.current.style.transition = "transform 0.5s ease-in-out";
      carouselRef.current.style.transform = `translateX(-${
        slideWidthPercentage * newIndex
      }%)`;
    }
  };

  const handleNext = () => {
    if (isTransitioning.current || filteredVideos.length <= 1) return;
    isTransitioning.current = true;
    const newIndex = currentIndex + 1;
    setCurrentIndex(newIndex);
    if (carouselRef.current) {
      carouselRef.current.style.transition = "transform 0.5s ease-in-out";
      carouselRef.current.style.transform = `translateX(-${
        slideWidthPercentage * newIndex
      }%)`;
    }
  };

  const getCurrentSlideIndex = () => {
    if (filteredVideos.length > actualVideosPerView) {
      return (
        (currentIndex - actualVideosPerView + filteredVideos.length) %
        filteredVideos.length
      );
    } else {
      return currentIndex;
    }
  };

  return (
    <div className={styles.videoLibraryContainer}>
      <h2>{t("videoLibrary.header")}</h2>
      <h1>{t("videoLibrary.subHeader")}</h1>
      <p>{t("videoLibrary.intro")}</p>

      <div className={styles.filterContainer}>
        {[
          "All",
          "General Relocation",
          "Apartment Search",
          "Insurance",
          "Diploma Recognition",
          "Public Transport",
          "Pets",
          "Commuting",
          "Taxes",
          "Working Permits",
        ].map((topic) => (
          <button
            key={topic}
            className={`${styles.filterButton} ${
              selectedTopic === topic ? styles.activeFilter : ""
            }`}
            onClick={() => {
              setSelectedTopic(topic);
            }}
          >
            {t(`videoLibrary.filters.${topic.replace(/ /g, "").toLowerCase()}`)}
          </button>
        ))}
      </div>

      <div className={styles.carouselContainer}>
        <div className={styles.carousel}>
          <div className={styles.carouselWrapper}>
            <div
              className={styles.carouselTrack}
              ref={carouselRef}
              onTransitionEnd={handleTransitionEnd}
              style={{
                transform: `translateX(-${
                  slideWidthPercentage * currentIndex
                }%)`,
                width: `${
                  (100 * extendedVideos.length) / actualVideosPerView
                }%`,
              }}
            >
              {extendedVideos.map((video, index) => (
                <div
                  key={`${video.id}-${index}`}
                  className={`${styles.videoCard}`}
                  style={{ width: `${100 / extendedVideos.length}%` }}
                >
                  <iframe
                    src={language === "de" ? video.url.de : video.url.en}
                    title={language === "de" ? video.title.de : video.title.en}
                    frameBorder="0"
                    allowFullScreen
                    className={styles.videoIframe}
                    loading="lazy"
                  ></iframe>
                  <h3 className={styles.videoTitle}>
                    {language === "de" ? video.title.de : video.title.en}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </div>

        {filteredVideos.length > actualVideosPerView && (
          <div className={styles.navButtonsContainer}>
            <button
              onClick={handlePrev}
              className={`${styles.navButton} ${styles.navButtonPrev}`}
              aria-label={t("videoLibrary.previousVideos")}
            >
              <LeftArrow />
            </button>
            <button
              onClick={handleNext}
              className={`${styles.navButton} ${styles.navButtonNext}`}
              aria-label={t("videoLibrary.nextVideos")}
            >
              <RightArrow />
            </button>
          </div>
        )}

        <div className={styles.carouselIndicators}>
          {filteredVideos.map((_, idx) => (
            <div
              key={idx}
              className={`${styles.carouselIndicator} ${
                getCurrentSlideIndex() === idx ? styles.activeIndicator : ""
              }`}
            ></div>
          ))}
        </div>

        <div className={styles.carouselCounter}>
          {`${getCurrentSlideIndex() + 1} / ${filteredVideos.length}`}
        </div>
      </div>
    </div>
  );
};

export default VideoLibraryIndividuals;
