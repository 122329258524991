import React from "react";
import styles from "../../styles/Landing Page styles/DemoWeb.module.css";
import { useTranslation } from "react-i18next";

const DemoWeb: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  const videoLink =
  i18n.language === "de" ? "D_LWtuiXiNE?si=fGoLiEi8Bn-2JG4I" : "D_LWtuiXiNE?si=fGoLiEi8Bn-2JG4I";

  return (
    <div className={styles.demoWebContainer}>
      <h1>{t("companies-landing-page.demo-web-title-blue")}</h1>
      <p>{t("companies-landing-page.demo-web-paragraph")}</p>
      <div className={styles.videoContainer}>
        <iframe
          src={`https://www.youtube.com/embed/${videoLink}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default DemoWeb;
