// src/components/CompanyDashboard/Home components/HomeCompanyDashboard.tsx

import React, { useState, useEffect } from "react";
import styles from "../../../styles/Company Dashboard styles/Home styles/HomeCompanyDashboard.module.css";
import { useTranslation } from "react-i18next";

interface Individual {
  _id: string;
  name: string;
  email: string;
  moveType: string;
  active: boolean;
  chatbotMessagesCounter: number;
  // Otros campos según tu backend
}

interface UserTasks {
  _id: string;
  title: string;
  isCompleted: string;
}
interface HomeCompanyDashboardProps {
  setActiveItem: (tab: string) => void;
}

const HomeCompanyDashboard: React.FC<HomeCompanyDashboardProps> = ({
  setActiveItem,
}) => {
  const { t } = useTranslation("global");

  const [activeUsers, setActiveUsers] = useState<number>(0);
  const [totalQuestionsToday, setTotalQuestionsToday] = useState<number>(0);
  const [totalTasks, setTotalTasks] = useState<number>(0);
  const [completedTasks, setCompletedTasks] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  // Función para obtener el token de autenticación
  const getAuthToken = (): string | null => {
    return localStorage.getItem("token");
  };

  const fetchUserTasks = async (token: string) => {
    try {
      const response = await fetch(
        "https://api.relotu.com/companies/user-tasks",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token,
          },
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error(t("homeDashboard.errors.unauthorized"));
        }
        const errorData = await response.json();
        throw new Error(
          errorData.message || t("homeDashboard.errors.fetchError")
        );
      }

      const tasks: UserTasks[] = await response.json();
      console.log("Datos recibidos de user-tasks:", tasks); // Log para depuración

      const total = tasks.length;
      setTotalTasks(total);
      console.log("Total de tareas:", total);

      // Contar tareas completadas
      const completed = tasks.filter((task) => task.isCompleted).length;
      setCompletedTasks(completed);
      console.log("Tareas completadas:", completed);
    } catch (err: any) {
      setError(err.message || t("homeDashboard.errors.unexpected"));
    }
  };

  useEffect(() => {
    const fetchActiveUsersAndTasks = async () => {
      const token = getAuthToken();

      if (!token) {
        setError(t("homeDashboard.errors.noAuthToken"));
        return;
      }

      setLoading(true);
      setError("");

      try {
        // Obtener individuos
        const response = await fetch(
          "https://api.relotu.com/companies/individuals",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-relotu-access": token,
            },
          }
        );

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error(t("homeDashboard.errors.unauthorized"));
          }
          const errorData = await response.json();
          throw new Error(
            errorData.message || t("homeDashboard.errors.fetchError")
          );
        }

        const data: Individual[] = await response.json();

        // Contar los usuarios activos
        const activeCount = data.filter(
          (individual) => individual.active
        ).length;
        setActiveUsers(activeCount);
        console.log("Usuarios activos:", activeCount);

        // Calcular el total de preguntas realizadas hoy
        const totalQuestionsDaily = data.reduce((sum, user) => {
          const counter = Number(user.chatbotMessagesCounter);
          return sum + (isNaN(counter) ? 0 : counter);
        }, 0);

        setTotalQuestionsToday(totalQuestionsDaily);
        console.log("Total de preguntas hoy calculado:", totalQuestionsDaily);

        // Obtener y contar las tareas
        await fetchUserTasks(token);
      } catch (err: any) {
        setError(err.message || t("homeDashboard.errors.unexpected"));
      } finally {
        setLoading(false);
      }
    };

    fetchActiveUsersAndTasks();
  }, [t]);

  return (
    <div className={styles.homeContainer}>
      {/* Actividad Reciente */}
      <section className={styles.recentActivity}>
        <h2 className={styles.sectionTitle}>
          {t("homeDashboard.recentActivity.title")}
        </h2>
        <ul className={styles.activityList}>
          <li className={styles.activityItem}>
            <span className={styles.activityIcon}>📄</span>
            {t("homeDashboard.recentActivity.activity1")}
          </li>
          <li className={styles.activityItem}>
            <span className={styles.activityIcon}>🔔</span>
            {t("homeDashboard.recentActivity.activity2")}
          </li>
          {/* Añade más actividades aquí */}
        </ul>
      </section>

      {/* KPIs Clave */}
      <section className={styles.keyMetrics}>
        <h2 className={styles.sectionTitle}>
          {t("homeDashboard.keyMetrics.title")}
        </h2>
        <div className={styles.metricsContainer}>
          <div className={styles.metricCard}>
            {loading ? (
              <span className={styles.metricValue}>
                {t("homeDashboard.keyMetrics.loading")}
              </span>
            ) : error ? (
              <span className={styles.metricValue}>
                {t("homeDashboard.keyMetrics.error")}
              </span>
            ) : (
              <span className={styles.metricValue}>{activeUsers}</span>
            )}
            <span className={styles.metricLabel}>
              {t("homeDashboard.keyMetrics.activeUsers")}
            </span>
          </div>
          <div className={styles.metricCard}>
            {loading ? (
              <span className={styles.metricValue}>
                {t("homeDashboard.keyMetrics.loading")}
              </span>
            ) : error ? (
              <span className={styles.metricValue}>
                {t("homeDashboard.keyMetrics.error")}
              </span>
            ) : (
              <span className={styles.metricValue}>{totalTasks}</span>
            )}
            <span className={styles.metricLabel}>
              {t("homeDashboard.keyMetrics.numberOfTasks")}
            </span>
          </div>
          <div className={styles.metricCard}>
            <span className={styles.metricValue}>10</span>
            <span className={styles.metricLabel}>
              {t("homeDashboard.keyMetrics.pendingRequests")}
            </span>
          </div>
          {/* Nueva tarjeta para el total de preguntas hoy */}
          <div className={styles.metricCard}>
            {loading ? (
              <span className={styles.metricValue}>
                {t("homeDashboard.keyMetrics.loading")}
              </span>
            ) : error ? (
              <span className={styles.metricValue}>
                {t("homeDashboard.keyMetrics.error")}
              </span>
            ) : (
              <span className={styles.metricValue}>{totalQuestionsToday}</span>
            )}
            <span className={styles.metricLabel}>
              {t("homeDashboard.keyMetrics.totalQuestionsToday")}
            </span>
          </div>
        </div>
      </section>

      {/* Notificaciones Importantes */}
      <section className={styles.importantNotifications}>
        <h2 className={styles.sectionTitle}>
          {t("homeDashboard.importantNotifications.title")}
        </h2>
        <ul className={styles.notificationList}>
          <li className={styles.notificationItem}>
            <span className={styles.notificationIcon}>⚠️</span>
            {t("homeDashboard.importantNotifications.notification1")}
          </li>
          <li className={styles.notificationItem}>
            <span className={styles.notificationIcon}>🚨</span>
            {t("homeDashboard.importantNotifications.notification2")}
          </li>
          {/* Añade más notificaciones aquí */}
        </ul>
      </section>

      {/* Acceso Rápido */}
      <section className={styles.quickAccess}>
        <h2 className={styles.sectionTitle}>
          {t("homeDashboard.quickAccess.title")}
        </h2>
        <div className={styles.quickActions}>
          <button
            className={styles.quickButton}
            onClick={() => setActiveItem("companyDashboard.provideRelocation")}
          >
            {t("homeDashboard.quickAccess.shareLinks")}
          </button>
          <button
            className={styles.quickButton}
            onClick={() => setActiveItem("companyDashboard.manageUsers")}
          >
            {t("homeDashboard.quickAccess.upgradeUsers")}
          </button>
          <button
            className={styles.quickButton}
            onClick={() => setActiveItem("companyDashboard.services")}
          >
            {t("homeDashboard.quickAccess.knowServices")}
          </button>
          <button
            className={styles.quickButton}
            onClick={() => setActiveItem("companyDashboard.contactPerson")}
          >
            {t("homeDashboard.quickAccess.contactPartner")}
          </button>
        </div>
      </section>

      {/* Noticias y Recursos */}
      <section className={styles.newsResources}>
        <h2 className={styles.sectionTitle}>
          {t("homeDashboard.newsResources.title")}
        </h2>
        <ul className={styles.newsList}>
          <li className={styles.newsItem}>
            {t("homeDashboard.newsResources.news1")}
          </li>
          <li className={styles.newsItem}>
            {t("homeDashboard.newsResources.news2")}
          </li>
          {/* Añade más noticias aquí */}
        </ul>
      </section>
    </div>
  );
};

export default HomeCompanyDashboard;
