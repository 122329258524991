import React, { useState, ChangeEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../styles/NavBar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

interface Language {
  code: string;
  name: string;
}

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    return storedLanguage || "de"; // Default de
  });
  const { i18n } = useTranslation();

  const languages: Language[] = [
    /*{ code: "en-US", name: "🇺🇸 English" },*/
    { code: "en", name: "🇬🇧 English" },
    /*{ code: "de-CH", name: "🇨🇭 Deutsch" },*/
    { code: "de", name: "🇩🇪 Deutsch" },
    /*{ code: "sp", name: "🇪🇸 Español" },
    { code: "fr", name: "🇫🇷 Français" },
    { code: "it", name: "🇮🇹 Italiano" },
    /*{ code: "ja", name: "🇯🇵 日本語" },*/
    /*{ code: "ru", name: "🇷🇺 Русский" },*/
    /*{ code: "nl", name: "🇳🇱 Dutch" },*/
  ];

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  useEffect(() => {
    localStorage.setItem("selectedLanguage", selectedLanguage);
  }, [selectedLanguage]);

  return (
    <div className={styles.languageSelector}>
      <select id="language" value={selectedLanguage} onChange={handleLanguageChange}>
        {languages.map((language) => (
          <option key={language.code} value={language.code}>
            {language.name}
          </option>
        ))}
      </select>
      {/* Icono de flecha usando FontAwesome */}
    </div>
  );
};

export default LanguageSelector;
