// src/components/CompanyDashboard/NavItem/NavItem.tsx

import React from 'react';
import styles from '../../styles/Company Dashboard styles/CompanyDashboard.module.css';
import { useTranslation } from 'react-i18next';

interface NavItemProps {
    name: string;
    icon?: React.ReactNode;
    isActive: boolean;
    onClick: () => void;
    isCollapsed: boolean;
}

const NavItem: React.FC<NavItemProps> = ({ name, icon, isActive, onClick, isCollapsed }) => {
  const { t } = useTranslation("global");

  return (
    <li
      className={`${styles.companyDashboardNavItem} ${
        isActive ? styles.companyDashboardActive : ''
      }`}
      onClick={onClick}
    >
      {icon && <span className={styles.companyDashboardIcon}>{icon}</span>}
      {!isCollapsed && <span className={styles.companyDashboardText}>{t(name)}</span>}
    </li>
  );
};

export default NavItem;
