// LandingPage.tsx
import React from "react";
import LandingSection from "./LandingSection";
import InstructionsContainer from "./IntructionsContainer";
import ServiceOffer from "./ServiceOffer";
import ContactForm from "./ContactForm";
import DemoWeb from "./DemoWeb";
import styles from "../../styles/Landing Page styles/LandingPage.module.css"; // Asegúrate de que la ruta sea correcta
import BeforeAndAfter from "./BeforeAndAfter";
import OpeningCompanies from "./OpeningCompanies";
import CalendlyComponent from "../CalendlyComponent";
import PersonalInteraction from "./PersonalInteraction";
import InstructionTypes from "./InstructionTypes";
import { useTranslation } from "react-i18next";

const LandingPage: React.FC = () => {
  const [t, i18] = useTranslation("global");

  return (
    <div >
     <OpeningCompanies />
      <LandingSection />
      <InstructionTypes />
      <PersonalInteraction />
      <InstructionsContainer />
      <BeforeAndAfter />
      {/*<ServiceOffer />*/}
      <div id="contact-us-section">
        <CalendlyComponent
          title={t("companies-landing-page.calendly-title-black")}
          boldText={t("companies-landing-page.calendly-paragraph-black")}
          normalText={t("companies-landing-page.calendly-paragraph-normal")}
          calendlyLink="https://calendly.com/relotu/45min"
        />
      </div>
      <ContactForm />
    </div>
  );
};

export default LandingPage;
