// IndividualsTextImage.tsx
import React from "react";

import welcomeImage from "../../assets/ch-img4.jpg";
import styles from "../../styles/Individuals styles/IndividualsTextImage.module.css";
import NavigationButtons from "./NavigationButtons";
import { useTranslation } from "react-i18next";

const IndividualsTextImage = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className={styles.textImageContainer}>
      <div className={styles.textSection}>
        <div>
          <h1>{t("individuals.text-image-title")}</h1>
          <p>{t("individuals.text-image-paragraph")}</p>
         <br />
          <p>{t("individuals.text-image-paragraph2")}</p>
          <div className={styles.buttonTextImageSection}>
           
            <a href="/login-individuals">
              <button className={styles.buttonTextImage}>
                {t("navbar.login-button")}
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.imageSection}>
        <img src={welcomeImage} alt="Welcome Image" />
      </div>
    </div>
  );
};

export default IndividualsTextImage;
