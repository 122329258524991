import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "../../styles/Authentication styles/EmailValidation.module.css";
import succesfulValidationImg from "../../assets/emailSuccessfulValidation.png";
import { useTranslation } from "react-i18next";

export default function SuccessfulValidationEmail() {
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  const userType = localStorage.getItem("userType");


  const handleOkClick = () => {
    if (userType === "company") {
      navigate("/login-organization");
    } else {
      navigate("/login-individuals");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <img
          src={succesfulValidationImg}
          alt="Email validated"
          className={styles.validationImage}
        />
        <h2 className={styles.title}>
          {t("authentication.succesful-validation-title")}
        </h2>
        <p className={styles.message}>
          {t("authentication.succesful-validation-message")}
        </p>
        <button className={styles.button} onClick={handleOkClick}>
          {t("authentication.succesful-validation-button")}
        </button>
      </div>
    </div>
  );
}
