import React from "react";
import icon from "../../assets/logo.png";
import styles from "../../styles/Landing Page styles/BeforeAndAfter.module.css"; // Asegúrate de importar tus estilos
import { useTranslation } from "react-i18next";
const BeforeAndAfter: React.FC = () => {
  const [t, i18n] = useTranslation("global");

  return (
    <div className={styles.beforeAndAfter}>
      <div className={styles.allContainer}>
        <h1>
          {t("companies-landing-page.before-after-title-black1")}{" "}
          <span className={styles.highlighted}>
            {" "}
            {t("companies-landing-page.before-after-title-blue1")}{" "}
          </span>
          {t("companies-landing-page.before-after-title-black2")}{" "}
          <span className={styles.highlighted}>
            {t("companies-landing-page.before-after-title-blue2")}
          </span>
        </h1>

        <div className={styles.innerContainer}>
          <div className={styles.leftContainer}>
            {/* Contenido de la izquierda */}
            <h2>{t("companies-landing-page.before-title")}</h2>
            <ul>
            <span>
                &#10006; {t("companies-landing-page.before-paragraph-bold1")}
              </span>{" "}
              {t("companies-landing-page.before-paragraph-normal1")} <br />
              <br />
              <span>
                &#10006; {t("companies-landing-page.before-paragraph-bold2")}
              </span>{" "}
              {t("companies-landing-page.before-paragraph-normal2")} <br />
              <br />
              <span>
                &#10006; {t("companies-landing-page.before-paragraph-bold3")}
              </span>{" "}
              {t("companies-landing-page.before-paragraph-normal3")} <br />
              <br />
              <span>
                &#10006; {t("companies-landing-page.before-paragraph-bold4")}
              </span>{" "}
              {t("companies-landing-page.before-paragraph-normal4")} <br />
              <br />
              <span>
                &#10006; {t("companies-landing-page.before-paragraph-bold5")}
              </span>{" "}
              {t("companies-landing-page.before-paragraph-normal5")} <br />
              <br />
            </ul>
          </div>

          <div className={styles.rightContainer}>
            {/* Contenido de la derecha */}
            <div className={styles.containerHeader}>
              <h2>{t("companies-landing-page.after-title")}</h2>
              <img className={styles.logo} src={icon} alt="Logo" />
            </div>

            <ul>
              <span>
                &#10004; {t("companies-landing-page.after-paragraph-bold1")}
              </span>{" "}
              {t("companies-landing-page.after-paragraph-normal1")} <br />
              <br />
              <span>
                &#10004; {t("companies-landing-page.after-paragraph-bold2")}
              </span>{" "}
              {t("companies-landing-page.after-paragraph-normal2")} <br />
              <br />
              <span>
                &#10004; {t("companies-landing-page.after-paragraph-bold3")}
              </span>{" "}
              {t("companies-landing-page.after-paragraph-normal3")} <br />
              <br />
              <span>
                &#10004; {t("companies-landing-page.after-paragraph-bold4")}
              </span>{" "}
              {t("companies-landing-page.after-paragraph-normal4")} <br />
              <br />
              <span>
                &#10004; {t("companies-landing-page.after-paragraph-bold5")}
              </span>{" "}
              {t("companies-landing-page.after-paragraph-normal5")} <br />
              <br />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeforeAndAfter;
