// src/components/CompanyDashboard/CompanyServicesDashboard.tsx

import React, { useState } from "react";
import styles from "../../../styles/Company Dashboard styles/Companies Services styles/CompanyServicesTabs.module.css";
import ServiceOverviewTab from "./ServiceOverviewTab";
import RelocationToolTab from "./RelocationToolTab";
import CallsTab from "./CallsTab";
import RelocationSupportTab from "./RelocationSupportTab";
import IntegrationTab from "./IntegrationTab";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // Cambiar íconos para el toggle

const CompanyServicesDashboard: React.FC = () => {
  const [activeTab, setActiveTab] = useState("overview");
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Estado para el menú en pantallas pequeñas

  const renderTabContent = () => {
    switch (activeTab) {
      case "overview":
        return <ServiceOverviewTab />;
      case "relocationTool":
        return <RelocationToolTab />;
      case "calls":
        return <CallsTab />;
      case "relocationSupport":
        return <RelocationSupportTab />;
      case "integration":
        return <IntegrationTab />;
      default:
        return <ServiceOverviewTab />;
    }
  };

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className={styles.companyServicesDashboardContainer}>
      <nav className={styles.companyServicesTabsContainer}>
        {/* Botón de toggle para pantallas pequeñas */}
        <button
          className={styles.toggleMenuButton}
          onClick={handleToggleMenu}
          aria-expanded={isMenuOpen}
          aria-label={isMenuOpen ? "Cerrar menú" : "Abrir menú"}
        >
          {isMenuOpen ? <FaChevronUp /> : <FaChevronDown />}
        </button>

        {/* Contenedor de las tabs */}
        <div className={`${styles.tabsWrapper} ${isMenuOpen ? styles.tabsOpen : ""}`}>
          <button
            className={`${styles.companyServicesTabButton} ${
              activeTab === "overview" ? styles.companyServicesActiveTab : ""
            }`}
            onClick={() => {
              setActiveTab("overview");
              setIsMenuOpen(false); // Cerrar el menú al seleccionar una tab
            }}
          >
            Overview
          </button>
          <button
            className={`${styles.companyServicesTabButton} ${
              activeTab === "relocationTool" ? styles.companyServicesActiveTab : ""
            }`}
            onClick={() => {
              setActiveTab("relocationTool");
              setIsMenuOpen(false);
            }}
          >
            Relocation Tool
          </button>
          <button
            className={`${styles.companyServicesTabButton} ${
              activeTab === "relocationSupport" ? styles.companyServicesActiveTab : ""
            }`}
            onClick={() => {
              setActiveTab("relocationSupport");
              setIsMenuOpen(false);
            }}
          >
            Relocation Support
          </button>
          <button
            className={`${styles.companyServicesTabButton} ${
              activeTab === "calls" ? styles.companyServicesActiveTab : ""
            }`}
            onClick={() => {
              setActiveTab("calls");
              setIsMenuOpen(false);
            }}
          >
            Calls
          </button>
          <button
            className={`${styles.companyServicesTabButton} ${
              activeTab === "integration" ? styles.companyServicesActiveTab : ""
            }`}
            onClick={() => {
              setActiveTab("integration");
              setIsMenuOpen(false);
            }}
          >
            Integration
          </button>
        </div>
      </nav>
      <div className={styles.companyServicesTabContent}>{renderTabContent()}</div>
    </div>
  );
};

export default CompanyServicesDashboard;
