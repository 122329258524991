// src/components/ViewIndividuals/ViewIndividuals.tsx

import React, { useState, useEffect } from "react";
import styles from "../../../styles/Company Dashboard styles/Companies Individuals Styles/ViewIndividuals.module.css";
import { TbLayoutSidebarLeftCollapseFilled, TbLayoutSidebarRightCollapseFilled } from "react-icons/tb";
import { useTranslation } from "react-i18next";

interface Individual {
  _id: string;
  name: string;
  email: string;
  moveType: string;
  moveAt: string; // Move date
  active: boolean;
  moveInfo: {
    from: string;
    to: string;
    moveWithPartner: boolean;
    moveWithPets: boolean;
    moveWithKids: boolean;
    moveWithCar: boolean;
    isEuCitizen: boolean;
  };
}

const ViewIndividuals: React.FC = () => {
  const { t } = useTranslation("global"); // Initialize the translation function

  const [individuals, setIndividuals] = useState<Individual[]>([]);
  const [filteredIndividuals, setFilteredIndividuals] = useState<Individual[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedMoveType, setSelectedMoveType] = useState<string>("All");
  const [selectedStatus, setSelectedStatus] = useState<string>("All");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);

  // Function to retrieve the authentication token
  const getAuthToken = (): string | null => {
    return localStorage.getItem("token");
  };

  // Function to toggle the visibility of additional information columns
  const toggleAdditionalInfo = () => {
    setShowAdditionalInfo((prev) => !prev);
  };

  useEffect(() => {
    const fetchIndividuals = async () => {
      const token = getAuthToken();

      if (!token) {
        setError(t("viewIndividuals.errors.noAuthToken"));
        return;
      }

      setLoading(true);
      setError("");

      try {
        // Production URL: https://api.relotu.com/companies/individuals
        // Development URL: http://localhost:8080/companies/individuals
        const response = await fetch("https://api.relotu.com/companies/individuals", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error(t("viewIndividuals.errors.unauthorized"));
          }
          const errorData = await response.json();
          throw new Error(errorData.message || t("viewIndividuals.errors.fetchError"));
        }

        const data: any[] = await response.json();

        // Map the data to flatten the structure
        const mappedData: Individual[] = data.map((individual) => ({
          _id: individual._id,
          name: individual.name,
          email: individual.email,
          moveType: individual.moveInfo.moveType,
          moveAt: individual.moveInfo.moveAt,
          active: individual.active,
          moveInfo: {
            from: individual.moveInfo.from,
            to: individual.moveInfo.to,
            moveWithPartner: individual.moveInfo.moveWithPartner,
            moveWithPets: individual.moveInfo.moveWithPets,
            moveWithKids: individual.moveInfo.moveWithKids,
            moveWithCar: individual.moveInfo.moveWithCar,
            isEuCitizen: individual.moveInfo.isEuCitizen,
          },
        }));

        setIndividuals(mappedData);
        setFilteredIndividuals(mappedData);
      } catch (err: any) {
        setError(err.message || t("viewIndividuals.errors.unexpected"));
      } finally {
        setLoading(false);
      }
    };

    fetchIndividuals();
  }, [t]);

  useEffect(() => {
    const filtered = individuals.filter((individual) => {
      const matchesSearch =
        individual.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        individual.email.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesMoveType = selectedMoveType === "All" || individual.moveType === selectedMoveType;
      const matchesStatus =
        selectedStatus === "All" ||
        (individual.active ? t("viewIndividuals.status.active") : t("viewIndividuals.status.inactive")) === selectedStatus;

      return matchesSearch && matchesMoveType && matchesStatus;
    });

    setFilteredIndividuals(filtered);
  }, [searchTerm, selectedMoveType, selectedStatus, individuals, t]);

  return (
    <div className={styles.viewIndividualsContainer}>
      <h1 className={styles.viewIndividualsHeading}>{t("viewIndividuals.heading")}</h1>

      {/* Search and Filter Section */}
      <div className={styles.viewIndividualsSearchFilterContainer}>
        <div className={styles.viewIndividualsFilters}>
          <input
            type="text"
            placeholder={t("viewIndividuals.searchPlaceholder")}
            className={styles.viewIndividualsSearchInput}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <select
            className={styles.viewIndividualsSelect}
            value={selectedMoveType}
            onChange={(e) => setSelectedMoveType(e.target.value)}
          >
            <option value="All">{t("viewIndividuals.moveTypes.all")}</option>
            <option value="interested">{t("viewIndividuals.moveTypes.interested")}</option>
            <option value="candidate">{t("viewIndividuals.moveTypes.candidate")}</option>
            <option value="mover">{t("viewIndividuals.moveTypes.mover")}</option>
            <option value="weekly-commuter">{t("viewIndividuals.moveTypes.weekly-commuter")}</option>
            <option value="daily-commuter">{t("viewIndividuals.moveTypes.daily-commuter")}</option>
            {/* Add more move types as needed */}
          </select>

          <select
            className={styles.viewIndividualsSelect}
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="All">{t("viewIndividuals.status.all")}</option>
            <option value="Active">{t("viewIndividuals.status.active")}</option>
            <option value="Inactive">{t("viewIndividuals.status.inactive")}</option>
          </select>
        </div>
      </div>

      {/* Display loading and error messages */}
      {loading && <p className={styles.viewIndividualsLoading}>{t("viewIndividuals.loading")}</p>}
      {error && <p className={styles.viewIndividualsError}>{error}</p>}

      {/* Individual List */}
      {!loading && !error && (
        <div className={styles.viewIndividualsTableWrapper}>
          <table className={styles.viewIndividualsIndividualTable}>
            <thead>
              <tr>
                <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.name")}</th>
                <th className={`${styles.viewIndividualsTableHeader} ${styles.viewIndividualsEmailHeader}`}>
                  {t("viewIndividuals.tableHeaders.email")}
                </th>
                <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.moveType")}</th>
                <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.moveAt")}</th>
                <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.status")}</th>
                <th className={styles.viewIndividualsTableHeader}>
                  <div className={styles.viewIndividualsTableHeaderOtherInfo}>
                    {t("viewIndividuals.tableHeaders.otherInfo")}
                    <button
                      className={styles.viewIndividualsExpandButton}
                      onClick={toggleAdditionalInfo}
                      aria-expanded={showAdditionalInfo}
                      aria-controls="additional-columns"
                      title={showAdditionalInfo ? t("viewIndividuals.buttons.collapse") : t("viewIndividuals.buttons.expand")}
                    >
                      {showAdditionalInfo ? <TbLayoutSidebarLeftCollapseFilled /> : <TbLayoutSidebarRightCollapseFilled />}
                    </button>
                  </div>
                </th>
                {/* Additional columns that will be displayed when expanded */}
                {showAdditionalInfo && (
                  <>
                    <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.from")}</th>
                    <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.to")}</th>
                    <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.withPartner")}</th>
                    <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.withPets")}</th>
                    <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.withKids")}</th>
                    <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.withCar")}</th>
                    <th className={styles.viewIndividualsTableHeader}>{t("viewIndividuals.tableHeaders.euCitizen")}</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {filteredIndividuals.length > 0 ? (
                filteredIndividuals.map((individual) => (
                  <tr key={individual._id} className={styles.viewIndividualsTableRow}>
                    <td className={styles.viewIndividualsTableCell}>{individual.name}</td>
                    <td className={`${styles.viewIndividualsTableCell} ${styles.viewIndividualsEmailCell}`}>
                      {individual.email}
                    </td>
                    <td className={styles.viewIndividualsTableCell}>{t(`viewIndividuals.moveTypes.${individual.moveType}`)}</td>
                    <td className={styles.viewIndividualsTableCell}>
                      {new Date(individual.moveAt).toLocaleDateString()}
                    </td>
                    <td className={styles.viewIndividualsTableCell}>
                      <span
                        className={
                          individual.active
                            ? styles.viewIndividualsStatusActive
                            : styles.viewIndividualsStatusInactive
                        }
                      >
                        {individual.active ? t("viewIndividuals.status.active") : t("viewIndividuals.status.inactive")}
                      </span>
                    </td>
                    <td className={styles.viewIndividualsTableCell}>
                      {/* No botón de expansión por fila */}
                    </td>
                    {/* Conditional rendering of additional columns */}
                    {showAdditionalInfo && (
                      <>
                        <td className={styles.viewIndividualsTableCell}>{individual.moveInfo.from}</td>
                        <td className={styles.viewIndividualsTableCell}>{individual.moveInfo.to}</td>
                        <td className={styles.viewIndividualsTableCell}>
                          {individual.moveInfo.moveWithPartner ? t("viewIndividuals.common.yes") : t("viewIndividuals.common.no")}
                        </td>
                        <td className={styles.viewIndividualsTableCell}>
                          {individual.moveInfo.moveWithPets ? t("viewIndividuals.common.yes") : t("viewIndividuals.common.no")}
                        </td>
                        <td className={styles.viewIndividualsTableCell}>
                          {individual.moveInfo.moveWithKids ? t("viewIndividuals.common.yes") : t("viewIndividuals.common.no")}
                        </td>
                        <td className={styles.viewIndividualsTableCell}>
                          {individual.moveInfo.moveWithCar ? t("viewIndividuals.common.yes") : t("viewIndividuals.common.no")}
                        </td>
                        <td className={styles.viewIndividualsTableCell}>
                          {individual.moveInfo.isEuCitizen ? t("viewIndividuals.common.yes") : t("viewIndividuals.common.no")}
                        </td>
                      </>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className={styles.viewIndividualsNoData} colSpan={showAdditionalInfo ? 12 : 6}>
                    {t("viewIndividuals.noData")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ViewIndividuals;
