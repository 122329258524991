// src/components/Calculator components/CostCalculator.tsx

import React, { useState, useEffect, useRef } from "react";
import styles from "../../styles/Calculator styles/CostCalculator.module.css";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieLabelRenderProps,
} from "recharts";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faBus,
  faUtensils,
  faHeartbeat,
  faBeer,
  faCartShopping,
} from "@fortawesome/free-solid-svg-icons";

interface Expenses {
  housing: number;
  transportation: number;
  food: number;
  healthcare: number;
  entertainment: number;
  otherExpenses?: number; // Nueva categoría opcional
}

const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#AA336A",
  "#FF6384",
  "#8A2BE2", // Color para "Other Expenses"
];
const expenseOptions = {
  monthly: {
    housing: { low: 800, medium: 1500, high: 2500 },
    transportation: { low: 100, medium: 300, high: 500 },
    food: { low: 300, medium: 500, high: 800 },
    healthcare: { low: 200, medium: 400, high: 600 },
    entertainment: { low: 250, medium: 500, high: 1000 },
    otherExpenses: { low: 0, medium: 0, high: 0 }, // Inicialmente sin valores
  },
  annual: {
    housing: { low: 800 * 12, medium: 1500 * 12, high: 2500 * 12 },
    transportation: { low: 100 * 12, medium: 300 * 12, high: 500 * 12 },
    food: { low: 300 * 12, medium: 500 * 12, high: 800 * 12 },
    healthcare: { low: 200 * 12, medium: 400 * 12, high: 600 * 12 },
    entertainment: { low: 250 * 12, medium: 500 * 12, high: 1000 * 12 },
    otherExpenses: { low: 0, medium: 0, high: 0 }, // Inicialmente sin valores
  },
};

// Componente de Tooltip Personalizado
const CustomTooltip = ({
  active,
  payload,
}: {
  active?: boolean;
  payload?: any[];
}) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload;
    return (
      <div className={styles.customTooltip}>
        <p className={styles.tooltipName}>{name}</p>
        <p className={styles.tooltipValue}>€{value.toFixed(2)}</p>
      </div>
    );
  }

  return null;
};

const CostOfLivingCalculator: React.FC = () => {
  const { t } = useTranslation("global");

  // Estado para el período: 'monthly' o 'annual'
  const [period, setPeriod] = useState<"monthly" | "annual">("monthly");

  // Estado para el ingreso, que puede ser número o vacío
  const [income, setIncome] = useState<number | "">("");

  // Estado para el tamaño de la familia
  const [familySize, setFamilySize] = useState<number>(1);

  // Estado para los gastos
  const [expenses, setExpenses] = useState<Expenses>({
    housing: expenseOptions.monthly.housing.low * 1,
    transportation: expenseOptions.monthly.transportation.low * 1,
    food: expenseOptions.monthly.food.low * 1,
    healthcare: expenseOptions.monthly.healthcare.low * 1,
    entertainment: expenseOptions.monthly.entertainment.low * 1,
    otherExpenses: 0, // Inicialmente sin valor
  });

  // Estado para el total de gastos y saldo
  const [totalExpenses, setTotalExpenses] = useState<number>(0);
  const [balance, setBalance] = useState<number>(0);
  const [isCalculated, setIsCalculated] = useState<boolean>(false);
  const [disposableIncomePercentage, setDisposableIncomePercentage] =
    useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const prevPeriodRef = useRef<"monthly" | "annual">(period);

  // Función para redondear a dos decimales
  const round = (num: number) => Math.round(num * 100) / 100;

  // Manejar cambios en los inputs
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    const numericValue = Number(value);

    if (name === "income") {
      setIncome(value === "" ? "" : numericValue >= 0 ? numericValue : 0);
    } else if (name === "familySize") {
      setFamilySize(numericValue >= 1 ? numericValue : 1);
    } else {
      setExpenses({
        ...expenses,
        [name]: numericValue >= 0 ? numericValue : 0,
      });
      setIsCalculated(false);
    }
  };

  // Manejar cambios en las opciones de gasto (low/medium/high)
  const handleOptionChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    category: keyof Expenses
  ) => {
    const { value } = e.target;
    let selectedValue = 0;

    if (value === "low") {
      selectedValue = expenseOptions[period][category].low * familySize;
    } else if (value === "medium") {
      selectedValue = expenseOptions[period][category].medium * familySize;
    } else if (value === "high") {
      selectedValue = expenseOptions[period][category].high * familySize;
    } else {
      selectedValue = 0;
    }

    setExpenses({
      ...expenses,
      [category]: selectedValue,
    });
    setIsCalculated(false);
  };

  // Función para manejar el cambio de período
  const handlePeriodChange = (newPeriod: "monthly" | "annual") => {
    if (prevPeriodRef.current === "monthly" && newPeriod === "annual") {
      // Multiplicar gastos por 12
      setExpenses((prevExpenses) => ({
        housing: round(prevExpenses.housing * 12),
        transportation: round(prevExpenses.transportation * 12),
        food: round(prevExpenses.food * 12),
        healthcare: round(prevExpenses.healthcare * 12),
        entertainment: round(prevExpenses.entertainment * 12),
        otherExpenses: prevExpenses.otherExpenses
          ? round(prevExpenses.otherExpenses * 12)
          : undefined,
      }));
    } else if (prevPeriodRef.current === "annual" && newPeriod === "monthly") {
      // Dividir gastos por 12
      setExpenses((prevExpenses) => ({
        housing: round(prevExpenses.housing / 12),
        transportation: round(prevExpenses.transportation / 12),
        food: round(prevExpenses.food / 12),
        healthcare: round(prevExpenses.healthcare / 12),
        entertainment: round(prevExpenses.entertainment / 12),
        otherExpenses: prevExpenses.otherExpenses
          ? round(prevExpenses.otherExpenses / 12)
          : undefined,
      }));
    }
    setPeriod(newPeriod);
    prevPeriodRef.current = newPeriod;
    setIsCalculated(false);
  };

  // Función para renderizar los labels del gráfico de pastel con fuente adaptativa
  const renderLabel = (props: PieLabelRenderProps) => {
    const { name, percent, x, y } = props;
    const safePercent = percent ?? 0;

    return (
      <text
        x={x}
        y={y}
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={
          windowWidth < 600 ? "8" : "10"
        } /* Tamaño de fuente adaptativo */
        fill="#333"
      >
        {`${name}: ${(safePercent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const prevFamilySizeRef = useRef<number>(familySize);

  useEffect(() => {
    const multiplier = familySize / prevFamilySizeRef.current;
    setExpenses((prevExpenses) => ({
      housing: round(prevExpenses.housing * multiplier),
      transportation: round(prevExpenses.transportation * multiplier),
      food: round(prevExpenses.food * multiplier),
      healthcare: round(prevExpenses.healthcare * multiplier),
      entertainment: round(prevExpenses.entertainment * multiplier),
      otherExpenses: prevExpenses.otherExpenses
        ? round(prevExpenses.otherExpenses * multiplier)
        : undefined,
    }));
    prevFamilySizeRef.current = familySize;
  }, [familySize]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const calculateCostOfLiving = () => {
    const total = Object.entries(expenses).reduce((acc, [key, value]) => {
      if (value > 0) {
        return acc + value;
      }
      return acc;
    }, 0);
    setTotalExpenses(total);
    let calculatedBalance = 0;
    if (income === "") {
      calculatedBalance = -total;
    } else {
      calculatedBalance = (income as number) - total;
    }
    setBalance(calculatedBalance);

    // Calcular el porcentaje de ingreso disponible
    const disposablePercentage =
      income !== "" && income > 0
        ? (calculatedBalance / (income as number)) * 100
        : 0;
    setDisposableIncomePercentage(disposablePercentage);

    setIsCalculated(true);
  };

  // Preparar datos para el gráfico de pastel
  const data = Object.entries(expenses)
    .filter(([_, value]) => value > 0)
    .map(([key, value]) => ({
      name:
        key === "otherExpenses"
          ? t("calculator.otherExpensesLabel")
          : key.charAt(0).toUpperCase() + key.slice(1),
      value,
    }));

  return (
    <div className={styles.componentCalculator}>
      <h2>{t("calculator.title")}</h2>
      <div className={styles.calculatorContainer}>
        <form
          className={styles.calculatorForm}
          onSubmit={(e) => {
            e.preventDefault();
            calculateCostOfLiving();
          }}
        >
          <div className={styles.periodSelector}>
            <label>
              <input
                type="radio"
                name="period"
                value="monthly"
                checked={period === "monthly"}
                onChange={() => handlePeriodChange("monthly")}
              />
              {t("calculator.monthly")}
            </label>
            <label>
              <input
                type="radio"
                name="period"
                value="annual"
                checked={period === "annual"}
                onChange={() => handlePeriodChange("annual")}
              />
              {t("calculator.annual")}
            </label>
          </div>

          <div className={styles.topInputs}>
            <div className={styles.inputGroup}>
              <label htmlFor="income">{t("calculator.incomeLabel")}</label>
              <input
                type="number"
                id="income"
                name="income"
                value={income}
                onChange={handleInputChange}
                min="0"
                placeholder={t("calculator.selectOptionPlaceholder")}
              />
            </div>

            <div className={styles.inputGroup}>
              <label htmlFor="familySize">
                {t("calculator.familySizeLabel")}
              </label>
              <input
                type="number"
                id="familySize"
                name="familySize"
                value={familySize}
                onChange={handleInputChange}
                min="1"
                required
                placeholder=""
              />
            </div>
          </div>

          <p className={styles.guideComment}>
            {t("calculator.guideLargeCities")}
          </p>

          <div className={styles.expensesSection}>
            <h3>{t("calculator.expensesSection")}</h3>

            <div className={styles.expenseItem}>
              <FontAwesomeIcon icon={faHome} className={styles.icon} />
              <div className={styles.expenseDetails}>
                <label htmlFor="housing">{t("calculator.housingLabel")}</label>
                <div className={styles.selectInput}>
                  <select onChange={(e) => handleOptionChange(e, "housing")}>
                    <option value="select">
                      {t("calculator.selectOption")}
                    </option>
                    <option value="low">{t("calculator.low")}</option>
                    <option value="medium">{t("calculator.medium")}</option>
                    <option value="high">{t("calculator.high")}</option>
                  </select>
                  <input
                    type="number"
                    id="housing"
                    name="housing"
                    value={expenses.housing}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.selectOptionPlaceholder")}
                  />
                </div>
              </div>
            </div>

            <div className={styles.expenseItem}>
              <FontAwesomeIcon icon={faBus} className={styles.icon} />
              <div className={styles.expenseDetails}>
                <label htmlFor="transportation">
                  {t("calculator.transportationLabel")}
                </label>
                <div className={styles.selectInput}>
                  <select
                    onChange={(e) => handleOptionChange(e, "transportation")}
                  >
                    <option value="select">
                      {t("calculator.selectOption")}
                    </option>
                    <option value="low">{t("calculator.low")}</option>
                    <option value="medium">{t("calculator.medium")}</option>
                    <option value="high">{t("calculator.high")}</option>
                  </select>
                  <input
                    type="number"
                    id="transportation"
                    name="transportation"
                    value={expenses.transportation}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.selectOptionPlaceholder")}
                  />
                </div>
              </div>
            </div>

            <div className={styles.expenseItem}>
              <FontAwesomeIcon icon={faUtensils} className={styles.icon} />
              <div className={styles.expenseDetails}>
                <label htmlFor="food">{t("calculator.foodLabel")}</label>
                <div className={styles.selectInput}>
                  <select onChange={(e) => handleOptionChange(e, "food")}>
                    <option value="select">
                      {t("calculator.selectOption")}
                    </option>
                    <option value="low">{t("calculator.low")}</option>
                    <option value="medium">{t("calculator.medium")}</option>
                    <option value="high">{t("calculator.high")}</option>
                  </select>
                  <input
                    type="number"
                    id="food"
                    name="food"
                    value={expenses.food}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.selectOptionPlaceholder")}
                  />
                </div>
              </div>
            </div>

            <div className={styles.expenseItem}>
              <FontAwesomeIcon icon={faHeartbeat} className={styles.icon} />
              <div className={styles.expenseDetails}>
                <label htmlFor="healthcare">
                  {t("calculator.healthcareLabel")}
                </label>
                <div className={styles.selectInput}>
                  <select onChange={(e) => handleOptionChange(e, "healthcare")}>
                    <option value="select">
                      {t("calculator.selectOption")}
                    </option>
                    <option value="low">{t("calculator.low")}</option>
                    <option value="medium">{t("calculator.medium")}</option>
                    <option value="high">{t("calculator.high")}</option>
                  </select>
                  <input
                    type="number"
                    id="healthcare"
                    name="healthcare"
                    value={expenses.healthcare}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.selectOptionPlaceholder")}
                  />
                </div>
              </div>
            </div>

            <div className={styles.expenseItem}>
              <FontAwesomeIcon icon={faBeer} className={styles.icon} />
              <div className={styles.expenseDetails}>
                <label htmlFor="entertainment">
                  {t("calculator.entertainmentLabel")}
                </label>
                <div className={styles.selectInput}>
                  <select
                    onChange={(e) => handleOptionChange(e, "entertainment")}
                  >
                    <option value="select">
                      {t("calculator.selectOption")}
                    </option>
                    <option value="low">{t("calculator.low")}</option>
                    <option value="medium">{t("calculator.medium")}</option>
                    <option value="high">{t("calculator.high")}</option>
                  </select>
                  <input
                    type="number"
                    id="entertainment"
                    name="entertainment"
                    value={expenses.entertainment}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.selectOptionPlaceholder")}
                  />
                </div>
              </div>
            </div>

            <div className={styles.expenseItem}>
              <FontAwesomeIcon icon={faCartShopping} className={styles.icon} />
              <div className={styles.expenseDetails}>
                <label htmlFor="otherExpenses">
                  {t("calculator.otherExpensesLabel")}
                </label>
                <div className={styles.selectInput}>
                  <input
                    type="number"
                    id="otherExpenses"
                    name="otherExpenses"
                    value={expenses.otherExpenses || ""}
                    onChange={handleInputChange}
                    min="0"
                    placeholder={t("calculator.otherExpensesPlaceholder")}
                  />
                </div>
              </div>
            </div>
          </div>

          <button type="submit" className={styles.calculateButton}>
            {t("calculator.calculateButton")}
          </button>
        </form>

        <div className={styles.results}>
          <h3>{t("calculator.resultsTitle")}</h3>
          {isCalculated ? (
            <>
              <p>
                <strong>{t("calculator.totalCost")}</strong> €
                {totalExpenses.toFixed(2)}
              </p>
              <p>
                <strong>{t("calculator.remainingBalance")}</strong> €
                {balance.toFixed(2)}
              </p>
              {balance < 0 && income !== "" && income > 0 && 
                <p className={styles.warning}>{t("calculator.warning")}</p>
              }
              {balance > 0 && (
                <>
                  <div className={styles.progressBarContainer}>
                    <label>{t("calculator.disposableIncomePercentage")}</label>
                    <div className={styles.progressBar}>
                      <div
                        className={styles.progress}
                        style={{
                          width: `${Math.min(
                            Math.max(disposableIncomePercentage, 0),
                            100
                          )}%`,
                        }}
                      ></div>
                    </div>
                    <p>{disposableIncomePercentage.toFixed(2)}%</p>
                  </div>
                </>
              )}

              {totalExpenses > 0 && (
                <div className={styles.chartContainer}>
                  <h4>{t("calculator.expenseDistribution")}</h4>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderLabel}
                        outerRadius={windowWidth < 600 ? "60%" : "80%"} // Ajuste dinámico del outerRadius
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip content={<CustomTooltip />} />
                      <Legend
                        layout={windowWidth < 600 ? "vertical" : "horizontal"}
                        align={windowWidth < 600 ? "right" : "center"}
                        verticalAlign={windowWidth < 600 ? "middle" : "bottom"}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              )}
            </>
          ) : (
            <p>{t("calculator.prompt")}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CostOfLivingCalculator;
