// src/components/Dashboard components/DashboardCompanies.tsx

import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "../../styles/Dashboard styles/DashboardCompanies.module.css";
import CreateLinks from "./CreateLinks"; // Asegúrate de que la ruta sea correcta
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import WhatIsIncluded from "../Company Dashboard components/Companies Services components/WhatIsIncluded";

interface Service {
  id: number;
  translationKey: string;
  videoUrls: {
    [language: string]: string;
  };
  pageLinks: string;
  disabled?: boolean;
}

const services: Service[] = [
  {
    id: 1,
    translationKey: "servicesCompanies.provideRelocationService",
    videoUrls: {
      en: "https://www.youtube.com/embed/A5FmOGtw6jA?si=mUUdXaXrCY2T5E88",
      de: "https://www.youtube.com/embed/A5FmOGtw6jA?si=mUUdXaXrCY2T5E88",
    },
    pageLinks: "/provide-relocation",
  },
  /*{
    id: 2,
    translationKey: "servicesCompanies.personalizeRelotu",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_PERSONALIZE_RELOTU_EN",
      de: "https://www.youtube.com/embed/VIDEO_ID_PERSONALIZE_RELOTU_ES",
    },
    pageLinks: "/personalize-relotu",
  },*/
  {
    id: 3,
    translationKey: "servicesCompanies.whatIsIncluded",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_PEOPLE_INSIGHTS_EN",
      de: "https://www.youtube.com/embed/VIDEO_ID_PEOPLE_INSIGHTS_ES",
    },
    pageLinks: "/what-is-included",
  },
  {
    id: 4,
    translationKey: "servicesCompanies.peopleInsights",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_PEOPLE_INSIGHTS_EN",
      de: "https://www.youtube.com/embed/VIDEO_ID_PEOPLE_INSIGHTS_ES",
    },
    pageLinks: "/people-insights",
    disabled: true,
  },
  /*{
    id: 4,
    translationKey: "servicesCompanies.yourTasks",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_YOUR_TASKS_EN",
      de: "https://www.youtube.com/embed/VIDEO_ID_YOUR_TASKS_ES",
    },
    pageLinks: "/company-tasks",
  },*/
  {
    id: 5,
    translationKey: "servicesCompanies.videoLibrary",
    videoUrls: {
      en: "https://www.youtube.com/embed/Vx3ZnTzVie0?si=T2t9k75E6rEOq7y1",
      de: "https://www.youtube.com/embed/Vx3ZnTzVie0?si=T2t9k75E6rEOq7y1",
    },
    pageLinks: "/videolibrary",
  },
  {
    id: 6,
    translationKey: "servicesCompanies.contactUs",
    videoUrls: {
      en: "https://www.youtube.com/embed/kWIR4fR_8os?si=huuLh-DSrXelhHEq",
      de: "https://www.youtube.com/embed/kWIR4fR_8os?si=jnCcsd95MSL4GOeR",
    },
    pageLinks: "/contact-us",
  },
  {
    id: 7,
    translationKey: "servicesCompanies.whatIsRelotu",
    videoUrls: {
      en: "https://www.youtube.com/embed/ytF8KdL6UEc?si=KBo9FbvAyH-Zsin0",
      de: "https://www.youtube.com/embed/ytF8KdL6UEc?si=KBo9FbvAyH-Zsin0",
    },
    pageLinks: "/what-is-relotu",
  },
  /*{
    id: 8,
    translationKey: "servicesCompanies.billing",
    videoUrls: {
      en: "https://www.youtube.com/embed/VIDEO_ID_BILLING_EN",
      de: "https://www.youtube.com/embed/VIDEO_ID_BILLING_ES",
    },
    pageLinks: "/billing",
  },*/
];

const DashboardCompanies: React.FC = () => {
  const { t, i18n } = useTranslation("global");
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(
    window.innerWidth <= 768
  );

  const handleServiceClick = (translationKey: string) => {
    setSelectedService((prev) =>
      prev === translationKey ? null : translationKey
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const selectedServiceObj = services.find(
    (service) => service.translationKey === selectedService
  );

  const getVideoUrl = (): string => {
    if (!selectedServiceObj) return "";
    const currentLanguage = i18n.language.startsWith("de") ? "de" : "en";
    return (
      selectedServiceObj.videoUrls[currentLanguage] ||
      selectedServiceObj.videoUrls["en"]
    );
  };

  const getPageLink = (): string => {
    if (!selectedServiceObj) return "/";
    return selectedServiceObj.pageLinks;
  };

  // Componente para Renderizar los Detalles del Servicio
  const ServiceDetails: React.FC = () => {
    if (!selectedServiceObj) {
      return <p>{t("servicesCompanies.selectService")}</p>;
    }

    if (selectedService === "servicesCompanies.whatIsIncluded") {
      return <WhatIsIncluded />;
    }

    return (
      <>
        <h2 className={styles.serviceTitle}>{t(`${selectedService}.name`)}</h2>{" "}
        {/* Añadido className */}
        <p>{t(`${selectedService}.details`)}</p>
        {/* Render CreateLinks antes del video para Provide Relocation Service */}
        {selectedService === "servicesCompanies.provideRelocationService" && (
          <CreateLinks />
        )}
        {selectedService !== "servicesCompanies.whatIsRelotu" &&
        selectedService !== "servicesCompanies.peopleInsights" ? (
          <div className={styles.serviceButton}>
            {selectedService ===
            "servicesCompanies.provideRelocationService" ? null : (
              <Link to={getPageLink()} className={styles.linkButton}>
                {t(`${selectedService}.button`)}
              </Link>
            )}
          </div>
        ) : null}
        <div className={styles.serviceVideo}>
          <iframe
            src={getVideoUrl()}
            title={t(`${selectedService}.name`)}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className={styles.videoIframe}
            loading="lazy"
          ></iframe>
        </div>
      </>
    );
  };

  return (
    <div className={styles.componentServices}>
      <h2>{t("servicesCompanies.header")}</h2>
      <h1>{t("servicesCompanies.subHeader")}</h1>
      {/*<p>{t("servicesCompanies.intro")}</p>*/}
      <div className={styles.containerServices}>
        <div className={styles.servicesList}>
          {services.map((service) => (
            <div key={service.id}>
              <div
                className={`${styles.serviceBox} ${
                  selectedService === service.translationKey
                    ? styles.active
                    : ""
                } ${service.disabled ? styles.disabled : ""}`}
                onClick={() =>
                  !service.disabled &&
                  handleServiceClick(service.translationKey)
                }
                role="button"
                aria-pressed={selectedService === service.translationKey}
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && !service.disabled)
                    handleServiceClick(service.translationKey);
                }}
              >
                {service.disabled ? (
                  <span className={styles.tooltipContainer}>
                    <div className={styles.serviceContent}>
                      <h3>{t(`${service.translationKey}.name`)}</h3>
                      <FontAwesomeIcon
                        icon={faLock}
                        className={styles.lockIcon}
                      />
                    </div>
                    <span className={styles.tooltipText}>
                      {t("navbar.tooltip-disabled-link-people-insights")}
                    </span>
                  </span>
                ) : (
                  <div className={styles.serviceContent}>
                    <h3>{t(`${service.translationKey}.name`)}</h3>
                  </div>
                )}
              </div>
              {/* Renderizar detalles dentro de serviceBox en pantallas pequeñas */}
              {isSmallScreen && selectedService === service.translationKey && (
                <div className={styles.serviceDetails}>
                  <ServiceDetails />
                </div>
              )}
            </div>
          ))}
        </div>
        {/* Renderizar detalles fuera de servicesList en pantallas grandes */}
        {!isSmallScreen && (
          <div className={styles.serviceDetails}>
            {selectedService ? (
              <ServiceDetails />
            ) : (
              <p>{t("servicesCompanies.selectService")}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardCompanies;
