// src/components/UpgradeIndividuals/UpgradeIndividuals.tsx

import React, { useState, useEffect, useRef } from "react";
import styles from "../../../styles/Company Dashboard styles/Companies Individuals Styles/UpgradeIndividuals.module.css";
import { useTranslation } from "react-i18next";

// Interface para los Individuos
interface Individual {
  _id: string;
  name: string;
  lastName: string;
  email: string;
  moveType: string;
  companyId: string; // Asegúrate de que este campo esté correctamente asignado
}

// Interface para el cuerpo de la solicitud POST
interface UpgradeRequestBody {
  companyId: string;
  individualId: string;
  moveInfo: {
    moveType: string;
  };
}

const UpgradeIndividuals: React.FC = () => {
  const { t } = useTranslation("global");
  
  const [individuals, setIndividuals] = useState<Individual[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  // Estado para el Modal
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedIndividual, setSelectedIndividual] = useState<Individual | null>(null);
  const [newMoveType, setNewMoveType] = useState<string>("");

  // Estado para controlar qué dropdown está abierto
  const [openDropdownId, setOpenDropdownId] = useState<string | null>(null);

  // Referencias para cada contenedor de dropdown (botón + contenido)
  const dropdownRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  // Función para obtener el token de autenticación
  const getAuthToken = (): string | null => {
    return localStorage.getItem("token");
  };

  useEffect(() => {
    const fetchIndividuals = async () => {
      const token = getAuthToken();

      if (!token) {
        setError(t('upgradeIndividuals.alerts.authenticationError'));
        return;
      }

      setLoading(true);
      setError("");

      try {
        // URL de producción: https://api.relotu.com/companies/individuals
        // URL de desarrollo: http://localhost:8080/companies/individuals
        const response = await fetch("https://api.relotu.com/companies/individuals", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-relotu-access": token,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            throw new Error(t('upgradeIndividuals.alerts.unauthorized'));
          }
          const errorData = await response.json();
          throw new Error(errorData.message || t('upgradeIndividuals.alerts.fetchError'));
        }

        const data: any[] = await response.json();

        // Mapear los datos incluyendo _id y companyId, ajustando la ruta según la estructura real
        const mappedData: Individual[] = data.map((individual) => {
          

          let extractedCompanyId = "";

          if (typeof individual.company === "string") {
            // Escenario 1: company es una cadena
            extractedCompanyId = individual.company;
          } else if (typeof individual.company === "object" && individual.company !== null) {
            // Escenario 2 y 3: company es un objeto
            extractedCompanyId = individual.company._id || individual.company.companyId || individual.company.id || "";
          }

          return {
            _id: individual._id || "",
            name: individual.name || "",
            lastName: individual.lastName || "",
            email: individual.email || "",
            moveType: individual.moveInfo ? individual.moveInfo.moveType : individual.moveType || "",
            companyId: extractedCompanyId,
          };
        });

        

        setIndividuals(mappedData);
      } catch (err: any) {
        setError(err.message || t('upgradeIndividuals.alerts.fetchError'));
      } finally {
        setLoading(false);
      }
    };

    fetchIndividuals();
  }, [t]);

  // Función para manejar las actualizaciones de moveType
  const handleMoveTypeChange = async () => {
    if (!selectedIndividual || !newMoveType) return;

    if (!selectedIndividual.companyId) {
      setError(t('upgradeIndividuals.alerts.updateError'));
      return;
    }

    const token = getAuthToken();

    if (!token) {
      setError(t('upgradeIndividuals.alerts.authenticationError'));
      return;
    }

    

    // Construir el cuerpo de la solicitud según la estructura requerida
    const requestBody: UpgradeRequestBody = {
      companyId: selectedIndividual.companyId,
      individualId: selectedIndividual._id,
      moveInfo: {
        moveType: newMoveType,
      },
    };

    try {
      const response = await fetch("https://api.relotu.com/companies/upgrade-individuals", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-relotu-access": token,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || t('upgradeIndividuals.alerts.updateError'));
      }

      // Actualizar moveType en el estado
      setIndividuals((prevIndividuals) =>
        prevIndividuals.map((individual) =>
          individual._id === selectedIndividual._id
            ? { ...individual, moveType: newMoveType }
            : individual
        )
      );

      // Limpiar mensajes de error y mostrar alerta de éxito
      setError("");
      alert(t('upgradeIndividuals.alerts.success'));

      // Cerrar el modal después de una actualización exitosa
      setIsModalOpen(false);
      setSelectedIndividual(null);
      setNewMoveType("");
    } catch (err: any) {
      setError(err.message || t('upgradeIndividuals.alerts.error'));
      alert(err.message || t('upgradeIndividuals.alerts.error'));
    }
  };

  // Definir opciones de upgrade basadas en el moveType actual
  const getUpgradeOptions = (currentMoveType: string): { label: string; value: string }[] => {
    switch (currentMoveType) {
      case "interested":
        return [{ label: t('upgradeIndividuals.candidate'), value: "candidate" },
        { label: t('upgradeIndividuals.mover'), value: "mover" },
        { label: t('upgradeIndividuals.weeklyCommuter'), value: "weekly-commuter" },
        { label: t('upgradeIndividuals.dailyCommuter'), value: "daily-commuter" },
      ];
      case "candidate":
        return [
          { label: t('upgradeIndividuals.mover'), value: "mover" },
          { label: t('upgradeIndividuals.weeklyCommuter'), value: "weekly-commuter" },
          { label: t('upgradeIndividuals.dailyCommuter'), value: "daily-commuter" },
        ]; // Opciones actualizadas con guiones para el backend
      default:
        return [];
    }
  };

  // Categorizar individuos
  const interestedIndividuals = individuals.filter((ind) => ind.moveType === "interested");
  const candidateIndividuals = individuals.filter((ind) => ind.moveType === "candidate");
  const moverCommuterIndividuals = individuals.filter(
    (ind) =>
      ind.moveType === "mover" ||
      ind.moveType === "commuter" ||
      ind.moveType === "weekly-commuter" ||
      ind.moveType === "daily-commuter"
  );

  // Función para filtrar individuos basados en searchTerm
  const filterIndividuals = (data: Individual[]): Individual[] => {
    if (!searchTerm) return data;

    const lowerSearchTerm = searchTerm.toLowerCase();

    return data.filter(
      (ind) =>
        (ind.name && ind.name.toLowerCase().includes(lowerSearchTerm)) ||
        (ind.lastName && ind.lastName.toLowerCase().includes(lowerSearchTerm)) ||
        (ind.email && ind.email.toLowerCase().includes(lowerSearchTerm))
    );
  };

  // Función para alternar el dropdown
  const toggleDropdown = (individual: Individual) => {
    setOpenDropdownId((prevId) => (prevId === individual._id ? null : individual._id));
    setSelectedIndividual(null);
    setNewMoveType("");
  };

  // Función para abrir el modal de confirmación
  const openModal = (individual: Individual, upgradeOption: string) => {
    if (upgradeOption === "") {
      // Abrir o cerrar el dropdown
      toggleDropdown(individual);
    } else {
      // Abrir el modal de confirmación
      setSelectedIndividual(individual);
      setNewMoveType(upgradeOption);
      setIsModalOpen(true);
      setOpenDropdownId(null);
    }
  };

  // Función para cerrar el modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedIndividual(null);
    setNewMoveType("");
  };

  // Efecto para cerrar el dropdown al hacer clic fuera
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openDropdownId) {
        const dropdownElement = dropdownRefs.current[openDropdownId];
        if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
          setOpenDropdownId(null);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openDropdownId]);

  return (
    <div className={styles.upgradeIndividualsContainer}>
      <h1 className={styles.upgradeIndividualsHeading}>{t('upgradeIndividuals.heading')}</h1>

      {/* Sección de Búsqueda */}
      <div className={styles.upgradeIndividualsSearchFilterContainer}>
        <input
          type="text"
          placeholder={t('upgradeIndividuals.searchPlaceholder')}
          className={styles.upgradeIndividualsSearchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Mensajes de Carga y Error */}
      {loading && <p className={styles.upgradeIndividualsLoading}>{t('upgradeIndividuals.loading')}</p>}
      {error && <p className={styles.upgradeIndividualsError}>{error}</p>}

      {/* Tablas */}
      {!loading && !error && (
        <div className={styles.upgradeIndividualsTablesContainer}>
          {/* Tabla de Interesados */}
          <div className={styles.upgradeIndividualsTableWrapper}>
            <h2 className={styles.upgradeIndividualsTableTitle}>{t('upgradeIndividuals.interested')}</h2>
            <table className={styles.upgradeIndividualsIndividualTable}>
              <thead>
                <tr>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.firstName')}</th>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.lastName')}</th>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.email')}</th>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.upgradeTo')}</th>
                </tr>
              </thead>
              <tbody>
                {filterIndividuals(interestedIndividuals).length > 0 ? (
                  filterIndividuals(interestedIndividuals).map((individual) => (
                    <tr key={individual._id} className={styles.upgradeIndividualsTableRow}>
                      <td className={styles.upgradeIndividualsTableCell}>{individual.name}</td>
                      <td className={styles.upgradeIndividualsTableCell}>{individual.lastName}</td>
                      <td className={styles.upgradeIndividualsTableCell}>{individual.email}</td>
                      <td className={styles.upgradeIndividualsTableCell}>
                        {getUpgradeOptions(individual.moveType).length > 0 ? (
                          <div
                            className={styles.upgradeIndividualsDropdown}
                            ref={(el) => (dropdownRefs.current[individual._id] = el)}
                          >
                            <button
                              className={styles.upgradeIndividualsDropdownButton}
                              onClick={() => openModal(individual, "")}
                            >
                              {t('upgradeIndividuals.selectUpgrade')}
                            </button>
                            <div
                              className={`${styles.upgradeIndividualsDropdownContent} ${
                                openDropdownId === individual._id ? styles.upgradeIndividualsShow : ""
                              }`}
                            >
                              {getUpgradeOptions(individual.moveType).map((option) => (
                                <button
                                  key={option.value}
                                  className={styles.upgradeIndividualsDropdownContentButton}
                                  onClick={() => openModal(individual, option.value)}
                                >
                                  {option.label}
                                </button>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <span>{t('upgradeIndividuals.noUpgradeOptions')}</span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className={styles.upgradeIndividualsNoData} colSpan={4}>
                      {t('upgradeIndividuals.noInterested')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Tabla de Candidatos */}
          <div className={styles.upgradeIndividualsTableWrapper}>
            <h2 className={styles.upgradeIndividualsTableTitle}>{t('upgradeIndividuals.candidates')}</h2>
            <table className={styles.upgradeIndividualsIndividualTable}>
              <thead>
                <tr>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.firstName')}</th>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.lastName')}</th>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.email')}</th>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.upgradeTo')}</th>
                </tr>
              </thead>
              <tbody>
                {filterIndividuals(candidateIndividuals).length > 0 ? (
                  filterIndividuals(candidateIndividuals).map((individual) => (
                    <tr key={individual._id} className={styles.upgradeIndividualsTableRow}>
                      <td className={styles.upgradeIndividualsTableCell}>{individual.name}</td>
                      <td className={styles.upgradeIndividualsTableCell}>{individual.lastName}</td>
                      <td className={styles.upgradeIndividualsTableCell}>{individual.email}</td>
                      <td className={styles.upgradeIndividualsTableCell}>
                        {getUpgradeOptions(individual.moveType).length > 0 ? (
                          <div
                            className={styles.upgradeIndividualsDropdown}
                            ref={(el) => (dropdownRefs.current[individual._id] = el)}
                          >
                            <button
                              className={styles.upgradeIndividualsDropdownButton}
                              onClick={() => openModal(individual, "")}
                            >
                              {t('upgradeIndividuals.selectUpgrade')}
                            </button>
                            <div
                              className={`${styles.upgradeIndividualsDropdownContent} ${
                                openDropdownId === individual._id ? styles.upgradeIndividualsShow : ""
                              }`}
                            >
                              {getUpgradeOptions(individual.moveType).map((option) => (
                                <button
                                  key={option.value}
                                  className={styles.upgradeIndividualsDropdownContentButton}
                                  onClick={() => openModal(individual, option.value)}
                                >
                                  {option.label}
                                </button>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <span>{t('upgradeIndividuals.noUpgradeOptions')}</span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className={styles.upgradeIndividualsNoData} colSpan={4}>
                      {t('upgradeIndividuals.noCandidates')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Tabla de Movers/Commuters */}
          <div className={styles.upgradeIndividualsTableWrapper}>
            <h2 className={styles.upgradeIndividualsTableTitle}>{t('upgradeIndividuals.moversCommuters')}</h2>
            <table className={styles.upgradeIndividualsIndividualTable}>
              <thead>
                <tr>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.firstName')}</th>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.lastName')}</th>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.email')}</th>
                  <th className={styles.upgradeIndividualsTableHeader}>{t('upgradeIndividuals.moveType')}</th>
                </tr>
              </thead>
              <tbody>
                {filterIndividuals(moverCommuterIndividuals).length > 0 ? (
                  filterIndividuals(moverCommuterIndividuals).map((individual) => (
                    <tr key={individual._id} className={styles.upgradeIndividualsTableRow}>
                      <td className={styles.upgradeIndividualsTableCell}>{individual.name}</td>
                      <td className={styles.upgradeIndividualsTableCell}>{individual.lastName}</td>
                      <td className={styles.upgradeIndividualsTableCell}>{individual.email}</td>
                      <td className={styles.upgradeIndividualsTableCell}>
                        {individual.moveType
                          .split('-')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className={styles.upgradeIndividualsNoData} colSpan={4}>
                      {t('upgradeIndividuals.noMovers')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Modal de Confirmación */}
      {isModalOpen && selectedIndividual && newMoveType && (
        <div className={styles.upgradeIndividualsModalOverlay}>
          <div className={styles.upgradeIndividualsModalContent}>
            <h2 className={styles.upgradeIndividualsModalTitle}>{t('upgradeIndividuals.upgradeConfirmation.title')}</h2>
            <p className={styles.upgradeIndividualsModalMessage}>
              {t('upgradeIndividuals.upgradeConfirmation.message')} <strong>
                {selectedIndividual.name} {selectedIndividual.lastName}
              </strong> {t('upgradeIndividuals.upgradeConfirmation.to')} <strong>
                {newMoveType.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
              </strong>?
            </p>
            <p className={styles.upgradeIndividualsModalWarning}>
              {t('upgradeIndividuals.upgradeConfirmation.warning')}
            </p>
            <div className={styles.upgradeIndividualsModalButtons}>
              <button className={styles.upgradeIndividualsConfirmButton} onClick={handleMoveTypeChange}>
                {t('upgradeIndividuals.buttons.confirm')}
              </button>
              <button className={styles.upgradeIndividualsCancelButton} onClick={closeModal}>
                {t('upgradeIndividuals.buttons.cancel')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpgradeIndividuals;
